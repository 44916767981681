<!-- <svg width="960" height="600"></svg> -->
<div style="text-align:center;">
  <mat-form-field>
    <mat-select [formControl]="keyword" name="keyword" placeholder="Keyword" multiple>
      <mat-option *ngFor="let key of keywordsList" [value]="key">{{key}}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-select [formControl]="object" name="object" placeholder="Learning object" multiple>
      <mat-option *ngFor="let obj of objectsList" [value]="obj">{{obj}}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-select [formControl]="links" name="links" placeholder="Learning object's links" multiple>
      <mat-option *ngFor="let objLink of objectLinksList" [value]="objLink">{{objLink}}</mat-option>
    </mat-select>
  </mat-form-field>
  <button id="filterButton" (click)="filter()" mat-raised-button color="primary">Filter</button>
  <button style="margin-left: 10px;" id="filterButton" (click)="reset()" mat-raised-button color="primary">Reset</button>
</div>
<button style="margin-left: 20px" mat-raised-button color="warn">Beta</button>
<!--<div class="box">
  <span class="dot blue"></span>
  <span class="left-margin">Learning Object</span>
  <span class="dot light-orange left-margin"></span>
  <span class="left-margin">Keyword</span>
  <span class="dot light-blue left-margin"></span>
  <span class="left-margin">XML Schema</span>
  <span class="dot orange left-margin"></span> 
  <span class="left-margin">License</span>
</div>-->
  
<svg id="mySvg" width="1500" height="1500" style="height: 2000px;">
    <defs id="mdef">
      <pattern id="view" x="0" y="0" height="40" width="40">
        <image x="0" y="0" width="40" height="40" xlink:href="../assets/icons/circled-play.png"></image>
      </pattern>
      <pattern id="creativecommons" x="0" y="0" height="40" width="40">
        <image x="0" y="0" width="40" height="40" xlink:href="../assets/icons/creative-commons.png"></image>
      </pattern>
      <pattern id="keyword" x="0" y="0" height="50" width="50">
        <image x="0" y="0" width="40" height="40" xlink:href="../assets/icons/keyword.png"></image>
      </pattern>
      <pattern id="xml" x="0" y="0" height="40" width="40">
        <image x="0" y="0" width="40" height="40" xlink:href="../assets/icons/xml.png"></image>
      </pattern>
    </defs>
</svg>
<div style="clear: both"></div>