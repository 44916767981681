import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tutorials',
  templateUrl: './tutorials.component.html',
  styleUrls: ['./tutorials.component.css']
})
export class TutorialsComponent implements OnInit {
  gridColumns = 2;
  tutorialVideos: any;
  presentationVideos: any;

  constructor() { }

  ngOnInit(): void {
    this.presentationVideos = [
      {
        title: "Apresentação Re-Mar",
        fileName: "ReMar_Apresentação.mp4",
        description: ""
      },
      {
        title: "Partilhe o seu objeto no Re-Mar",
        fileName: "ReMar_Submissão.mp4",
        description: ""
      }]
    this.tutorialVideos = [
      {
        title: "TED ED",
        fileName: "01_video_tutorial_ted_ed_FIN.mp4",
        description: "A TED-Ed é uma iniciativa juvenil e educativa da famosa plataforma TED Ideas worth spreading que promove a aprendizagem e troca de ideias. Apresenta versões para Estudantes, Professores e Encarregados de educação."
          + " Este tutorial foca-se na versão para Professores e começa por explicar como o utilizador se pode registar para depois poder explorar e desenvolver recursos educativos."
          + " Podem ser consultadas e utilizadas lições já disponibilizadas (Discover) ou podem ser criadas (Create) lições novas. Os professores podem partilhar as suas ideias ao estilo das TED talks e podem ainda incentivar os seus alunos a fazer o mesmo."
          + " As lições podem ser usadas na sala de aula, mas também é possível utilizar esta ferramenta no contexto de ensino à distância pois permite que os alunos interajam com a lição através da ligação partilhada pelo professor."
          + " O formato do TED-Ed está na língua inglesa, assim como a estrutura das lições, mas ao criar uma lição, os professores podem inserir conteúdo totalmente em português."
          + " Idioma: Inglês.\n"
          + " Modalidade: Gratuito."
      },
      {
        title: "FABAPP",
        fileName: "02_video_tutorial_fabapp_FIN.mp4",
        description: "A Fabapp permite a criação de aplicações (APPs) sem necessidade de programação."
          + " Este tutorial ajuda a criar uma APP através da interface Fabapp."
          + " Existem várias categorias de APPs (e.g. entretenimento, desporto, negócios, educação, etc) e, neste caso, focamos a atenção para a categoria de Educação."
          + " Um pequeno guia sobre as aves marinhas dos Açores foi o exemplo escolhido para ilustrar uma das muitas possibilidades de utilização desta ferramenta na construção de APPs como recursos pedagógicos."
          + " Idioma: Português (Brasil), Inglês e Espanhol."
          + " Modalidade: Gratuito apenas nos primeiros 7 dias do plano básico."
      },
      {
        title: "EMAZE",
        fileName: "03_video_tutorial_emaze_FIN.mp4",
        description: "A Emaze é uma ferramenta online usada essencialmente para construir apresentações, mas também pode ser usada para construir outros conteúdos digitais (sites, cartões digitais, blogs, álbuns fotográficos)."
          + " Idioma: Português (Brasil), Inglês, Árabe, Chinês, Holandês, Francês, Alemão, Hebraico, Italiano, Russo e Espanhol."
          + " Modalidade: Gratuito na maioria dos seus serviços básicos – freemium."
      },
      {
        title: "PIXTON",
        fileName: "04_video_tutorial_pixton_FIN.mp4",
        description: "O Pixton é ideal para fazer banda desenhada. Apresenta versões para Professores, que abordamos neste tutorial, mas também para Estudantes, Encarregados de educação e para Empresas."
          + "A plataforma de funcionamento do Pixton está na língua inglesa mas, ao criar uma banda desenhada, os professores podem inserir conteúdo totalmente em português."
          + " Idioma: Inglês, Francês e Espanhol."
          + " Modalidade: Gratuito na maioria dos seus serviços básicos – freemium."
      },
      {
        title: "SCREENCAST-O-MATIC",
        fileName: "05_video_tutorial_screencastomatic_FIN.mp4",
        description: "O Screencast-O-Matic permite a gravação do nosso ecrã de computador de forma simples e versátil, mesmo na versão gratuita."
          + " Para utilização rápida, não necessita de registo de utilizador."
          + " Idioma: Inglês."
          + " Modalidade: Gratuito na maioria dos seus serviços básicos – freemium."
      },
    ]
  }

}
