<div id='commentsInputDiv'>
    <p class="paragraph">Adicione um comentário:</p>
    <form [formGroup] = "reviewersCommentsForm">
        <mat-form-field style="width:100%">
            <textarea #comment (change)="existText()" formControlName="comment" rows="5" style="border:solid 1px;" matInput autocomplete="off"></textarea>
        </mat-form-field>
        <button mat-raised-button (click)="addComment()">Gravar comentário</button>
    </form>
    <br>
</div>
<div id='commentsShowDiv'>
    <p class="paragraph">Comentários:</p>
    <table cellpadding="10">
        <tr *ngFor="let comment of simple.reviewersComments">
            <td>{{getCommentPart(comment,"comment=")}}</td>
            <td>{{getCommentPart(comment,"user=")}}</td>
            <td>{{translatePhase(getCommentPart(comment,"phase="))}}</td>
        </tr>
    </table>
    <p *ngIf="!simple.reviewersComments || simple.reviewersComments.length == 0" style="margin-left: 1em;"><br>Não há comentários para este objeto de aprendizagem.</p>
</div>