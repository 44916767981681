export const learningCyclesList: string[] = ["Educação pré-escolar", "Básico 1º ciclo", "Básico 2º ciclo", "Básico 3º ciclo",
"Ensino secundário", "Ensino superior", "Ensino profissional"];

export const knowledgeAreasList: string[] = ["Ciências exatas", "Ciências humanas",
"Ciências naturais", "Ciências tecnológicas"];

export const curricularAreasList: string[] = ["Matemática", "Português", "Estudo do Meio", "Expressões (Plástica, Musical, Dramática/Teatro)",
"Educação Física", "Cidadania", "Ciências Naturais", "Inglês", "TIC", "História", "Educação Visual",
"Educação Tecnológica", "Educação Musical", "Biologia", "Geografia", "Físico-Química"];

export const institutionList: string[] = ["Centro Interdisciplinar de Investigação Marinha e Ambiental (CIIMAR)", 
"Expolab", "National Oceanic Atmospheric Administration (NOAA)", "Observatório do Mar dos Açores (OMA)", 
"Universidade dos Açores"];

export const stopwords: string[] = ["último","a","as","e","é","acerca","agora","algmas","alguns","ali","ambos",
"antes","apontar","aquela","aquelas","aquele","aqueles","aqui","atrás","bem","bom","cada","caminho","com","de",
"cima","com","como","comprido","conhecido","corrente","das","debaixo","dentro","desde","desligado",
"deve","devem","deverá","direita","diz","dizer","dois","dos","e","ela","ele","eles","em","enquanto",
"então","está","estão","estado","estar","estará","este","estes","esteve","estive","estivemos",
"estiveram","eu","fará","faz","fazer","fazia","fez","fim","foi","fora","horas","iniciar","início",
"ir","irá","ista","iste","isto","ligado","maioria","maiorias","mais","mas","mesmo","meu","muito",
"muitos","nós","não","nome","nosso","novo","o","onde","os","ou","outro","para","parte","pegar",
"pelo","pessoas","pode","poderá","podia","por","porque","povo","promeiro","que","quê","qual",
"qualquer","quando","quem","quieto","são","saber","sem","ser","seu","somente","têm","tal","também",
"tem","tempo","tenho","tentar","tentaram","tente","tentei","teu","teve","tipo","tive","todos",
"trabalhar","trabalho","tu","um","uma","umas","uns","usa","usar","valor","veja","ver","verdade",
"verdadeiro","você"]; //adapted from https://www.ranks.nl/stopwords/portuguese