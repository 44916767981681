import { Component, OnInit, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomSnackbarComponent } from '../custom-snackbar/custom-snackbar.component';

@Component({
  selector: 'app-show-metadata',
  templateUrl: './show-metadata.component.html',
  styleUrls: ['./show-metadata.component.css']
})
export class ShowMetadataComponent implements OnInit {

  @Input() simple:any;

  constructor(private _snackBarAdvice: MatSnackBar) { }

  ngOnInit() {
    if(typeof this.simple.relationWith[0] !== 'object') {
      this.simple.relationWith = this.convertRelationsToObjects();
    }
    if(!this.simple.linkOfLo) {
      this.simple.linkOfLo = "";
    }
  }

  showSameRelation(relations_id) {
    let url_ids = [];
    if(relations_id.indexOf(",") != -1) {
      let ids = relations_id.split(",");
      for(let id of ids) {
        url_ids.push(id);
      }
    } else {
      url_ids.push(relations_id);
    }
    return url_ids;
  }

  convertRelationsToObjects() {
    let updatedList = [];
    for(let i = 0; i < this.simple.relationWith.length; i++) {
      updatedList.push({ entry: this.getEntry(this.simple.relationWith[i]), kind: this.getKind(this.simple.relationWith[i]) })
    }
    return updatedList;
  }

  getKind(relation: string) {
    let relation_parts = relation.split(",");
    for(let i=0; i < relation_parts.length; i++) {
      if(relation_parts[i].indexOf("kind=") != -1) {
        return relation_parts[i].replace("}","").replace("kind=","");
      }
    }
    return "";
  }

  getEntry(relation: string){
    let relation_parts = relation.split(",");
    let relation_ids = "";
    for(let i=0; i < relation_parts.length; i++) {
      if(relation_parts[i].indexOf("{entry=") != -1) {
        relation_parts[i] = relation_parts[i].replace("{entry=","");
        (relation_parts[i]) ? relation_ids += relation_parts[i] + "," : relation_ids += "";   
      } else if(relation_parts[i].indexOf("kind=") == -1) {
        (relation_parts[i]) ? relation_ids += relation_parts[i] + "," : relation_ids += "";  
      }
    }
    return relation_ids.substring(0,relation_ids.length-1);
  }

  openSnackBar(message: string, action: string, url: string) {
    let snackBarRef = this._snackBarAdvice.openFromComponent(CustomSnackbarComponent, {
      duration: 20000,
      horizontalPosition: 'center',
      //verticalPosition: 'top',
      data: { message: message, action: action, url: url, snackType: "Warn" }
    });
    snackBarRef.onAction().subscribe(() => {
      this.openLink(url);
    });
  }

  showTranslatedAuthorRole(roles) {
    var roles_translated = "";
    for(var i = 0; i < roles.length; i++) {
      if (i > 0) {
        roles_translated += ", " + this.translateRole(roles[i]);
      } else {
        roles_translated += this.translateRole(roles[i]);
      }
    }    
    return roles_translated;
  }

  translateRole(role) {
    switch(role.toString().trimLeft()) {
      case "author": return "Autor\\a";
      case "content production": return "Produção de conteúdo";
      case "lesson plan production": return "Produção do plano de aula";
      case "graphical designer": return "Designer Gráfico";
      case "script writer": return "Guionista";
      case "pedagogical reviewer": return "Revisor\\a pedagógico";
      case "content reviewer about the sea": return "Revisor\\a do conteúdo";
      case "programmer": return "Programador\\a";
      case "consultant": return "Consultor\\a";
      case "translator": return "Tradutor\\a";
      case "other": return "Outra";
      default: return "tradução não encontrada";
    }
  }
  
  translateRelationKind(relationKind) {
    switch(relationKind.toString().trimLeft()) {
      case "haspart": return "Contém parte";
      case "isversionof": return "É uma versão de";
      case "references": return "Referencia";
      case "requires": return "Requer";
      default: return "tradução não encontrada";
    }
  }

  fixUrl(url) {
    if(url.indexOf("http") == -1) {
      url = "http://" + url;
    }
    return url;
  }

  openLink(url: string){
    url = this.fixUrl(url);
    window.open(url, "_blank");
  }
}