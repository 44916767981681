<div *ngIf="simple.status == 'REVIEWED'" class="center-div-step2" id="step7">
    <h1 style="text-align: center;"><strong>{{simple.name}} </strong></h1>
    <div style="text-align: center;width:auto;margin:0 auto;">
      <div style="display:inline-block;" matTooltip="{{voteMsg}}">
        <star-rating value="{{meanVotes}}" [totalstars]="5" checkedcolor="gold" uncheckedcolor="{{uncheckedColor}}" size="24px" readonly="false" (rate)="onRate($event)" ></star-rating> 
      </div>
      <div style="display:inline-block;">
        ({{totalVotes}})
      </div>
      <div style="display:inline-block;" matTooltip="{{favoriteMsg}}">
        <button *ngIf="isLogged" mat-icon-button matSuffix (click)="like = !like; updateLike(like,id)"
      [attr.aria-label]="'Like'" [attr.aria-pressed]="like">
          <mat-icon>{{like ? 'favorite' : 'favorite_border'}}</mat-icon>
        </button>
        <button *ngIf="!isLogged" mat-icon-button matSuffix [attr.aria-label]="'Like'" [attr.aria-pressed]="like">
          <mat-icon>favorite_border</mat-icon>
        </button>
      </div>
      <div style="display:inline-block;">
        ({{totalFavorites}})
      </div>
    </div>
    
    <br>
    <div ><img style="display:block;margin:auto; max-width:50%; max-height:300px" mat-card-image src="{{thumb}}"  alt="Photo"></div> <br>
    <div style="text-align: center">
      <a href="https://catalogolx.cm-lisboa.pt/ipac20/ipac.jsp?profile=rbml&uri=full=3100024~!545013~!0&ri=0&aspect=basic_search&menu=search" target="_blank">
        <img *ngIf="id == 2" style="display:block;margin:auto; max-width:20%; max-height:300px; margin-bottom: 30px" mat-card-image src="../assets/images/logo_pnl.png"  alt="Plano Nacional de Leitura">
      </a>
      <button *ngIf="fileId.length > 0 && enableViewButton" mat-raised-button (click)="view();scrollTo(viewComponent);saveUserStatistics('download')" color="primary">Visualizar</button>
      <a *ngIf="fileId.length > 0" style="padding-left: 10px;" [href]="sanitize()"><button mat-raised-button (click)="saveUserStatistics('download')" color="primary">Descarregar</button></a>
      <br><br>
      <button mat-raised-button (click)="openPedagogicalTemplateDialog()" color="primary">Informação pedagógica</button>
    </div>
    <br><br>
    <app-show-metadata [simple]="simple"></app-show-metadata>
    <app-comments [simple]="simple"></app-comments>
    <div #viewComponent></div>
    <app-view *ngIf="enableViewComponent" [fileId]="fileId"></app-view>
</div>

<div *ngIf="simple.status != 'REVIEWED'" class="center-div-step2" id="step7">
  <p class="paragraph" style="text-align: center;">Este objeto de aprendizagem encontra-se em fase de submissão ou está em revisão.</p>
</div>
