<br>
<br>
<mat-progress-bar id="progress" class="orange-progress" [value]="progressBarValue"></mat-progress-bar>
<mat-horizontal-stepper [linear]="true" #stepper >
  <mat-step  editable="false" completed="false">
    <ng-template matStepLabel>Submissão</ng-template>
  </mat-step>
  <mat-step  editable="false" completed="false">
    <ng-template matStepLabel>Revisão científica</ng-template>
  </mat-step>
  <mat-step  editable="false" completed="false">
    <ng-template matStepLabel>Revisão pedagógica</ng-template>
  </mat-step>
  <mat-step  editable="false" completed="false">
    <ng-template matStepLabel>Aprovação</ng-template>
  </mat-step>
</mat-horizontal-stepper>  
<div class="center-div" id="step0">
  <p class="paragraph">
    Ao depositar um objeto no repositório Re-Mar, está a aceitar que os seus dados possam ser 
    utilizados, de forma agregada e anonimizada, para fins estatísticos e académicos. Qualquer 
    esclarecimento adicional ou ação que pretenda sobre os seus dados pode contactar o responsável: 
    Armando B. Mendes, pelo endereço <a href="mailto:armando.b.mendes@uac.pt">armando.b.mendes@uac.pt</a>.
  </p>
  <br>
  <button id="agreeButton" (click)="agreeWithDeposit(true)" mat-raised-button color="primary">Concordo</button>
</div>

<div class="center-div" id="step1" style="display:none">
  <h3 style="text-align: center;"><strong>Arquivo(s):</strong></h3>
  <div *ngIf="fileId.length > 0 || fileThumb"
     style="border-style: solid;width: fit-content; margin:auto; padding: 20px;">
    <div>
      <p class="paragraph">Objeto de aprendizagem:</p>
      <br>
      <div *ngFor="let id of fileId; let i = index">
        <a href="{{id}}" style="color: #007bff">Arquivo enviado #{{i}}</a>
        <button mat-icon-button matTooltip="Remover" (click)="removeFile(id, i)">
          <mat-icon class="mat-24">delete</mat-icon>
        </button>    
      </div>
      <a *ngIf="fileId.length == 0" style="color: red">Arquivo não enviado! Link de acesso:</a>
      <p *ngIf="fileId.length == 0 && simple.linkOfLo != ''">
        <mat-icon>link</mat-icon>
        <a style="font-size: 18px; vertical-align: top;" href="{{simple.linkOfLo}}" target="_blank">
        {{simple.linkOfLo}}</a></p>
        <p *ngIf="fileId.length == 0 && simple.linkOfLo == ''" >
          <mat-icon>link_off</mat-icon>
          <a style="font-size: 18px; vertical-align: top;">Erro! Link não informado!</a>
        </p>
      <br>
      <br>
    </div>
    <p *ngIf="fileThumb || reviewerText == 'Revisão'" class="paragraph">Imagem de rosto:</p>
    <br>
    <a *ngIf="fileThumb == '' && reviewerText == 'Revisão'" style="color: red">Arquivo não enviado!</a>
    <a *ngIf="fileThumb" href="{{fileThumb}}" style="color: #007bff">Arquivo enviado</a>
    <button *ngIf="fileThumb" mat-icon-button matTooltip="Remover" (click)="removeFile(thumbIdFile, -1)">
      <mat-icon class="mat-24">delete</mat-icon>
    </button>
  </div>
  <br>
  <br>
  <div id="uploadFileDiv">
    <p class="paragraph">Adicione o(s) arquivo(s) do seu objeto / recurso</p><br>
    <!-- <p>* O arquivo pode ser composto por vários ficheiros de forma compactada.</p> -->
    <p>* Se não possuir arquivo, o link de acesso deve ser informado no formulário.</p>
    <button class="circleButton" mat-icon-button matTooltip="Adicione o arquivo do seu objeto / recurso" (click)="fileInput.click();">
      <mat-icon class="icon-display">present_to_all</mat-icon>
    </button>
    <p id="uploadFileText" style="margin-top: 10px; display: none;">
      Arquivo(s) selecionado(s):
    </p>
    <div *ngFor="let item of uploader2.queue">
      {{item.file?.name}}
      <button mat-icon-button matTooltip="Remover" (click)="item.remove();hideText('uploadFileText');">
        <mat-icon class="mat-24">delete</mat-icon>
      </button>
    </div>
    <input #fileInput style="display: none;" type="file" name="file" id="uploadFile" 
    (change)="showText('uploadFileText');" ng2FileSelect [uploader]="uploader2" multiple />
    <br>
  </div>
  <br>
  <div id="uploadPhotoDiv">
    <p class="paragraph">Adicione uma imagem de rosto para o seu objeto / recurso</p><br>
    <p>* Arquivo nos formatos .gif, .jpg, .png</p>
    <button class="circleButton" mat-icon-button matTooltip="Adicione uma imagem de rosto para o seu objeto / recurso" (click)="photoUpload.click();">
      <mat-icon class="icon-display">add_photo_alternate</mat-icon>
    </button>
    <p id="uploadPhotoText" style="margin-top: 10px; display: none;">
      Arquivo selecionado:
    </p>
    <div *ngFor="let item of uploader.queue">
      {{item.file?.name}}
      <button mat-icon-button matTooltip="Remover" (click)="uploader.clearQueue();hideText('uploadPhotoText')">
        <mat-icon class="mat-24">delete</mat-icon>
      </button>
    </div>
    <input #photoUpload style="display: none" type="file" name="photo" id="photoUpload" 
      (click)="uploader.clearQueue()" ng2FileSelect [uploader]="uploader" accept="image/*" 
      (change)="showText('uploadPhotoText')"/>
    <br>
    <br>
  </div>
</div>

<div class="center-div-step2" id="step2" style="display:none">
  <h3 style="text-align: center;"><strong>Relações entre objetos de aprendizagem</strong></h3> <br><br>

  <p class="paragraph">O objeto / recurso usou como base outro objeto de aprendizagem já existente neste repositório?</p><br>
  <mat-radio-group name="loRelation">
    <p style="font-size: large;">
      <mat-radio-button [checked]="existRelation" value="1" (change)="showRelationDiv($event)">Sim</mat-radio-button>
    </p>
    <p style="font-size: large;">
      <mat-radio-button [checked]="!existRelation" value="0" (change)="showRelationDiv($event)">Não</mat-radio-button>
    </p>
  </mat-radio-group>
  <div *ngIf="existRelation" id="relationDiv">
    <div *ngFor="let relation of simple.relationWith; let i = index">
      <p class="paragraph"><span>De que forma?</span>
        <button *ngIf="simple.relationWith.length > 1" mat-icon-button matTooltip="Remover" (click)="removeRelation(i)">
            <mat-icon class="mat-24">clear</mat-icon>
        </button>
      </p>
      <mat-form-field>
        <mat-select [(ngModel)]="relation.kind" name="kind">
          <!-- <mat-option value="">  </mat-option> -->
          <!-- <mat-option value="ispartof"> É parte de </mat-option> -->
          <mat-option value="haspart"> Contém parte </mat-option>
          <mat-option value="isversionof"> É uma versão de </mat-option>
          <!-- <mat-option value="hasversion"> Tem versão</mat-option> -->
          <!-- <mat-option value="isformatof"> É formato de</mat-option>
          <mat-option value="hasformat"> Tem formato</mat-option> -->
          <mat-option value="references"> Referencia</mat-option>
          <!-- <mat-option value="isreferencedby"> É referenciado por </mat-option> -->
          <!-- <mat-option value="isbasedon"> É baseado em </mat-option>
          <mat-option value="isbasisfor"> É base para </mat-option> -->
          <mat-option value="requires"> Requer </mat-option>
          <!-- <mat-option value="isrequiredby"> É requerido por </mat-option> -->
        </mat-select>
      </mat-form-field> <br><br>
      
      <table cellpadding="10">
        <tr *ngFor="let docTiny of documentsTiny[i]; let k = index">
          <td>{{docTiny.id}}</td>
          <td *ngIf="docTiny.title">{{docTiny.title}}</td>
          <td *ngIf="!docTiny.title">{{async_titles[i][k]}}</td>
          <td>
            <button mat-icon-button matTooltip="Remover" (click)="removeDocTiny(i,k)">
            <mat-icon class="mat-24">delete</mat-icon>
            </button>
          </td>
        </tr>
      </table>
      <br>
      <br>
      <button mat-raised-button (click)="openRelationDialog(i)">Escolha o objeto com que se relaciona</button>
      <br>
      <br>
      <br>
      <br>
    </div>
    <button mat-raised-button (click)="addRelation('haspart')">Adicionar outra relação</button>
    <!-- <br>
    <br>
    <button *ngIf="simple.relationWith.length > 1" mat-raised-button (click)="removeRelation()">Remover relação</button> -->
    <br>
    <br>
    <br>
    <p class="paragraph">Escolha o objeto / recurso para pré-preencher este relacionado?</p><br>
    <mat-form-field>
      <mat-select [(ngModel)]="preFillValue">
        <mat-option (onSelectionChange)="changePreFillValue($event)" [value]="0">Nenhum</mat-option>
        <mat-optgroup *ngFor="let group of documentsTiny; let i = index;">
          <button mat-raised-button color="primary">Relação #{{i+1}}</button>
          <mat-option (onSelectionChange)="changePreFillValue($event)" *ngFor="let docTiny of group; let k = index;" [value]="docTiny.id">
            {{docTiny.id}} - {{(docTiny.title) ? docTiny.title : async_titles[i][k]}}
          </mat-option>
        </mat-optgroup>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<div class="center-div-step2" id="step3" style="display:none">
  <h3 style="text-align: center;"><strong>Informação pedagógica</strong></h3> <br><br>

  <p class="paragraph">Área(s) Curricular(es) * (identifique a ou as àreas 
    curriculares que podem ser potenciadas, numa perspectiva integradora, 
    com a utilização do objeto de aprendizagem):</p>
  <br>
  <br>
  <table mat-table [dataSource]="curriculumAreas_predefined" class="mat-elevation-z8">

    <ng-container matColumnDef="col_1">
      <td mat-cell *matCellDef="let element;"> 
        <mat-checkbox [checked]="element.col_1.isValid" (change)="element.col_1.isValid = ! element.col_1.isValid;"></mat-checkbox>
        {{element.col_1.name}}
      </td>
    </ng-container>
    <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr> -->
    <tr mat-row *matRowDef="let row; columns: displayedTableColumms(1);"></tr>
  </table>
  <br>
  <mat-form-field style="width:65%;padding-right: 1em;">
    <input matInput
        placeholder="Adicione outra área curricular"
        [formControl]="controlCurriculumArea">
  </mat-form-field>
  <!-- <mat-form-field style="width:45%">
    <input matInput [(ngModel)]="keywords" name="keyword" placeholder="Adicione outra palavra-chave">
  </mat-form-field> -->
  <button mat-raised-button (click)="addCurriculumArea();">Adicione</button>
  <br>
  <div *ngFor="let item of curriculumAreas; let i = index">
    <p style="font-size: large;">
      <span>{{item}}</span>
      <button mat-icon-button matTooltip="Remover" (click)="removeCurriculumArea(i)">
        <mat-icon class="mat-24">delete</mat-icon>
      </button>
    </p>
  </div>
  <br>
  <br>
  <p class="paragraph">Objetivos de Aprendizagem: *</p>
  <br><br>
  <mat-form-field style="width:100%">
      <textarea [(ngModel)]="simple.learningObjectives" rows="5" style="overflow:hidden" matInput autocomplete="off" appTextareaAutoresize></textarea>
  </mat-form-field>
  <br>
  <p class="paragraph">Principais estratégias *(enumere as estratégias fundamentais para a utilização
    do objeto de aprendizagem ou qualquer outra informação que considere relevante, por exemplo,
    tipo de livro ou questionário):
  </p>
  <br><br>
  <mat-form-field class="example-full-width" style="width:100%">
      <textarea [(ngModel)]="simple.mainStrategies" rows="5" style="overflow:hidden" matInput autocomplete="off" appTextareaAutoresize></textarea>
  </mat-form-field>
  <br>    
  <p class="paragraph">Link de acesso ao OA se aplicável:</p>
  <br>
  <mat-form-field class="example-full-width" style="width:100%">
      <input [(ngModel)]="simple.linkOfLo" id="linkOfLo" matInput placeholder="" value="">
  </mat-form-field>
  <br>
</div>

<div class="center-div-step2" id="step4" style="display:none">
  <h3 style="text-align: center;"><strong>Informações gerais</strong></h3> <br><br>

  <form>

    <p class="paragraph">Título:</p>
    <mat-form-field style="width:70%">
      <input required matInput [(ngModel)]="simple.name" name="title" placeholder="ex.: O mar é a nossa casa">
    </mat-form-field>
    <br><br>
    <p class="paragraph">Idioma:</p>
    <mat-form-field>
      <mat-select required [(ngModel)]="simple.language" name="languages" placeholder="Escolher" multiple>
        <mat-option value=Português>Português</mat-option>
        <mat-option value=Inglês>Inglês</mat-option>
        <mat-option value=Espanhol>Espanhol</mat-option>
        <mat-option value=Nenhum>Nenhum</mat-option>
      </mat-select>
    </mat-form-field> <br><br>

    <p class="paragraph">Descrição:</p>
    <mat-form-field style="width:70%">
      <input required matInput [(ngModel)]="simple.description" name="description" placeholder="ex.: Livro eletrónico com uma aventura no mar">
    </mat-form-field>
    <br><br>
    
    <p class="paragraph">Palavras-chave: *
      <button mat-icon-button matTooltip="Sugerir palavras-chave a partir do título e descrição" (click)="getSuggestions()">
        <mat-icon class="mat-24">assignment icon</mat-icon>
      </button>
      <button mat-icon-button matTooltip="Remover todas sugestões" (click)="removeSuggestions()">
        <mat-icon class="mat-24">delete</mat-icon>
      </button>
    </p>
    <br>
    <div>
      <table mat-table [dataSource]="keywords_predefined" class="mat-elevation-z8">

        <ng-container matColumnDef="col_1">
          <td mat-cell *matCellDef="let element"> 
            <mat-checkbox [checked]="element.col_1.isValid" (change)="element.col_1.isValid = ! element.col_1.isValid;"></mat-checkbox>
            {{element.col_1.name}}
          </td>
        </ng-container>

        <ng-container matColumnDef="col_2">
          <td mat-cell *matCellDef="let element"> 
            <mat-checkbox [checked]="element.col_2.isValid" (change)="element.col_2.isValid = ! element.col_2.isValid;"></mat-checkbox>
            {{element.col_2.name}}
          </td>
        </ng-container>

        <ng-container matColumnDef="col_3">
          <td mat-cell *matCellDef="let element"> 
            <mat-checkbox [checked]="element.col_3.isValid" (change)="element.col_3.isValid = ! element.col_3.isValid;"></mat-checkbox>
            {{element.col_3.name}}
          </td>
        </ng-container>
      
        <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr> -->
        <tr mat-row *matRowDef="let row; columns: displayedTableColumms(3);"></tr>
      </table>
      <br>
    </div>

    <mat-form-field style="width:65%;padding-right: 1em;">
      <input matInput
          placeholder="Adicione outra palavra-chave"
          [formControl]="controlKeyword"
          [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let key of filteredKeywords | async" [value]="key">
          {{key}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <!-- <mat-form-field style="width:45%">
      <input matInput [(ngModel)]="keywords" name="keyword" placeholder="Adicione outra palavra-chave">
    </mat-form-field> -->
    <button mat-raised-button (click)="addKeyword();">Adicione</button>
    <br>
    <div *ngFor="let item of keywords; let i = index">
      <p style="font-size: large;">
        <span>{{item}}</span>
        <button mat-icon-button matTooltip="Remover" (click)="removeKeyord(i)">
          <mat-icon class="mat-24">delete</mat-icon>
        </button>
      </p>
    </div>
    <br>
    <br>
    <p class="paragraph">Tipo(s) de objeto / recurso de aprendizagem: *
    </p>
    <br>
    <div>
      <table mat-table [dataSource]="resources_predefined" class="mat-elevation-z8">

        <ng-container matColumnDef="col_1">
          <td mat-cell *matCellDef="let element"> 
            <mat-checkbox [checked]="element.col_1.isValid" (change)="element.col_1.isValid = ! element.col_1.isValid;"></mat-checkbox>
            {{element.col_1.name}}
          </td>
        </ng-container>

        <ng-container matColumnDef="col_2">
          <td mat-cell *matCellDef="let element"> 
            <mat-checkbox *ngIf="element.col_2.name" [checked]="element.col_2.isValid" (change)="element.col_2.isValid = ! element.col_2.isValid;"></mat-checkbox>
            {{element.col_2.name}}
          </td>
        </ng-container>

        <ng-container matColumnDef="col_3">
          <td mat-cell *matCellDef="let element"> 
            <mat-checkbox *ngIf="element.col_3.name" [checked]="element.col_3.isValid" (change)="element.col_3.isValid = ! element.col_3.isValid;"></mat-checkbox>
            {{element.col_3.name}}
          </td>
        </ng-container>

        <ng-container matColumnDef="col_4">
          <td mat-cell *matCellDef="let element"> 
            <mat-checkbox *ngIf="element.col_4.name" [checked]="element.col_4.isValid" (change)="element.col_4.isValid = ! element.col_4.isValid;"></mat-checkbox>
            {{element.col_4.name}}
          </td>
        </ng-container>

        <ng-container matColumnDef="col_5">
          <td mat-cell *matCellDef="let element"> 
            <mat-checkbox *ngIf="element.col_5.name" [checked]="element.col_5.isValid" (change)="element.col_5.isValid = ! element.col_5.isValid;"></mat-checkbox>
            {{element.col_5.name}}
          </td>
        </ng-container>
      
        <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr> -->
        <tr mat-row *matRowDef="let row; columns: displayedTableColumms(5);"></tr>
      </table>
       <!-- <table>
        <tr>
          <th>
            <mat-form-field style="width:100%;display:none" id="otherResourceForm">
              <input matInput [(ngModel)]="otherResource" name="otherResource" placeholder="Indique outros tipos, separados por vírgulas">
            </mat-form-field>
          </th>
        </tr>
      </table>
      <button mat-raised-button (click)="addKeyword();">Adicione</button> -->
      <br>
      <mat-form-field style="width:65%;padding-right: 1em;">
        <input matInput
            placeholder="Adicione outro tipo"
            [formControl]="controlType">
      </mat-form-field>
      <!-- <mat-form-field style="width:45%">
        <input matInput [(ngModel)]="keywords" name="keyword" placeholder="Adicione outra palavra-chave">
      </mat-form-field> -->
      <button mat-raised-button (click)="addType();">Adicione</button>
      <br>
    <div *ngFor="let item of resources; let i = index">
      <p style="font-size: large;">
        <span>{{item}}</span>
        <button mat-icon-button matTooltip="Remover" (click)="removeType(i)">
          <mat-icon class="mat-24">delete</mat-icon>
        </button>
      </p>
    </div>
      <br>
    </div>
    <br><br>
  </form>
</div>


<div class="center-div-step2" id="step5" style="display:none">
  <h3 style="text-align: center;"><strong>Interatividade</strong></h3> <br><br>
  <form>
    <p class="paragraph">Nível de interação: *</p><br><br>
    <mat-slider thumbLabel class="cdk-focused" style="pointer-events:none;" [(ngModel)]="simple.interactionNumber" id="interactivityNumber" name="interactivityNumber" [displayWith]="formatLabel"
      min="1" max="4" step="1" value="1"></mat-slider>
    <br><br>

    <mat-radio-group id="interactivitySelection">
      <p style="font-size: large;">
        <mat-radio-button [checked]="simple.interactionNumber == 1" value="1" (change)="radioInteractivityChange($event)">Nenhuma</mat-radio-button>
      </p>
      <p style="font-size: large;">
        <mat-radio-button [checked]="simple.interactionNumber == 2" value="2" (change)="radioInteractivityChange($event)">Apenas texto ou  vídeo</mat-radio-button>
      </p>
      <p style="font-size: large;">
        <mat-radio-button [checked]="simple.interactionNumber == 3" value="3" (change)="radioInteractivityChange($event)">Vídeo com perguntas, página web, protocolo com atividade</mat-radio-button>
      </p>
      <p style="font-size: large;">
        <mat-radio-button [checked]="simple.interactionNumber == 4" value="4" (change)="radioInteractivityChange($event)">Jogo, simulações interativas</mat-radio-button>
      </p>
    </mat-radio-group> <br>
  </form>
</div>

<div class="center-div-step2" id="step6" style="display:none">
  <h3 style="text-align: center;"><strong>Público Alvo</strong></h3> <br><br>
  <form>

    <p class="paragraph">Utilizadores finais: *</p> <br>
    <br>
    <div>
      <table mat-table [dataSource]="target" class="mat-elevation-z8">

        <ng-container matColumnDef="col_1">
          <td mat-cell *matCellDef="let element"> 
            <mat-checkbox [checked]="element.col_1.isValid" (change)="element.col_1.isValid = ! element.col_1.isValid;"></mat-checkbox>
            {{element.col_1.name}}
          </td>
        </ng-container>

        <ng-container matColumnDef="col_2">
          <td mat-cell *matCellDef="let element"> 
            <mat-checkbox [checked]="element.col_2.isValid" (change)="element.col_2.isValid = ! element.col_2.isValid;"></mat-checkbox>
            {{element.col_2.name}}
          </td>
        </ng-container>

        <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr> -->
        <tr mat-row *matRowDef="let row; columns: displayedTableColumms(2);"></tr>
      </table>
      <br>
    </div>
    <br>

    <p class="paragraph">Etapa(s) da educação: *</p>
    <br>
    <div>
      <table mat-table [dataSource]="age" class="mat-elevation-z8">

        <ng-container matColumnDef="col_1">
          <td mat-cell *matCellDef="let element"> 
            <mat-checkbox [checked]="element.col_1.isValid" (change)="element.col_1.isValid = ! element.col_1.isValid;"></mat-checkbox>
            {{element.col_1.name}}
          </td>
        </ng-container>

        <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr> -->
        <tr mat-row *matRowDef="let row; columns: displayedTableColumms(1);"></tr>
      </table>
      <br>
    </div>
    <br>
    <p class="paragraph">Área(s) do conhecimento: *</p>
    <br>
    <div>
      <table mat-table [dataSource]="knowledgeArea" class="mat-elevation-z8">

        <ng-container matColumnDef="col_1">
          <td mat-cell *matCellDef="let element"> 
            <mat-checkbox [checked]="element.col_1.isValid" (change)="element.col_1.isValid = ! element.col_1.isValid;"></mat-checkbox>
            {{element.col_1.name}}
          </td>
        </ng-container>

        <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr> -->
        <tr mat-row *matRowDef="let row; columns: displayedTableColumms(1);"></tr>
      </table>
      <br>
    </div>
    <br>
    <p class="paragraph">Tempo necessário (em minutos) para a realização da atividade: *</p>
    <br>
    <span>Clique na linha:</span>
    <br>
    <mat-slider disabled="{{moreThanThreeHours || doNotApply}}" thumbLabel [(ngModel)]="typicalLearningTime" id="typicalLearningTime" name="typicalLearningTime" [displayWith]=""
    (input)="setTypicalLearningTime($event.value,$event)" min="0" max="180" step="15" value="15"></mat-slider><span>(máximo 3 horas)</span>
    <br>
    <section>
      <mat-checkbox style="font-size: large;" [checked]="fortyFiveMin" (change)="setTypicalLearningTime('fortyFiveMin',$event)">45 minutos</mat-checkbox>
    </section>
    <br>
    <section>
      <mat-checkbox style="font-size: large;" [checked]="NinetyMin" (change)="setTypicalLearningTime('NinetyMin',$event)">90 minutos</mat-checkbox>
    </section>
    <br>
    <section>
      <mat-checkbox style="font-size: large;" [checked]="moreThanThreeHours" (change)="setTypicalLearningTime('moreThanThreeHours',$event)">Mais de 3 horas</mat-checkbox>
    </section>
    <br>
    <section>
      <mat-checkbox style="font-size: large;" [checked]="doNotApply" (change)="setTypicalLearningTime('doNotApply',$event)">Não se aplica</mat-checkbox>
    </section>
    <br>
  </form>

</div>

<div class="center-div-step2" id="step7" style="display:none">
  <h3 style="text-align: center;"><strong>Direitos</strong></h3> <br><br>

  <form>
    <p class="paragraph">É permitido compartilhar — copiar e redistribuir o material em qualquer suporte ou formato.</p><br>
    <p class="paragraph">É permitido adaptar — transformar e criar a partir do material.</p><br>
    <p class="paragraph">No caso de adaptação, têm de ser mantidos os créditos reconhecendo todos os autores ou intervenientes na produção do OA.</p> <br>
    <p class="paragraph">Quem alterar o seu objeto de aprendizagem não poderá revogar esta licença pelo que o novo objeto de aprendizagem terá de ser licenciado com a mesma licença.</p> <br>
    <p class="paragraph">Saiba mais sobre as licenças <a rel="license" target="_blank" href="http://creativecommons.org/licenses/">aqui.</a></p><br>
    <p class="paragraph">Licença pretendida: *</p><br>
    <mat-radio-group [(ngModel)]="simple.licence" name="licence" id="licence">
      <div style="font-size: large;display: inline-block;">
        <mat-radio-button value="https://creativecommons.org/licenses/by-sa/4.0/">
        </mat-radio-button>
        <a rel="license" target="_blank" href="https://creativecommons.org/licenses/by-sa/4.0/"><img alt="Licença Creative Commons" style="border-width:0" src="https://i.creativecommons.org/l/by-sa/4.0/88x31.png" /></a>
        <span>
          É permitida a utilização para qualquer fim, mesmo que comercial.
        </span>
      </div>
      <br>
      <br>
      <div style="font-size: large;display: inline-block;">
        <mat-radio-button value="https://creativecommons.org/licenses/by-nc-sa/4.0/">
        </mat-radio-button>  
        <a rel="license" target="_blank" href="https://creativecommons.org/licenses/by-nc-sa/4.0/"><img alt="Licença Creative Commons" style="border-width:0" src="https://i.creativecommons.org/l/by-nc-sa/4.0/88x31.png" /></a>
        <span> 
          Não é permitida a utilização para fins comerciais.
        </span> 
      </div>
    </mat-radio-group> <br><br>
    <br>
  </form>
  
</div>

<div class="center-div-step2" id="step8" style="display:none">
  <h3 style="text-align: center;"><strong>Contribuições</strong></h3> <br><br>

  <p class="paragraph">Quem contribuiu para a criação do objeto?</p><br>
  <div *ngFor="let author of simple.author; let i = index">
    <p class="paragraph"><span>Nome completo:</span>
      <button *ngIf="simple.author.length > 1" mat-icon-button matTooltip="Remover" (click)="removeAuthor(i)">
          <mat-icon class="mat-24">clear</mat-icon>
      </button>
    </p>
    <mat-form-field style="width:70%">
      <input required matInput [(ngModel)]="author.name" name="author" placeholder="Resposta">
    </mat-form-field>
    <br>

    <p class="paragraph">Instituição:</p>
    <mat-form-field style="width:70%">
      <input matInput [(ngModel)]="author.institution" name="institution" placeholder="Resposta">
      </mat-form-field>
    <br>

    <p class="paragraph">De que forma?</p>
    <mat-form-field>
      <mat-select required [(ngModel)]="author.role" name="role" multiple>
      <!-- <mat-option value=Aluno>Aluno</mat-option>
        <mat-option value=Professor>Professor</mat-option>
        <mat-option value=Gestor>Gestor</mat-option> -->

        <mat-option value="author"> Autor\a </mat-option>
        <mat-option value="content production"> Produção de conteúdo </mat-option>
        <mat-option value="lesson plan production"> Produção do plano de aula </mat-option>
        <mat-option value="graphical designer"> Designer Gráfico</mat-option>
        <mat-option value="script writer"> Guionista</mat-option>
        <mat-option value="pedagogical reviewer"> Revisor\a pedagógico</mat-option>
        <mat-option value="content reviewer about the sea"> Revisor\a do conteúdo</mat-option>
        <mat-option value="programmer"> Programador\a </mat-option>
        <mat-option value="consultant"> Consultor\a </mat-option>
        <mat-option value="translator"> Tradutor\a </mat-option>
        <mat-option value="other"> Outra </mat-option>
      </mat-select>
    </mat-form-field> <br><br>

  </div>
  <button mat-raised-button (click)="addAuthor('','',['author'])">Adicionar outro contribuinte</button>
  <!-- <br>
  <br>
  <button *ngIf="this.simple.author.length > 1" mat-raised-button (click)="removeAuthor()">Remover contribuinte</button> -->
  <br><br><br><br>
</div>

<div class="center-div-step2" id="step9" style="display:none">
  <h3 style="text-align: center;"><strong>Resumo - {{simple.name}}</strong></h3> <br><br>

  <app-show-metadata [simple]="simple"></app-show-metadata>
  <hr>
  <div id="sameLO" style="text-align: center;display:none;">
    <p style="color:red">TÍTULO E DESCRIÇÃO DO OBJETO NÃO PODEM SER IGUAIS AO DO OBJETO RELACIONADO</p>
  </div>
  <div id="incomplete" style="text-align: center;display:none;">
    <p style="color:red">O QUESTIONÁRIO AINDA NÃO ESTÁ TOTALMENTE COMPLETO</p>
  </div>
  <div id="uploadEmpty" style="text-align: center;display:none;">
    <p style="color:red">NENHUM ARQUIVO FOI SELECIONADO PARA O ENVIO OU LINK DE ACESSO FOI INFORMADO</p>
  </div>
  <app-comments [simple]="simple" (unsavedText)="getUnsavedCommentText($event)" (commentText)="addComment($event)"></app-comments>
  <hr>
  <p style="font-size: large"><strong>Defina a próxima etapa:</strong></p>
  <br>
  <div style="text-align: center">    
    <button *ngIf="simple.status == 'UNDER_TECH_REVIEW'" id="backToIncomplete" (click)="finish('', 'INCOMPLETE')" mat-raised-button style="background: #F5E81D;">Retornar para etapa anterior</button>
    <button *ngIf="simple.status == 'UNDER_PEDAG_REVIEW'" id="backToTechReview" (click)="finish('', 'NEEDS_TECH_REVIEW')" mat-raised-button style="background: #F5E81D;">Retornar para etapa anterior</button>
    <button *ngIf="simple.status == 'UNDER_TECH_REVIEW' || simple.status == 'INCOMPLETE' || simple.status == ''" id="finishbutton" (click)="finish('','')" mat-raised-button color="primary">Encerrar {{reviewerText}}</button>
    <button *ngIf="simple.status == 'UNDER_TECH_REVIEW' || simple.status == 'UNDER_PEDAG_REVIEW'" id="approveButton" (click)="finish(reviewerMainRole, '')" mat-raised-button style="background: #00A819;">Aprovar</button>
  </div>
  <hr>
  
</div>

<div id="saveAndPaginationSection" style="display:none">
  <div style="text-align: center">
    <button *ngIf="currentPage > 1 && currentPage < numPages" (click)="save()" mat-raised-button color="primary" matTooltip="Gravar o preenchimento do formulário. Os arquivos só serão enviados ao finalizar o envio.">Gravar</button>
  </div>

  <div style="margin-top:4em;">
    <div class="center-div">
      <button mat-button *ngIf="currentPage > 1" style="font-size: x-large; font-weight: bold;" (click)="prevPage()">&lt; Anterior</button>
      <button mat-button id="page1" style="font-size: x-large; font-weight: bold; color: #81D6FF" (click)="page(1)">1</button>
      <button mat-button id="page2" style="font-size: x-large;" (click)="page(2)">2</button>
      <button mat-button id="page3" style="font-size: x-large;" (click)="page(3)">3</button>
      <button mat-button id="page4" style="font-size: x-large;" (click)="page(4)">4</button>
      <button mat-button id="page5" style="font-size: x-large;" (click)="page(5)">5</button>
      <button mat-button id="page6" style="font-size: x-large;" (click)="page(6)">6</button>
      <button mat-button id="page7" style="font-size: x-large;" (click)="page(7)">7</button>
      <button mat-button id="page8" style="font-size: x-large;" (click)="page(8)">8</button>
      <button mat-button id="page9" style="font-size: x-large;" (click)="page(9)">9</button>
      <button mat-button *ngIf="currentPage < numPages" id="next" style="font-size: x-large; font-weight: bold;" (click)="nextPage()"> &gt; Continuar </button>
    </div>
  </div>
</div>