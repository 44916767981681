<mat-card class="card">
  <a [routerLink]="['/documents/' + document.id]" routerLinkActive="router-link-active">
    <img mat-card-image class="mat-card-image" src={{thumb}} alt="Photo">
  </a>

  <mat-card-actions matTooltip="{{document.completeTitle}}">
    <h5 style="text-align: center;"><strong>{{document.title}}</strong></h5>
  </mat-card-actions>

  <a class="card-footers theme-orange" [routerLink]="['/documents/' + document.id]"
    routerLinkActive="router-link-active">
    <strong>+ Informações</strong>
  </a>
  <button *ngIf="isLogged" mat-icon-button matSuffix (click)="like = !like; updateLike(like,document.id)"
    [attr.aria-label]="'Like'" [attr.aria-pressed]="like">
    <mat-icon>{{like ? 'favorite' : 'favorite_border'}}</mat-icon>
  </button>
  <button mat-button (click)="showShareButtons = !showShareButtons;" style="float: right;">
    <mat-icon aria-label="share">share_border</mat-icon>
  </button>
  <div *ngIf="showShareButtons">
    <a target="_blank" rel="nofollow" title="Partilhe no Facebook"
      href="http://www.facebook.com/sharer.php?u=http://re-mar.uac.pt/#/documents/{{document.id}}"
      class="fa fa-facebook"></a>
    <a target="_blank" rel="nofollow" title="Partilhe no Twitter"
      href="http://twitter.com/share?url=http://re-mar.uac.pt/#/documents/{{document.id}}" class="fa fa-twitter"></a>
    <a target="_blank" rel="nofollow" title="Partilhe no Linkedin"
      href="http://www.linkedin.com/shareArticle?mini=true&url=http://re-mar.uac.pt/#/documents/{{document.id}}"
      class="fa fa-linkedin"></a>
  </div>
</mat-card>