<br>
<br>
<div class="grey-division">
  <div class="grey-division-text"><strong>Perfil</strong></div>
</div>
<mat-tab-group mat-stretch-tabs>
  <mat-tab label="OAs incompletos"> 
    <table *ngIf="documentsToComplete.length > 0" style="margin: 0px auto;">
      <tr>
          <th>Título</th>
      </tr>
      <tr *ngFor="let document of documentsToComplete">
        <td>
            <p style="margin-bottom: 0em;font-size: 15px;">
              <a *ngIf="!document.title" [routerLink]="['/documents/' + document.id + '/edit/']" routerLinkActive="router-link-active">
              <strong>Sem título</strong></a>
              <a *ngIf="document.title" [routerLink]="['/documents/' + document.id + '/edit/']" routerLinkActive="router-link-active">
                <strong>{{document.title}}</strong></a>
            </p>
        </td>
      </tr>
    </table>
    <p *ngIf="documentsToComplete.length == 0" style="margin-left: 1em;"><br>Não há objetos de aprendizagem incompletos.</p>
  </mat-tab>
  <mat-tab *ngIf="isReviewer" label="Lista de OAs para revisão">
    <table *ngIf="documentsToReview.length > 0" style="margin: 0px auto;">
      <tr>
        <th></th>
        <th>Título</th>
        <th>Depositante</th>
        <th></th>
      </tr>
      <tr *ngFor="let document of documentsToReview; let i = index">
        <td *ngIf="document.status.includes('NEEDS')">
            <mat-checkbox (change)="document.isValid = ! document.isValid">
            </mat-checkbox>
        </td>
        <td *ngIf="document.status.includes('NEEDS')">
          <p style="margin-bottom: 0em;font-size: 15px;">
            <strong>{{document.title}}</strong>
          </p>
        </td>
        <td *ngIf="document.status.includes('NEEDS')">{{document.owner}}</td>
        <td *ngIf="document.status.includes('NEEDS_TECH_REVIEW')">
          <button mat-raised-button (click)="backDocumentInWorkflow(document.id, i, document.owner, 'INCOMPLETE', document.status)">Retornar etapa anterior</button></td>
        <td *ngIf="document.status.includes('NEEDS_PEDAG_REVIEW')">
          <button mat-raised-button (click)="backDocumentInWorkflow(document.id, i, document.owner, 'NEEDS_TECH_REVIEW', document.status)">Retornar etapa anterior</button></td>
      </tr>
      <tr>
        <td colspan="2"> <button *ngIf="documentsToReview.length > 0" mat-raised-button (click)="reviewDocuments()" >Selecionar</button></td>
        <td></td>
        <td></td>
      </tr>
    </table>
    <br>
   
    <p *ngIf="documentsToReview.length == 0" style="margin-left: 1em;"><br>Não há objetos de aprendizagem novos para a revisão {{roleText}}.</p>
  </mat-tab>
  <mat-tab *ngIf="isReviewer" label="Rev. de OAs em andamento">
    <table *ngIf="documentsUnderReview.length > 0" style="margin: 0px auto;">
      <tr>
        <th>Título</th>
        <th>Depositante</th>
        <th></th>
      </tr>
      <tr *ngFor="let document of documentsUnderReview; let i = index">
        <td *ngIf="document.status.includes('UNDER')">
          <p *ngIf="document.reviewer == tokenInfo.sub" style="margin-bottom: 0em;font-size: 15px;">
              <a  [routerLink]="['/documents/' + document.id + '/edit/?workflow_step=' + this.reviewerMainRole]" routerLinkActive="router-link-active">
                <strong>{{document.title}}</strong>
              </a>  
          </p>
          <p *ngIf="document.reviewer != tokenInfo.sub" style="margin-bottom: 0em;font-size: 15px;">
              <strong>{{document.title}}</strong>
        </p>
        </td>
        <td *ngIf="document.status.includes('UNDER')">{{document.owner}}</td>
        <td *ngIf="document.status.includes('UNDER') && document.reviewer == tokenInfo.sub">
          <button mat-raised-button (click)="backDocumentInWorkflow(document.id, i, document.owner, 'NEEDS', 'UNDER')">Devolver a lista</button></td>
        <td *ngIf="document.status.includes('UNDER') && document.reviewer != tokenInfo.sub">
          <button mat-raised-button style="background: #F5E81D">{{document.reviewer}}</button></td>
      </tr>
    </table>
    <p *ngIf="documentsUnderReview.length == 0" style="margin-left: 1em;"><br>Não há objetos de aprendizagem em revisão {{roleText}}.</p>
    <button mat-icon-button (click)="hideOthers = !hideOthers;showOtherDocuments()" style="margin-left: 30px;"><mat-icon>info</mat-icon>Ver OAs em revisão {{otherRevision}}</button>
    <p id="zeroRevisions" *ngIf="documentsOtherUnderReview.length == 0 && documentsOtherNeedReview.length == 0" style="margin-left: 4em; display: none;"><br>Não há objetos de aprendizagem nesta revisão.</p>
    <table id="otherRevisions" *ngIf="documentsOtherUnderReview.length > 0 || documentsOtherNeedReview.length > 0" style="margin: 0 auto; display: none;">
      <tr>
        <th>Título</th>
        <th>Depositante</th>
        <th>Revisor</th>
      </tr>
      <tr *ngFor="let document of documentsOtherUnderReview;">
        <td>
          <p style="margin-bottom: 0em;font-size: 15px;">
              <strong>{{document.title}}</strong>
          </p>
        </td>
        <td>{{document.owner}}</td>
        <td>
          <button mat-raised-button style="background: #F5E81D">{{document.reviewer}}</button></td>
      </tr>
      <tr *ngFor="let document of documentsOtherNeedReview;">
        <td>
          <p style="margin-bottom: 0em;font-size: 15px;">
              <strong>{{document.title}}</strong>
          </p>
        </td>
        <td>{{document.owner}}</td>
          <td>
            <button mat-raised-button style="background: #F5E81D">Ainda sem revisor</button></td>
      </tr>
    </table>
  </mat-tab>
  <mat-tab label="OAs submetidos">
    <table *ngIf="documentsSubmitted.length > 0" style="margin: 0px auto;">
      <tr>
          <th>Título</th>
          <th style="text-align: center;">Status</th>
      </tr>
      <tr *ngFor="let document of documentsSubmitted">
        <td>
            <p style="margin-bottom: 0em;font-size: 15px;">
              <a *ngIf="document.status != 'REVIEWED'"><strong>{{document.title}}</strong></a>
              <a *ngIf="document.status == 'REVIEWED'" [routerLink]="['/documents/' + document.id]" routerLinkActive="router-link-active">
                <strong>{{document.title}}</strong></a>
            </p>
        </td>
        <td *ngIf="document.status == 'NEEDS_TECH_REVIEW' || document.status == 'UNDER_TECH_REVIEW'">
          <button mat-raised-button style="background: #F54411">Em rev. científica</button></td>
        <td *ngIf="document.status == 'NEEDS_PEDAG_REVIEW' || document.status == 'UNDER_PEDAG_REVIEW'">
          <button mat-raised-button style="background: #F5E81D">Em rev. pedagógica</button></td>
        <td *ngIf="document.status == 'REVIEWED'"><button mat-raised-button style="background: #00A819">Aprovado</button></td>
        <td *ngIf="document.status == 'DISCARTED'"><button mat-raised-button style="background: #797e7a">Descartado</button></td>
      </tr>
    </table>
    <p *ngIf="documentsSubmitted.length == 0" style="margin-left: 1em;"><br>Não há objetos de aprendizagem submetidos.</p>
  </mat-tab>
  <mat-tab *ngIf="isAdmin" label="Admin. OAs">
    <table *ngIf="documentsToAdmin.length > 0" style="margin: 0px auto;">
      <tr>
        <th>ID</th>
        <th>Título</th>
        <th>Depositante</th>
        <th>Etapa Atual</th>
        <th>Último Revisor</th>
        <th></th>
        <th>Ontologia</th>
      </tr>
      <tr *ngFor="let document of documentsToAdmin; let i = index">
        <td>
          <strong>{{document.id}}</strong>
        </td>
        <td>
          <p style="margin-bottom: 0em;font-size: 15px;">
              <strong>{{document.title}}</strong>
          </p>
        </td>
        <td><input matInput [(ngModel)]="document.owner"></td>
        <td>
          <mat-select style="width: 200px;" required [(ngModel)]="document.current_status" name="status">    
              <mat-option value="DISCARTED"> DISCARTED </mat-option>
              <mat-option value="INCOMPLETE"> INCOMPLETE </mat-option>
              <mat-option value="NEEDS_TECH_REVIEW"> NEEDS_TECH_REVIEW </mat-option>
              <mat-option value="UNDER_TECH_REVIEW"> UNDER_TECH_REVIEW </mat-option>
              <mat-option value="NEEDS_PEDAG_REVIEW"> NEEDS_PEDAG_REVIEW</mat-option>
              <mat-option value="UNDER_PEDAG_REVIEW"> UNDER_PEDAG_REVIEW</mat-option>
              <mat-option value="REVIEWED"> REVIEWED</mat-option>
            </mat-select>
        </td>
        <td><input matInput [(ngModel)]="document.reviewer"></td>
        <td><button mat-raised-button (click)="updateDocument(document.id, document.name, 
          document.owner, document.current_status, document.reviewer)">Atualizar</button></td>
        <td style="text-align: center !important;">
          <button mat-icon-button disabled="{{document.current_status != 'REVIEWED'}}" matSuffix (click)="updateOntology(document)" 
            matTooltip="{{document.hasOntology ? 'Atualizar ontologia' : 'Criar ontologia'}}">
            <mat-icon>{{document.hasOntology ? 'check_circle' : 'report_problem'}}</mat-icon>
          </button>
        </td>
      </tr>
    </table>
    <p *ngIf="documentsToAdmin.length == 0" style="margin-left: 1em;"><br>Não há objetos de aprendizagem para administrar.</p>
  </mat-tab>
</mat-tab-group>

<div class="center-div">
  <button (click) = "logoutUser()" mat-button class="logout-button">
    Logout
  </button>
</div>

