<br>
<br>
  <div class="grey-division">
      <div class="grey-division-text"><strong>Sobre</strong></div>
  </div>

  <!-- <img src="../assets/images/about.jpg" alt="logo" style="width:100%; margin-bottom: 6em"/> -->

  <div class="center-paragraph">
    <br>
    <br>
    <a href="https://fgf.uac.pt/projeto/857" target="_blank">
      <img src="../assets/images/Logo mostarda cheio.png" alt="FGF" class="center-image">
    </a>
    <br>
    <br>
    <h3>Re-Mar: Repositório de objetos de aprendizagem sobre o ambiente Marinho</h3>
    <br>
    <p class="paragraph">Re-Mar é parte do <a href="https://fgf.uac.pt/projeto/857" target="_blank">projeto SeaThings</a> 
      que visa promover a alfabetização oceânica através do envolvimento ativo de investigadores de 
      diferentes universidades, escolas e centros de ciência da região, na criação de Objetos de 
      Aprendizagem (OAs) sobre os oceanos e na sua disponibilização na web num repositório de 
      acesso aberto. Utilizando tecnologias de inteligência artificial, serão desenvolvidas ferramentas de apoio à 
      pesquisa, criação e gestão de OAs.</p>

    <p class="paragraph">O Learning Technology Standards Committee usa um padrão (IEEE Standard 1484.12.1) que
      define um Objeto de Aprendizagem como qualquer entidade digital ou não digital que pode ser usada para aprendizagem, 
      educação ou treinamento. Esta definição é complementada pelos seguintes recursos principais:</p>
      <br>
      <ul>
        <li><p class="paragraph">- reutilizável : um único objeto de aprendizagem pode ser usado em vários contextos para vários fins;</p></li>
        <li><p class="paragraph">- autossuficiente : cada objeto de aprendizagem pode ser realizado de forma independente;</p></li>
        <li><p class="paragraph">- agregação : objetos de aprendizagem podem ser agrupados em coleções maiores de conteúdo, incluindo estruturas tradicionais de cursos;</p></li>
        <li><p class="paragraph">- metadados: cada objeto de aprendizagem possui informações descritivas que permitem que seja facilmente encontrado por meio de uma pesquisa.</p></li>
      </ul> 
     <br>
     <p class="paragraph">
      Com base no padrão IEEE LOM, um novo padrão Agent Based Learning Objects (OBAA) foi proposto 
      estendendo metadados <a href="http://link.springer.com/10.1007/978-3-642-16552-8_27" target="_blank">(Vicari 2010)</a> para adicionar suporte para múltiplas plataformas 
      (como TV digital e plataformas de dispositivos móveis), permitindo a construção de objetos aprendizagem interoperável. Este padrão permite ainda incluir novos campos na educação 
      que promovam a experiência colaborativa na utilização de OAs; adicionar acessibilidade cobrindo a maioria dos requisitos para o uso de diferentes dispositivos por parte de cidadãos 
      com necessidades especiais; e, por fim, adicionar informações de segmentação, possibilitando indexar segmentos de um objeto de aprendizagem por assunto, destaque ou atividade.</p>
   
      
      
 
  
  
  </div>