import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { RestService } from '../rest.service';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomSnackbarComponent } from '../custom-snackbar/custom-snackbar.component';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})

export class ContactComponent implements OnInit {

  name: string;
  email: string;
  message: string;
  ipAddress: string;

  public emailForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private router: Router, 
    private restApi: RestService, private http:HttpClient,
    private _snackBarAdvice: MatSnackBar) {
      this.emailForm = this.formBuilder.group({
        name: ['', Validators.required],
        email: ['', Validators.required],
        message: ['', Validators.required],
        ip: [''],
      });
  }

  ngOnInit() {
    this.getIPAddress();
  }

  openSnackBar(message: string, action: string, duration: number, snackType: string, role?: string) {
    let snackBarRef = this._snackBarAdvice.openFromComponent(CustomSnackbarComponent, {
      duration: duration,
      horizontalPosition: 'center',
      //verticalPosition: 'top',
      data: { message: message, action: action, snackType: snackType, role: role }
    });
  }

  getIPAddress(){
    this.http.get("https://ipapi.co/ip/", {responseType: 'text'}).subscribe((res:any)=>{
      this.ipAddress = res;
    });
  }

  processEmail() {
    if (this.emailForm.valid) {
      this.emailForm.patchValue({ip: this.ipAddress});
      let seq = this.restApi.sendEmail(this.emailForm.value);

      seq.subscribe((response) => {
          console.log(response)
          if (response == true) {
              this.openSnackBar("Mensagem enviada com sucesso!", "OK", 3000, "Info");
              this.router.navigate(['/']);
          }else if (response == 'e') {
            this.openSnackBar("Erro ao enviar mensagem.", "OK", 3000, "Info");
          }
      },err => {
        this.openSnackBar("Erro ao enviar mensagem.", "OK", 3000, "Info");
        console.error('ERROR', err);
      });
    }
  }
}
