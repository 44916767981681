<br>
<br>
<div class="main-image-div">
  <div class="inside-image">
    <div class="image-text editable"> Pesquisa avançada
        <mat-icon
          [matTooltip]="tooltipAdvancedSearch" matTooltipClass="material-tooltip">info_outline</mat-icon>
    </div>
    <div class="search-box center-div">

      <span>
        <mat-form-field floatLabel="never" class="search-bar">
          <input matInput [(ngModel)]="searchText" name="searchTexts" placeholder="Pesquisar objetos de aprendizagem">

        </mat-form-field>
      </span>
      <button *ngIf = "isLogged" mat-icon-button matTooltip="Pesquisar somente em vossos favoritos" matSuffix (click)="searchFavorites = !searchFavorites;">
        <mat-icon style="position: absolute; bottom: 70%; font-size: 40px;">{{searchFavorites ? 'favorite' : 'favorite_border'}}</mat-icon>
      </button>
      <a style="cursor:pointer" (click)="search(0, 10)">
        <div class="dot">
          <mat-icon style="color:#2b82b8; margin-right:0.5em; margin-top:0.2em; font-size: 2em" aria-hidden="false"
            aria-label="search">search</mat-icon>
        </div>
      </a>
    </div>
    <div matTooltip = "Também pode-se adicionar termos relacionados à pesquisa">
      <div style="display: flex;justify-content: center;">
        <mat-chip-list selectable multiple>
          <mat-chip class="search-options" #chip="matChip"
          (click)="chip.toggleSelected(true);selectSuggestion(removeLang(narrowTerms.suggestion));" [value]="narrowTerms.suggestion"
              *ngFor="let narrowTerms of searchSuggestions.narrowTerms; let i = index" 
              [attr.data-index]="i">
            <mat-icon *ngIf="chip.selected">check</mat-icon>
            <span class="chip-text">{{removeLang(narrowTerms.suggestion)}}</span></mat-chip>
        </mat-chip-list>
      </div>
      <div style="display: flex;justify-content: center; padding-top: 10px;">
        <mat-chip-list selectable multiple>
          <mat-chip class="search-options" #chip="matChip"
            (click)="chip.toggleSelected(true);selectSuggestion(removeLang(broaderTerms.suggestion));" [value]="broaderTerms.suggestion"
              *ngFor="let broaderTerms of searchSuggestions.broaderTerms; let i = index" 
              [attr.data-index]="i">
            <mat-icon *ngIf="chip.selected">check</mat-icon>
            <span class="chip-text">{{removeLang(broaderTerms.suggestion)}}</span></mat-chip>
        </mat-chip-list>
      </div>
    </div>
    <div>
      <mat-form-field>
        <mat-select [formControl]="learningCycles" name="learningCycle" placeholder="Ciclo de ensino" multiple>
          <mat-option *ngFor="let learningCycle of learningCyclesList" [value]="learningCycle">{{learningCycle}}</mat-option>
        </mat-select>
      </mat-form-field>
      &nbsp;
      <mat-form-field>
        <mat-select [formControl]="knowledgeAreas" name="knowledgeArea" placeholder="Área temática" multiple>
          <mat-option *ngFor="let knowledgeArea of knowledgeAreasList" [value]="knowledgeArea">{{knowledgeArea}}</mat-option>
        </mat-select>
      </mat-form-field>
      &nbsp;
      <mat-form-field>
        <mat-select [formControl]="curricularAreas" name="curricularArea" placeholder="Área curricular" multiple>
          <mat-option *ngFor="let curricularArea of curricularAreasList" [value]="curricularArea">{{curricularArea}}</mat-option>
        </mat-select>
      </mat-form-field>
      &nbsp;
      <mat-form-field>
        <mat-select [formControl]="institution" name="institution" placeholder="Instituição" multiple>
          <mat-option *ngFor="let institution of institutionList" [value]="institution">{{institution}}</mat-option>
        </mat-select>
      </mat-form-field>
      <!-- &nbsp;
      <button id="filterbutton" (click)="getFilterQuery()" mat-raised-button color="primary">Filtrar</button> -->
    </div>
    <!-- <div>Termos relacionados: (i)</div> -->
    
    <!--<div>
      <div class="search-buttons">
        <div style="float:left;" class="search-title">
          <mat-chip-list>
            <mat-chip selected class="search-options" (click)="onSelectPrevious()"><span
                class="chip-text">{{searchOptions.name}}</span></mat-chip>
          </mat-chip-list>
        </div>
        <div style="float:left; width:70%">
          <mat-chip-list>
            <mat-chip class="search-options" #chip="matChip" [selectable]=false
              *ngFor="let field of searchOptions.next; let i = index" [attr.data-index]="i"
              (click)="onSelect(i, field.name); chip.select();">
              <span class="chip-text">{{field.name}}</span></mat-chip>
          </mat-chip-list>

        </div>
      </div>
    </div>-->


  </div>
</div>

<!-- <div class="grey-division">
          <div class="grey-division-text"><strong>Seus Favoritos</strong></div>
          <div class="favorites">
              
              <mat-grid-list cols=3 rowHeight=1:1.1 gutterSize=2em >


                  <mat-grid-tile >
                      <app-documents></app-documents>
                  </mat-grid-tile>

                  <mat-grid-tile>
                      <app-documents></app-documents>
                  </mat-grid-tile>

                  <mat-grid-tile>
                      <app-documents></app-documents>
                  </mat-grid-tile>

              </mat-grid-list>
          
          </div>
      </div>
       -->

<div class="grey-division-text"><strong>Resultados</strong></div>

<mat-paginator #paginatorTop [length]="totalItems" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" (page)="pageChanged($event)">
</mat-paginator>

<div style="margin-left: 0.5em; font-size:1.5em; margin-top: 1.5em; width:90%">
  <mat-grid-list [cols]="breakpoint" rowHeight="18em" (window:resize)="onResize($event)">
      <mat-grid-tile *ngFor="let document of documents">
          <app-documents [document]="document"></app-documents>
      </mat-grid-tile>
  </mat-grid-list>
</div>

<mat-paginator #paginatorBottom [length]="totalItems" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" (page)="pageChanged($event)">
</mat-paginator>