import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { RestService, endpoint } from '../rest.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomSnackbarComponent } from '../custom-snackbar/custom-snackbar.component';

@Component({
  selector: 'app-request-reset-password',
  templateUrl: './request-reset-password.component.html',
  styleUrls: ['./request-reset-password.component.css']
})
export class RequestResetPasswordComponent implements OnInit {

  RequestResetForm: FormGroup;
  forbiddenEmails: any;
  errorMessage: string;
  successMessage: string;
  IsvalidForm = true;

  constructor(private rest: RestService, private router: Router,
    private _snackBarAdvice: MatSnackBar) { }

  ngOnInit(): void {
    this.RequestResetForm = new FormGroup({
      'email': new FormControl(null, [Validators.required, Validators.email], this.forbiddenEmails),
    });
  }

  openSnackBar(message: string, action: string, duration: number, snackType: string, role?: string) {
    let snackBarRef = this._snackBarAdvice.openFromComponent(CustomSnackbarComponent, {
        duration: duration,
        horizontalPosition: 'center',
        //verticalPosition: 'top',
        data: { message: message, action: action, snackType: snackType, role: role }
    });
  }

  RequestResetUser(form) {
    // console.log(form)
    document.body.style.cursor="wait";
    if (form.valid) {
      this.IsvalidForm = true;
      this.rest.requestResetPassword(this.RequestResetForm.value).subscribe(
        response => {
          document.body.style.cursor="initial";
          if (response == true) {
            this.openSnackBar("E-mail para redefinição de palavra-passe enviado com sucesso.", "OK", 3000, "Info");
            this.router.navigate(['/']);
          }else {
            this.openSnackBar(response.toString(), "OK", 3000, "Info");
          }      
        },
        err => {
          this.openSnackBar("Erro ao enviar e-mail para redefinição de palavra-passe.", "OK", 3000, "Info");
          if (err.error.message) {
            this.errorMessage = err.error.message;
            console.error('ERROR', this.errorMessage);
          }
        }
      );
    } else {
      this.IsvalidForm = false;
    }
  }
}
