<br>
<br>
<div class="grey-division">
    <div class="grey-division-text"><strong>Novo Registo</strong></div>
</div>

<div class="center-div">
<form fxFlex [formGroup] = "signUpForm">

  <mat-form-field class="input-size">
    <input formControlName='name' required matInput placeholder="Nome completo">
  </mat-form-field><br><br>
  <mat-form-field class="input-size">
      <input type="email" required email="true" formControlName='username' matInput placeholder="E-mail">
  </mat-form-field><br><br>
  <mat-form-field class="input-size">
    <input [type]="hide ? 'password' : 'text'" required formControlName='password' matInput placeholder="Palavra-passe">
    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
      <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
  </mat-form-field><br><br>
  <mat-form-field class="input-size">
    <input [type]="hideConfirm ? 'password' : 'text'" required formControlName='confirmPassword' matInput placeholder="Confirmar palavra-passe">
    <button mat-icon-button matSuffix (click)="hideConfirm = !hideConfirm" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideConfirm">
      <mat-icon>{{hideConfirm ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
  </mat-form-field><br><br>
  
  <mat-checkbox  formControlName='receiveEmail' (change)="allowEmail = !allowEmail;">Desejo receber e-mails com novidades do repositório.</mat-checkbox>
  <br><br>
  <re-captcha style="text-align: center; display: inline-block;"
  equired 
  formControlName='captcha'
  (resolved)="resolved($event)"
  (error)="onError($event)"
  errorMode="handled"
  siteKey="6Lc191saAAAAAP_SU17_higZhgcgH6KgV8asIRsw"
  ></re-captcha>
  <!-- key t 6LcOuyYTAAAAAHTjFuqhA52fmfJ_j5iFk5PsfXaU -->
  <!-- 6Lc191saAAAAAP_SU17_higZhgcgH6KgV8asIRsw -->
  <br>
  <br>
  <button class="create-button" mat-raised-button (click)="onSignUp()">Registar</button>
  </form>
</div>
