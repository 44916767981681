import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contributors',
  templateUrl: './contributors.component.html',
  styleUrls: ['./contributors.component.css']
})
export class ContributorsComponent implements OnInit {

  gridColumns = 4;
  contributors: any;
  folder = "../assets/images/contributors/"

  constructor() { }

  ngOnInit(): void {
    this.contributors =  [
      { filename: "logo-expolab.jpg", link: "http://expolab.centrosciencia.azores.gov.pt/" },
      { filename: "logo-noaa.png", link: "https://www.noaa.gov/" },
      { filename: "oma-logo.png", link: "https://www.oma.pt/" },
      { filename: "uac-logo.png", link: "https://uac.pt/" },
      { filename: "logo-escolaazul.jpg", link: "https://escolaazul.pt/" },
      { filename: "CIIMAR-logo.jpg", link: "https://www2.ciimar.up.pt/" },
      { filename: "3_Horitzontal-Color-EN.png", link: "https://www.socib.es/"},
      { filename: "logo-Ocean-Observers.png", link: "https://www.oceanobservers.org/"},
      { filename: "logo anp wwf.png", link: "https://www.natureza-portugal.org/"},
      { filename: "WEB&IA.png", link: "https://webia.uac.pt/"}
    ]
  }

}
