import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { endpoint } from '../rest.service';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css']
})

export class ViewComponent implements OnInit {

  @Input() fileId: any;
  index: any;
  content: any;
  updatedFileId: any;
  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.index = 0;
    //update allowed extension files
    this.updatedFileId = [];
    for(let i = 0; i < this.fileId.length; i++) {
      let fileExtension = this.fileId[i].contentType.substr(this.fileId[i].contentType.lastIndexOf('.') + 1);
      // console.log(fileExtension)
      switch(fileExtension) {
        case "image/png": case "image/jpeg": case "image/jpg": case "image/gif": case "image/tiff":
        case "video/mp4": case "application/pdf":
          this.updatedFileId.push(this.fileId[i]);
      }
    }
    this.content = this.sanitize();
  }

  changeFile(newIndex) {
    this.index = newIndex;
    this.content = this.sanitize();
  }

  sanitize() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(endpoint + '/files/' + this.updatedFileId[this.index].id + "/view");
  }
}
