<br>
<br>
<div class="grey-division">
  <div class="grey-division-text"><strong>Login</strong></div>
</div>

<div class="center-div">
  <form fxFlex [formGroup]="loginForm">

    <mat-form-field class="input-size">
      <input formControlName='username' required matInput placeholder="E-mail">
    </mat-form-field><br><br>
    <mat-form-field class="input-size">
      <input [type]="hide ? 'password' : 'text'" required formControlName='password' matInput placeholder="Palavra-passe">
      <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
    </mat-form-field>
    <br>
    <button class="login-button" mat-raised-button (click)="doLogin()">Entrar</button>
    <br><br><br>
    <p class="center-paragraph"> Esqueceu-se da palavra-passe? <a class = "orange-letter" routerLink = "/request-reset-password">Redefina!</a> </p>
    <br>
    <p class="center-paragraph"> Não tem uma conta? <a class = "orange-letter" routerLink = "/register">Registe-se!</a> </p>
  </form>
</div>