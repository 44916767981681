<div style="position: sticky;top:0;z-index: 999;background-color: white;">
    <div mat-dialog-content>
        <p class="paragraph">Selecione os objetos de aprendizagem relacionados:</p>
    </div>
    <div>
        <input type="search" [(ngModel)]="searchText" class="form-control"
            placeholder="Nome do objeto de aprendizagem...">
        <button mat-raised-button (click)="search(0,10)">Filtrar</button>
    </div>
    <mat-paginator [length]="totalItems" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"
        (page)="pageChangedOnDialog($event)">
    </mat-paginator>
</div>

<div style="font-size:1.5em; margin-top: 1.5em; width:100%">
    <div *ngIf="documentsTiny.length==0">
        <p class="paragraph">Ainda não há objetos de aprendizagem para relacionamento.</p>
    </div>
    <mat-grid-list cols=2 rowHeight=20em [gutterSize]="'10px'">
        <mat-grid-tile *ngFor="let document of documentsTiny">
            <app-document-tiny [documentsTiny]="document"></app-document-tiny>
        </mat-grid-tile>
    </mat-grid-list>
</div>

<div style="position: sticky;bottom: 0;z-index: 999;background-color: white;">
    <div mat-dialog-actions>
        <button mat-raised-button (click)="onCancelClick()">Cancelar</button>
        <button mat-raised-button [mat-dialog-close]="documentsTiny" cdkFocusInitial>Ok</button>
    </div>
</div>