import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RestService } from '../rest.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomSnackbarComponent } from '../custom-snackbar/custom-snackbar.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup;
  hide = true;
  page = 'none';
  
  constructor(private formBuilder: FormBuilder, private router: Router, 
    private route: ActivatedRoute, private _snackBarAdvice: MatSnackBar,
     private restApi: RestService) {
      this.loginForm = this.formBuilder.group({
        username: ['', Validators.required],
        //email: ['', Validators.required],
        password: ['', Validators.required],
        //confirmPassword: ['', Validators.required]
      });
  }

  ngOnInit() {
    console.log('entrou na página de login')
    this.route.queryParams
      .subscribe(params => {
        this.page = params.page;
        console.log(this.page);
      }
    );
  }

  openSnackBar(message: string, action: string, duration: number, snackType: string, role?: string) {
    let snackBarRef = this._snackBarAdvice.openFromComponent(CustomSnackbarComponent, {
      duration: duration,
      horizontalPosition: 'center',
      //verticalPosition: 'top',
      data: { message: message, action: action, snackType: snackType, role: role }
    });
  }

  doLogin(){
    if (this.loginForm.valid) {
      let seq = this.restApi.postLogin(this.loginForm.value);
      // console.log(this.loginForm.value);
      seq.subscribe((response) => {
        console.log(response)
          if (response == 'ok') {
            this.openSnackBar("Seja bem-vindo\\a ao Re-Mar!", "OK", 3000, "Info");
            switch(this.page){
              case 'add': this.router.navigate(['/documents/add']); break;
              default: this.router.navigate(['/']);
            }
              
          }else if (response == undefined){
            this.openSnackBar("Erro ao realizar login.", "OK", 3000, "Info");
          }
      },err => {
        this.openSnackBar("Erro ao registar utilizador. Envie mensagem de contacto.", "OK", 3000, "Info");
          console.error('ERROR', err);
    
        });
    } else {
      this.openSnackBar("Preencha todos os campos.", "OK", 3000, "Info");
    }
  }
}
