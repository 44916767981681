import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/rest.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  isLogged: boolean;
  userEmail: string;
  userName: string;
  totalPendencies: number;
  public loginForm: FormGroup;
  
  constructor(private rest: RestService, private formBuilder: FormBuilder) {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });    
  }

  ngOnInit() {
      this.rest.logged.subscribe(logged => this.isLogged = logged)
      this.rest.email.subscribe(email => this.userEmail = email)
      this.rest.name.subscribe(name => this.userName = name)
      
      if(localStorage.getItem('token') != null) {
        let tokenInfo = JSON.parse(atob(localStorage.getItem('token').match(/\..*\./)[0].replace(/\./g, '')));
        this.userName = decodeURIComponent(escape(tokenInfo.name));
        let reviewerMainRole = this.getReviewRole(tokenInfo.roles);
        if(Math.floor((new Date).getTime() / 1000) <= tokenInfo.exp && this.userName != "Usuário anônimo") {
          // (parseInt(localStorage.getItem('lastPendencies'))) ? this.totalPendencies = parseInt(localStorage.getItem('lastPendencies')) : this.totalPendencies = 0;
          let queryPendencies = "q=(owner:\"" + tokenInfo.sub + "\" AND status:INCOMPLETE)";
          if(reviewerMainRole != "author") {
            let role = reviewerMainRole.substring(0, reviewerMainRole.length - 2).toUpperCase();
            queryPendencies += " OR (status:NEEDS_" + role;
            queryPendencies += ") OR (reviewer:\"" + tokenInfo.sub + "\"";
            queryPendencies += " AND status:UNDER_" + role + ")&rows=0";
          }

          this.rest.querySOLR(queryPendencies, 'DocumentTinyDto').subscribe((data: any) => {
            if(data.response.numFound > 0) {
              this.rest.pendencies.emit(data.response.numFound);
              //console.log(data.response.numFound)
            } else {
              this.rest.pendencies.emit(0);
            }
            localStorage.setItem('lastPendencies', data.response.numFound);
          });
          this.isLogged = true; 
          this.rest.pendencies.subscribe(pendencies => this.totalPendencies = pendencies)
        }
      }

      if(!localStorage.getItem('token') || this.tokenExpired(localStorage.getItem('token_expiration'))){
        this.loginForm.setValue({
          username: "anonymous@uac.pt", 
          password: "anonymous"
        });
  
        this.rest.postLogin(this.loginForm.value).subscribe((data: {}) => {
          //console.log(data);
          window.location.reload();
        });
      }
  }

  getReviewRole(roles) {
    roles = roles.toString().split(',');
    for(let role of roles) {
      switch(role) {
        case "tech_reviewer": return "tech_reviewer";
        case "pedag_reviewer": return "pedag_reviewer";
      }
    }
    return "undefined";
  }

  logoutUser(){
    this.rest.logged.emit(false)
  }

  private tokenExpired(expiry: string) {
    return (Math.floor((new Date).getTime() / 1000)) >= +expiry;
  }


}
