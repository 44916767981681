export const keywords_suggestions: string[] = ['Algas', 'Alterações climáticas', 'Amostragens', 'Áreas protegidas', 'Artes', 'Aves',
    'Baixa profundidade / Subtidal', 'Biotecnologia marinha', 'Circulação oceânica', 'Correntes',
    'Ecossistemas marinhos', 'Embarcações', 'Energia', 'Entre marés / Interdital', 'Equipamentos marítimos',
    'Espécies não indigenas / invasoras', 'Fontes hidrotermais', 'Invertebrados', 'Mamíferos marinhos', 'Marés',
    'Microorganismos e marés vermelhas', 'Ondas', 'Peixes', 'Plancton', 'Plantas costeiras', 'Plataforma continental',
    'Praias arenosas', 'Produtividade', 'Química da água do mar', 'Sustentabilidade', 'Tartarugas', 'Teias tróficas',
    'Tubarões', 'Turismo, Desporto, Lazer', 'Zona costeira', 'Recursos marinhos / Pescas'];

export const curriculumAreas_predefined = [
      {col_1: { name: "Matemática", isValid: false }},
      {col_1: { name: "Português", isValid: false }},
      {col_1: { name: "Estudo do Meio", isValid: false }},
      {col_1: { name: "Expressões (Plástica, Musical, Dramática/Teatro)", isValid: false }},
      {col_1: { name: "Educação Física", isValid: false }},
      {col_1: { name: "Cidadania", isValid: false }},
      {col_1: { name: "Ciências Naturais", isValid: false }},
      {col_1: { name: "Inglês", isValid: false }},
      {col_1: { name: "TIC", isValid: false }},
      {col_1: { name: "História", isValid: false }},
      {col_1: { name: "Educação Visual", isValid: false }},
      {col_1: { name: "Educação Tecnológica", isValid: false }},
      {col_1: { name: "Educação Musical", isValid: false }},
      {col_1: { name: "Biologia", isValid: false }},
      {col_1: { name: "Geografia", isValid: false }},
      {col_1: { name: "Físico-Química", isValid: false }},
];

export const age_predefined = [
    {col_1: {name: "Educação pré-escolar", isValid: false}}, 
    {col_1: {name: "Básico 1º ciclo", isValid: false}}, 
    {col_1: {name: "Básico 2º ciclo", isValid: false}},
    {col_1: {name: "Básico 3º ciclo", isValid: false}},
    {col_1: {name: "Ensino secundário", isValid: false}},
    {col_1: {name: "Ensino superior", isValid: false}},
    {col_1: {name: "Ensino profissional", isValid: false}},
];

export const knowledgeArea_predefined = [
    {col_1: {name: "Ciências exatas", isValid: false}}, 
    {col_1: {name: "Ciências humanas", isValid: false}}, 
    {col_1: {name: "Ciências naturais", isValid: false}},
    {col_1: {name: "Ciências tecnológicas", isValid: false}},
];

export const target_predefined = [
    {col_1: {name: "Professores/as", isValid: false}, 
     col_2: {name: "Formadores/as", isValid: false}},
    {col_1: {name: "Estudantes", isValid: false}, 
     col_2: {name: "Outros", isValid: false}}
];

export const keywords_predefined = [
    {col_1: {name: "Biodiversidade", isValid: false}, 
     col_2: {name: "Ecologia marinha", isValid: false},
     col_3: {name: "Economia do mar", isValid: false}},
    {col_1: {name: "Geografia", isValid: false}, 
     col_2: {name: "Geologia / Fundos / Sedimentos", isValid: false},
     col_3: {name: "Mar profundo", isValid: false}},
    {col_1: {name: "Oceanografia", isValid: false}, 
     col_2: {name: "Património / Arqueologia subaquáticos", isValid: false},
     col_3: {name: "Poluição / Lixo / Ruído", isValid: false}},
];

export const resources_predefined = [
    {col_1: {name: "Questionário", isValid: false}, 
     col_2: {name: "Desafio", isValid: false},
     col_3: {name: "Prova", isValid: false},
     col_4: {name: "Enunciado de questão", isValid: false},
     col_5: {name: "", isValid: false}},
    {col_1: {name: "Plano de aula", isValid: false}, 
     col_2: {name: "Saída de campo", isValid: false},
     col_3: {name: "Experiência laboratorial", isValid: false},
     col_4: {name: "Guião", isValid: false},
     col_5: {name: "Diapositivos", isValid: false}},
    {col_1: {name: "Livro", isValid: false}, 
     col_2: {name: "Infografia", isValid: false},
     col_3: {name: "Página web", isValid: false},
     col_4: {name: "Texto teatral / dramatizado", isValid: false},
     col_5: {name: "Texto narrado", isValid: false}},
    {col_1: {name: "Documentário em vídeo", isValid: false}, 
     col_2: {name: "Demonstração filmada", isValid: false},
     col_3: {name: "Aula gravada ou filmada", isValid: false},
     col_4: {name: "", isValid: false},
     col_5: {name: "", isValid: false}},
    {col_1: {name: "Jogo", isValid: false}, 
     col_2: {name: "Simulação", isValid: false},
     col_3: {name: "", isValid: false},
     col_4: {name: "", isValid: false},
     col_5: {name: "", isValid: false}},
];