<div>
    <p class="paragraph">Idioma: <span style="font-weight:normal">{{simple.language}}</span></p><br>
    <p class="paragraph">Descrição: <span style="font-weight:normal">{{simple.description}}</span></p><br>
    <div *ngFor="let relation of simple.relationWith"> 
        <p *ngIf="relation.entry" class="paragraph">{{translateRelationKind(relation.kind)}}: </p>
        <div *ngFor="let relation_id of showSameRelation(relation.entry)">
            <a *ngIf="relation.entry" target="_blank" [routerLink]="['/documents/' + relation_id]" routerLinkActive="router-link-active">http://re-mar.uac.pt/#/documents/{{relation_id}}</a>
        </div>
        <br *ngIf="relation.entry">
    </div>
    <p class="paragraph">Palavras-chave: </p>
    <div *ngFor="let keyword of simple.keywords">
        <span style="font-weight:normal">{{keyword}} </span>
    </div>
    <br>
    <p class="paragraph">Etapa(s) da educação: </p>
    <div *ngFor="let age of simple.age">
        <span style="font-weight:normal">{{age}} </span>
    </div>
    <br>
    <p class="paragraph">Tipos de objeto / recurso: </p>
    <div *ngFor="let resource of simple.resources">
        <span style="font-weight:normal">{{resource}} </span>
    </div>
    <br>

    <p class="paragraph">Utilizadores finais:</p>
    <div *ngFor="let target of simple.target">
        <span style="font-weight:normal">{{target}} </span>
    </div>
    <br> 
        
    <p class="paragraph">Área(s) do conhecimento: </p>
    <div *ngFor="let knowledgeArea of simple.knowledgeArea">
        <span style="font-weight:normal">{{knowledgeArea}} </span>
    </div>
    <br> 
    
    <p class="paragraph">Tempo necessário para aprendizagem: 
        <span *ngIf="simple.typicalLearningTime" style="font-weight:normal">{{simple.typicalLearningTime}}</span>
        <span *ngIf="!simple.typicalLearningTime" style="font-weight:normal">Não se aplica</span>
    </p>
    <br>
    <p class="paragraph">Nível de interação: <span style="font-weight:normal">{{simple.interaction}}</span></p><br>
    <p class="paragraph">Licença: 
        <a *ngIf="simple.licence && simple.licence.includes('by-sa')" rel="license" target="_blank" href="https://creativecommons.org/licenses/by-sa/4.0/"><img alt="Licença Creative Commons" style="border-width:0" src="https://i.creativecommons.org/l/by-sa/4.0/88x31.png" /></a>
        <a *ngIf="simple.licence && simple.licence.includes('by-nc-sa')" rel="license" target="_blank" href="https://creativecommons.org/licenses/by-nc-sa/4.0/"><img alt="Licença Creative Commons" style="border-width:0" src="https://i.creativecommons.org/l/by-nc-sa/4.0/88x31.png" /></a>
    </p><br>
    <p class="paragraph">Contribuições:</p>
    <div *ngFor="let author of simple.author" style="margin-left:13px; font-weight:normal">
        <span style="font-weight:normal" *ngIf="author.name">{{author.name}} - {{author.institution}} - {{showTranslatedAuthorRole(author.role)}}</span>
    </div>
    <br>
    <p *ngIf="simple.linkOfLo" class="paragraph">Link de acesso:</p>
    <div *ngFor="let link of simple.linkOfLo.split(' ')" style="margin-left:13px; font-weight:normal">
        <a *ngIf="simple.linkOfLo" href="javascript: void(0);" (click)="openSnackBar('Atenção! Você está a sair do Re-Mar...', 'Concordo', link);">{{link}}</a>
    </div>
    <br>
</div>