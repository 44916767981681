<div>
    <div class="inside-image">
        <div class="image-text editable"> Adicione os seus objetos de aprendizagem, pesquise, partilhe!</div>

        <div class="search-box center-div">
            <span>
                <mat-form-field floatLabel="never" class="search-bar">
                    <input matInput [(ngModel)]="searchText" name="searchTexts"
                        placeholder="Pesquisar objetos de aprendizagem">
                </mat-form-field>
            </span>

            <a style="cursor:pointer" [routerLink]="['/search/'+searchText]  " routerLinkActive="router-link-active">
                <div class="dot-search">
                    <mat-icon class="dot-icon-search"
                        aria-hidden="false" aria-label="search">search</mat-icon>
                </div>
            </a>
        </div>
        <br>
        <br>
        <div class="add-box center-div">
            <a style="cursor:pointer" [routerLink]="documentAddRouter" [queryParams]="{ page: 'add' }" routerLinkActive="router-link-active">
                <span class="submit-text" style="display: inline-flex;align-items: center;">Submissão de objetos</span>
                <div class="dot-add">
                    <mat-icon class="dot-icon-add"
                        aria-hidden="false" aria-label="add">add</mat-icon>
                </div>
            </a>
        </div>
        <br>
        <br>
        <a [routerLink]="['/search/']  " routerLinkActive="router-link-active"><h3>Ver todos os objetos de aprendizagem</h3></a>
        <div>
            <div id="carouselDiv" class="float-child" style="margin: auto">
                <h3>Submissões recentes:</h3>
                <div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel">
                <ol class="carousel-indicators">
                    <li *ngFor="let doc of documents; let i = index" data-target="#carouselExampleCaptions" data-slide-to="{{i}}" [ngClass]="{'active': i === 0 }"></li>
                </ol>
                <div class="carousel-inner">
                    <div class="carousel-item" *ngFor="let doc of documents; let i = index" [ngClass]="{'active': i === 0 }" >
                        <a [routerLink]="['/documents/' + doc.id]" routerLinkActive="router-link-active">
                            <img src="{{url + '/files/' + doc.id + '/thumbnail'}}" class="d-block w-100" style="height:400px" alt="...">
                            <div class="carousel-caption d-none d-md-block">
                                <h5 style="background-color: gray;color:white">{{doc.title}}</h5>
                                <!-- <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> -->
                            </div>
                        </a> 
                    </div>
                </div>

                <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
                </div>
            </div>
            <div class="float-child">
                <h3>Últimas notícias:</h3>
                <iframe src="https://www.facebook.com/plugins/likebox.php?href=http%3A%2F%2Fwww.facebook.com%2F100078205782748&width=380&colorscheme=light&show_faces=false&border_color&stream=true&header=false&height=435" scrolling="yes" style="border:none; overflow:hidden; width:380px; height:430px; background: white; " allowtransparency="true" frameborder="0"></iframe>
            </div>
        </div>
        <!-- <button mat-raised-button (click)="openNotificationDialog()">Verificar notificações</button> -->
    </div>
</div>
<div style="clear: both"></div>
