<div fxLayout="row" class="snack-container">
  <div style="margin: auto; margin-right: 1em;">
    <mat-icon>{{getIcon}}</mat-icon>
  </div>
  <div style="margin: auto;">
    <span>{{data.message}}</span>
  </div>
  <div style="margin-left: 1em; margin-right: 1em;">
    <button mat-raised-button (click)="callActionSnackBar()">{{data.action}}</button>
  </div>
  <div style="margin: auto;">
    <a [routerLink]="" (click)="closeSnackbar()"><mat-icon>close</mat-icon></a>
  </div>
</div>