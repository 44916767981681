import { Component, OnInit, Input, Inject, ViewChild, SimpleChanges, ElementRef } from '@angular/core';
import { OBAA, OBAACreator } from '../metadata';
import { emptyMockOBAA, emptyMockOBAACreator } from '../mock-data';
import { RestService, endpoint } from '../rest.service';
import { FileUploader, FileSelectDirective } from 'ng2-file-upload';
import { ActivatedRoute, Router } from "@angular/router"
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatRadioChange } from '@angular/material/radio';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { MatStepper } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomSnackbarComponent } from '../custom-snackbar/custom-snackbar.component';
import { DOCUMENT } from '@angular/common';
import { keywords_suggestions, curriculumAreas_predefined, age_predefined, knowledgeArea_predefined, 
  target_predefined, keywords_predefined, resources_predefined} from './new-document-fast-datasource'

export interface DialogData {
  documentsTiny: string;
}

@Component({
  selector: 'app-new-document-fast',
  templateUrl: './new-document-fast.component.html',
  styleUrls: ['./new-document-fast.component.css']
})

export class NewDocumentFastComponent implements OnInit {
  @ViewChild('stepper') private stepper: MatStepper; 
  public async_titles;
  public imagePath;
  imgURL: any;
  searchOptions: any;
  finalSearch: string;
  finalparam: string;
  finished:boolean;
  depth:number;
  path:number[];
  interactionNumber:number;
  typicalLearningTime:number;
  moreThanThreeHours:boolean;
  doNotApply:boolean;
  fortyFiveMin:boolean;
  NinetyMin:boolean;
  hours:number;
  minutes:number;
  seconds:number;
  totalMinutes:number;
  otherResource:"";
  target:any;
  age:any;
  resources:any;
  resources_predefined:any;
  curriculumAreas:any;
  curriculumAreas_predefined:any;
  knowledgeArea:any;
  contribute:{
    role: "",
    entities: String[],
  }[];
  relation:{
    kind: "",
    resource: {identifier: {catalog: string, entry: any}[]}
  }[];
  keywords: any;
  keywords_suggestions: any;
  keywords_predefined: any;
  simple: any;
  edit: string;
  currentPage: number;
  progressBarValue: number;
  numPages: number;
  documentsTiny: any;
  name: string;
  preName: string;
  preDescription: string;
  fileId: any;
  fileThumb: string;
  existRelation: boolean;
  preFillValue: string;
  reviewerMainRole: string;
  reviewerText: string;
  thumbIdFile: number;

  OBAA: OBAACreator;
  public uploader: FileUploader = new FileUploader({url: endpoint + "/files/uploadFile", authToken: localStorage.getItem('token'), itemAlias: "file"});
  public uploader2: FileUploader = new FileUploader({url: endpoint + "/files/uploadFile", authToken: localStorage.getItem('token'), itemAlias: 'file'});
  
  controlKeyword = new FormControl();
  controlType = new FormControl();
  controlCurriculumArea = new FormControl();
  
  filteredKeywords: Observable<string[]>;
  panelOpenState = false;
  keywords_suggestions_from_dbpedia: string[][];
  unsavedCommentText = false;

  constructor(public rest:RestService, private router:Router, 
    private route: ActivatedRoute, private dialog: MatDialog,
    private http:HttpClient, private _snackBarAdvice: MatSnackBar,
    @Inject(DOCUMENT) private domDocument: Document) { 
      this.edit = "";
      this.async_titles = [];
      let paramId = this.route.snapshot.paramMap.get('id');
      this.reviewerMainRole = this.getReviewRole(this.rest.decodePayloadJWT().roles);

      if(paramId != null) {
        this.rest.querySOLR("q=id:" + paramId, 'DocumentTinyDto').subscribe((data: any) => {
          var documents = data.response.docs;
          if(documents[0].status == "REVIEWED") {
            this.router.navigate(['/documents/' + paramId]);
            return;
          } 
          if((documents[0].status == "INCOMPLETE" && documents[0].owner != this.rest.decodePayloadJWT().sub)
              || documents[0].status == "NEEDS_PEDAG_REVIEW" || documents[0].status == "NEEDS_TECH_REVIEW"
              || (documents[0].status == "UNDER_TECH_REVIEW" && (this.reviewerMainRole != "tech_reviewer" || documents[0].reviewer != this.rest.decodePayloadJWT().sub))
              || (documents[0].status == "UNDER_PEDAG_REVIEW" && (this.reviewerMainRole != "pedag_reviewer" || documents[0].reviewer != this.rest.decodePayloadJWT().sub))) {
            this.openSnackBar("Não tem permissão para aceder a este objeto de aprendizagem!", "OK", 3000, "Info");
            this.router.navigate(['/']);
            return;
          }
        });  
        this.rest.getDocumentFromID(parseInt(paramId)).subscribe((data: any) => {
          Object.assign(this.OBAA,data);
          this.thumbIdFile = 0;
          console.log(data.files)
          for(let i = data.files.length-1; i >= 0 ; i--){
            if(data.files[i].name != "thumbnail") {
                this.fileId.push(endpoint  + "/files/" + data.files[i].id); 
            }
            if(data.files[i].name == "thumbnail" && this.thumbIdFile == 0) {
              this.thumbIdFile = data.files[i].id;
            }
          }
          (this.thumbIdFile > 0) ? this.fileThumb = endpoint  + "/files/" + paramId + "/thumbnail" : this.fileThumb = "";
        },
        (error) => {                              
          document.body.style.cursor="initial";
          this.openSnackBar("Erro ao editar, token inválido. Tente fazer o login novamente.", "OK", 3000, "Info");
          //this.router.navigate([router.url]);
        });
        // this.OBAA.id = parseInt(this.route.snapshot.paramMap.get('id'));
        // console.log(this.route.snapshot.paramMap.get('id'))
        this.edit = "/edit";
        
        if(this.route.snapshot.paramMap.get('workflow_step') != null) {
          this.edit += "/?workflow_step=" + this.reviewerMainRole;
        } else {
          this.edit += "/?workflow_step=author";
        }
      } else {
        this.rest.getID().subscribe((data: {}) => {
          //console.log(data)
          Object.assign(this.OBAA,data);
          //console.log(this.OBAA);
        },
        (error) => {                              
          document.body.style.cursor="initial";
          this.openSnackBar("Erro ao criar novo documento, token inválido. Tente fazer o login novamente.", "OK", 3000, "Info");
        });
      }
      this.uploader.onBuildItemForm = (item, form) => {
        form.append("docId", this.OBAA.id);
        form.append("filename", "Thumbnail");
      };

      this.uploader2.onBuildItemForm = (item, form) => {
        form.append("docId", this.OBAA.id);
        form.append("filename", "Material");
      };
    this.keywords_suggestions_from_dbpedia = [];
    this.fileId = [];
    this.fileThumb = "";
    this.existRelation = false;
    let tokenInfo = this.rest.decodePayloadJWT();
    this.simple = {
      name: "",
      language: [],
      keywords: [],
      description: "",
      interaction: "",
      interactionNumber: "",
      author:[{
        name: "",
        institution: "",
        role: ["author"],
      }],
      relationWith:[{
        kind: "haspart",
        entry: "",
      }],
      typicalLearningTime: "",
      licence: "",
      target: [],
      age: [],
      knowledgeArea: [],
      resources: [],
      owner: tokenInfo.sub,
      favorites: ["admin"],
      free: "yes",
      status: "INCOMPLETE",
      id: 0,
      curriculumAreas: [],
      learningObjectives: "",
      linkOfLo: "",
      mainStrategies: "",
      reviewersComments: [{
        comment: "",
        user: "",
        phase: "",
      }],
    };
  }

  ngAfterViewInit() {
    this.stepper.selectedIndex = 0;
    let workflow_step = this.route.snapshot.paramMap.get('workflow_step');
    (workflow_step) ? workflow_step = workflow_step.replace('?workflow_step=','') : workflow_step = "";
    switch(workflow_step) {
      case "pedag_reviewer" :
        setTimeout(() => {           // or do some API calls/ Async events
          this.stepper.next();
          this.stepper.selectedIndex = 1;
          this.stepper.selected.completed = true;
         }, 1);
      case "tech_reviewer" : 
        this.stepper.selected.completed = true;
        setTimeout(() => {           // or do some API calls/ Async events
          this.stepper.next();
        }, 1);
    }
  }

  async getUnsavedCommentText(isUnsavedText) {
    this.unsavedCommentText = isUnsavedText;
  }

  async addComment(newCommentText) {    
    let new_comment = "{phase=" + this.simple.status + ", user=" +
      this.rest.decodePayloadJWT().sub + ", comment=" + newCommentText + "}";

    if(this.simple.reviewersComments.length > 1) {
      if(JSON.stringify(this.simple.reviewersComments[this.simple.reviewersComments.length-1]).includes(this.rest.decodePayloadJWT().sub) &&
      JSON.stringify(this.simple.reviewersComments[this.simple.reviewersComments.length-1]).includes(this.simple.status)) {
        this.simple.reviewersComments[this.simple.reviewersComments.length-1] = new_comment;
      } else {
        this.simple.reviewersComments.push(new_comment);
      }
    } else {
      this.simple.reviewersComments.push(new_comment);
    }
    document.body.style.cursor="wait";
    this.save();
    document.body.style.cursor="initial";
  }

  ngOnInit() {
    this.preFillValue = "";
    this.documentsTiny = [];
    this.currentPage = 1;
    this.numPages = 9;
    this.progressBarValue = 100/this.numPages;
    this.moreThanThreeHours = false;
    this.doNotApply = false;
    this.fortyFiveMin = false;
    this.NinetyMin=false;
    this.typicalLearningTime = 0;
    this.keywords = [];
    this.resources = [];
    this.curriculumAreas = [];
    this.preName = "";
    this.preDescription = "";
    this.reviewerText = "Submissão";
    this.keywords_suggestions = keywords_suggestions;
    this.curriculumAreas_predefined = curriculumAreas_predefined;
    this.age = age_predefined;
    this.knowledgeArea = knowledgeArea_predefined;
    this.target = target_predefined;
    this.keywords_predefined = keywords_predefined;
    this.resources_predefined = resources_predefined;

    this.getDocument(this.route.snapshot.paramMap.get('id'), true, false);
    // console.log(this.simple.status)
    
    this.OBAA = emptyMockOBAACreator;

    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.uploader2.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      //if(response.result == "success") {
        this.openSnackBar("Imagem de rosto carregada com sucesso.", "OK", 3000, "Info");
        this.uploader.clearQueue();
        this.checkQueues();
      //}
    };

    this.uploader2.onCompleteAll = () => {
      this.openSnackBar("Arquivos salvos com sucesso. Obrigado!", "OK", 3000, "Info");
      console.log('Upload ended');
      this.uploader2.clearQueue();
      this.checkQueues();
    };

    /*this.uploader2.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      if(response.result == "success") {
        this.openSnackBar("Arquivo carregado com sucesso.", "OK", 3000, "Info");
      } else if (status != 200){
        this.openSnackBar("Falha ao carregar arquivo. Tente novamente.", "OK", 5000, "Info");
      } 
    };*/

    this.uploader2.onAfterAddingFile = (item) => {
      item.remove();
      if (this.uploader2.queue.filter(f => f._file.name == item._file.name).length == 0) {
          this.uploader2.queue.push(item);
      } else {
        this.openSnackBar("Não é possível enviar arquivos com mesmo nome.", "OK", 5000, "Info");
      }
  };

    this.filteredKeywords = this.controlKeyword.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  
  }

  private _filter(value: string): string[] {
    const filterValue = this._normalizeValue(value);
    return this.keywords_suggestions.filter(sug_key => this._normalizeValue(sug_key).includes(filterValue));
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  getReviewRole(roles) {
    roles = roles.toString().split(',');
    for(let role of roles) {
      switch(role) {
        case "tech_reviewer": return "tech_reviewer";
        case "pedag_reviewer": return "pedag_reviewer";
      }
    }
    return "undefined";
  }

  getDocument(id, withRelations, checkNameAndDescription): void {
    if(id != null) {
      this.agreeWithDeposit(withRelations);
      this.rest.querySOLR("q=id:" + id, 'DocumentTinyDto').subscribe((data: any) => {
        var documents = data.response.docs;

        (documents[0].status != "INCOMPLETE" && !checkNameAndDescription) ? this.reviewerText = "Revisão" : this.reviewerText = "Submissão";

        this.otherResource = "";
        (documents[0].resources) ? this.updateCheckBoxes(documents[0].resources, this.resources_predefined, "resources", 5) : "";
        (documents[0].target) ? this.updateCheckBoxes(documents[0].target, this.target, "target", 2) : "";
        (documents[0].keywords) ? this.updateCheckBoxes(documents[0].keywords, this.keywords_predefined, "keywords", 3) : "";
        (documents[0].age) ? this.updateCheckBoxes(documents[0].age, this.age, "age", 1) : "";
        (documents[0].knowledgeArea) ? this.updateCheckBoxes(documents[0].knowledgeArea, this.knowledgeArea, "knowledgeArea", 1) : "";
        (documents[0].curriculumAreas) ? this.updateCheckBoxes(documents[0].curriculumAreas, this.curriculumAreas_predefined, "curriculumAreas", 1) : "";
        (documents[0].typicalLearningTime) ? this.updateTypicalLearningTime(documents[0].typicalLearningTime) : this.doNotApply = true;
        
        let tokenInfo = this.rest.decodePayloadJWT();
        this.simple = {
          name: documents[0].name,
          language: documents[0].language,
          // keywords: documents[0].keywords,
          description: documents[0].description,
          interaction: documents[0].interaction,
          interactionNumber: documents[0].interactionNumber,
          licence: documents[0].licence,
          owner: (withRelations) ? documents[0].owner : tokenInfo.sub,
          favorites: (withRelations) ? documents[0].favorites : [], 
          free: documents[0].free,
          relationWith: (withRelations && documents[0].relationWith) ? this.updateRelations(documents[0].relationWith) : this.simple.relationWith,
          author: this.updateAuthors(documents[0].author),
          status: (withRelations) ? documents[0].status : "INCOMPLETE",
          reviewer: tokenInfo.sub,
          //curriculumAreas: documents[0].curriculumAreas,
          learningObjectives: documents[0].learningObjectives,
          linkOfLo: (documents[0].linkOfLo) ? documents[0].linkOfLo : "",
          mainStrategies: documents[0].mainStrategies,
          reviewersComments: (documents[0].reviewersComments && withRelations) ? documents[0].reviewersComments : ["{phase=, user=, comment=}"],
        }
        if(checkNameAndDescription) {
          this.preName = documents[0].name;
          this.preDescription = documents[0].description;
        } else {
          this.preName = "";
          this.preDescription = "";
        }
      });
    }
  }

  nextStatusScope(status) {
    switch(status) {
      case "INCOMPLETE": return "NEEDS_TECH_REVIEW";
      case "UNDER_TECH_REVIEW": return "NEEDS_PEDAG_REVIEW";
      case "UNDER_PEDAG_REVIEW": return "REVIEWED";
      default: return "ERROR_STATUS";
    }
  }

  async getDocumentTitle(rel_entry,i,z){
    if (this.async_titles[i][z]) return;
    let response = await this.rest.querySOLR("q=id:" + rel_entry, 'DocumentTinyDto').toPromise();
    // console.log(response.response.docs[0].name);
    this.async_titles[i][z] = response.response.docs[0].name;
  }

  updateRelations(associatedRelations){
    this.existRelation = false;
    let relation_ret = [];
    this.documentsTiny = new Array(associatedRelations.length);
    this.async_titles = new Array(associatedRelations.length);  
    
    if(associatedRelations.length > 1) {
      for(var i = 0; i < associatedRelations.length; i++) {
        let relations = associatedRelations[i];
        
        let rel_parts = relations.split("=");
        let rel_entries = rel_parts[1].substring(0, rel_parts[1].lastIndexOf(","));
        let rel_kind = rel_parts[2].toString().replace('}', '')
        
        if(rel_kind.trim() != "" || rel_entries.trim() != ""){
          var rel = {
            kind: rel_kind,
            entry:rel_entries,
          };
          this.simple.relationWith.push(rel);
          relation_ret.push(rel)
          var entries = [];
          
          if(rel_entries.indexOf(",") != -1) {
            entries = rel_entries.split(",");
          } else if (rel_entries.trim() != ""){
            entries.push(rel_entries);
          }

          this.documentsTiny[i] = [];
          this.async_titles[i] = [];
          for (let z = 0; z < entries.length; z++) {
            // console.log(entries[z])
            this.documentsTiny[i].push({id: entries[z], title: ""})
            this.getDocumentTitle(entries[z], i, z);
            this.existRelation = true;
          }       
        } else {
          this.documentsTiny.pop()
        }
      }
    } 
    return relation_ret;
  }

  updateTypicalLearningTime(durationTime){
    if(durationTime == "PT3H15M0S") {
      this.moreThanThreeHours = true;
      this.typicalLearningTime = 0;
      this.doNotApply = false;
    } else {
      this.moreThanThreeHours = false;
      let time = durationTime.split("H");
      let hours = time[0].replace("PT","") * 60;
      let minutes = time[1].replace("M0S","") * 1;  
      this.typicalLearningTime = hours + minutes;
      this.typicalLearningTime === 90 ? this.NinetyMin = true : this.NinetyMin = false;
      this.typicalLearningTime === 45 ? this.fortyFiveMin = true : this.fortyFiveMin = false; 
    }
  }

  updateAuthors(authors_list){
    let contributors = [];
    for(var i = 0; i < authors_list.length; i++) {
        // console.log(authors_list[i])
        var aut_parts = authors_list[i].split("=") 
        var aut_name = aut_parts[1].substring(0, aut_parts[1].lastIndexOf(","));
        var aut_institution = aut_parts[2].substring(0, aut_parts[2].lastIndexOf(","));
        var aut_role = aut_parts[3].toString().replace('[', '').replace(']', '').replace('}', '');

        var aut_roles = [];
        if(aut_role.indexOf(",") == -1) {
          aut_roles.push(aut_role);
        } else {
          aut_roles = aut_role.split(", ");
        }
        if(aut_name.trim() != "" || i == 0) {
          contributors.push({name: aut_name, institution: aut_institution, role:aut_roles}); 
        }        
    }
    return contributors;
  }

  updateCheckBoxes(fields, fieldsList, varName, colummns) {
    
    var checkedFields = 0;
    for(var i = 0; i < fields.length; i++){
      for(var j = 0; j < fieldsList.length; j++){
        for(var k = 1; k <= colummns; k++){
          if(fields[i] == fieldsList[j]["col_"+ k].name){
            fieldsList[j]["col_" + k].isValid = true;
            checkedFields++;
          }
        }
      }
    }
    
    if(checkedFields != fields.length) {
      switch(varName) {
        case "keywords":
          for(var i = checkedFields; i < fields.length; i++){
            this.keywords.push(fields[i]);
          }
          break;
        case "resources":
          for(var i = checkedFields; i < fields.length; i++){
            this.resources.push(fields[i]);
          }
          break;
        case "curriculumAreas":
          for(var i = checkedFields; i < fields.length; i++){
            this.curriculumAreas.push(fields[i]);
          }
          break;
      }      
    }
  }

  async setSuggestions(ret_keywords) {
    // console.log(ret_keywords.length)
    // console.log(ret_keywords)
    for(let i=0; i < ret_keywords.length; i++){
      console.log(ret_keywords[i])
      this.keywords.push(ret_keywords[i]);
    }
  }

  getAnnotatedTextFromDbpediaSpotlight(text : string) {
    if(!text || text.trim() == "") {
      return;
    }
    //let keywords = [];
    //text = "Biodiversidade e Música que reflete aspetos do ciclo de vida do cagarro. Um cagarro juvenil (DJ Cagarro), ao sair do ninho pela primeira vez, enfrenta uma série de perigos, um dos quais as luzes de um camião que o desorientam, necessitando, assim, que alguém o salve.";
    this.http.get('https://api.dbpedia-spotlight.org/pt/annotate?text=' + text + '&confidence=0.2', {responseType: 'json'}).toPromise().then(data => {
      //console.log(`promise result: ${data}`);
      if(data["Resources"]) {
        for(let i=0; i < data["Resources"].length; i++) {
          let new_key = data["Resources"][i]["@surfaceForm"].substr(0,1).toUpperCase() + 
              data["Resources"][i]["@surfaceForm"].substr(1);
          
            let isPredefined = false;
            for(let k=0; k < this.keywords_predefined.length; k++) {
              if(this.keywords_predefined[k].col_1.name == new_key) {
                this.keywords_predefined[k].col_1.isValid = true;
                isPredefined = true;
              }
              if(this.keywords_predefined[k].col_2.name == new_key) {
                this.keywords_predefined[k].col_2.isValid = true;
                isPredefined = true;
              }
              if(this.keywords_predefined[k].col_3.name == new_key) {
                this.keywords_predefined[k].col_3.isValid = true;
                isPredefined = true;
              }
            }
            if(!isPredefined && this.keywords.indexOf(new_key) == -1) {
              this.keywords.push(new_key);
            }
            this.keywords_suggestions_from_dbpedia.push(new_key)  
        }
      }
    });
  }

  removeFile(id, index) {
    if(index >= 0) {
      this.openSnackBar("Tem a certeza de remover este arquivo?", "OK", 3000, "Warn", "" , "", id.split("/")[4], index);
    } else {
      this.openSnackBar("Tem a certeza de remover este arquivo?", "OK", 3000, "Warn", "" , "", id, index);
    }
  }

  doRemoveFile(id, index) {
    if(this.rest.deleteFileById(id)) {
      this.openSnackBar("Arquivo removido com sucesso.", "OK", 3000, "Info");
      if(index >= 0) {
        this.fileId.splice(index, 1);
      } else {
        this.thumbIdFile = 0;
        this.fileThumb = "";
      }
    } else {
      this.openSnackBar("Erro ao remover arquivo, contacte-nos.", "OK", 3000, "Info");
    }
  }

  getSuggestions() {  
    this.getAnnotatedTextFromDbpediaSpotlight(this.simple.title);
    this.getAnnotatedTextFromDbpediaSpotlight(this.simple.description);
  }

  removeSuggestions() {
    for(let i = 0; i < this.keywords_suggestions_from_dbpedia.length; i++) {
      let index = this.keywords.indexOf(this.keywords_suggestions_from_dbpedia[i]);
      if (index > -1) {
        this.keywords.splice(this.keywords.indexOf(this.keywords_suggestions_from_dbpedia[i]),1);
      }
      for(let k=0; k < this.keywords_predefined.length; k++) {
        if(this.keywords_predefined[k].col_1.name == this.keywords_suggestions_from_dbpedia[i]) {
          this.keywords_predefined[k].col_1.isValid = false;
        }
        if(this.keywords_predefined[k].col_2.name == this.keywords_suggestions_from_dbpedia[i]) {
          this.keywords_predefined[k].col_2.isValid = false;
        }
        if(this.keywords_predefined[k].col_3.name == this.keywords_suggestions_from_dbpedia[i]) {
          this.keywords_predefined[k].col_3.isValid = false;
        }
      }     
    }
  }

  openRelationDialog(index: number): void {
    let dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '800px',  height: '600px',
      data: {documentsTiny: this.documentsTiny}
    });
    
    dialogRef.afterClosed().subscribe(result => {
      var filteredResult = [];
      if(result){
        for(var i = 0; i < result.length; i++){
          if(result[i].selected) {
            filteredResult.push({id: result[i].id, title: result[i].title});
          }
        }
        this.documentsTiny[index] = filteredResult;
        // console.log(this.documentsTiny);
      } 
    });
  }
  
  changePreFillValue(event) {
    if(event.isUserInput) {
      // console.log(event.source.value, event.source.selected);
      if(event.source.value == "0") {
        this.openSnackBar("Deseja apagar todos os dados pré-preenchidos?", "OK", 3000, "Warn");
      } else {
        this.getDocument(event.source.value, false, true);
        for(var i = 2; i <= this.numPages; i++){      
          document.getElementById("page"+i).style.fontWeight = "normal";
          document.getElementById("page"+i).style.color = "#000000";
        }
      }
    }
  }

  clearCheckBoxes(fieldsList, varName, colummns) {
    for(var i = 0; i < fieldsList.length; i++){
      for(var j = 0; j < fieldsList.length; j++){
        for(var k = 1; k <= colummns; k++){
            fieldsList[j]["col_" + k].isValid = false;
        }
      }
    }
  }

  clearFormValues() {
    this.otherResource = "";
    this.keywords = [];
    this.resources = [];
    this.curriculumAreas = [];
    this.clearCheckBoxes(this.resources_predefined, "resources", 5);
    this.clearCheckBoxes(this.target, "target", 2);
    this.clearCheckBoxes(this.keywords_predefined, "keywords", 3);
    this.clearCheckBoxes(this.curriculumAreas_predefined, "curriculumAreas", 1);
    this.clearCheckBoxes(this.age, "age", 1);
    this.clearCheckBoxes(this.knowledgeArea, "knowledgeArea", 1);
    this.typicalLearningTime = 0;
    this.moreThanThreeHours = false;
    this.doNotApply = false;
    this.fortyFiveMin = false;
    this.NinetyMin = false;

    let tokenInfo = this.rest.decodePayloadJWT();
    this.simple = {
      name: "",
      language: "",
      // keywords: documents[0].keywords,
      description: "",
      interaction: "",
      interactionNumber: "",
      licence: "",
      owner: tokenInfo.sub,
      favorites:["admin"],
      free:"yes",
      relationWith: this.simple.relationWith,
      //authors: documents[0].author,
      author: this.updateAuthors([]),
      status: "INCOMPLETE",
      reviewer: tokenInfo.sub
    }
    this.addAuthor("","",["author"]);
  }

  radioInteractivityChange(event: MatRadioChange) {
    this.simple.interactionNumber = +event.value;
  }

  showRelationDiv(event: MatRadioChange) {
    if(this.simple.relationWith.length == 0) {
      this.addRelation("");
    }
    if(+event.value) {
      this.existRelation = true;
    } else {
      this.existRelation = false;
      if(this.preFillValue != "") {
        this.openSnackBar("Deseja apagar todos os dados pré-preenchidos?", "OK", 3000, "Warn");
      }
    }
  }

  setTypicalLearningTime(checkboxName, event: MatCheckboxChange) {
    switch(checkboxName) {
      case "fortyFiveMin":
        this.fortyFiveMin = event.checked;
        this.typicalLearningTime = 45;  
        this.moreThanThreeHours = false;
        this.doNotApply = false;
        this.NinetyMin = false;
        break;
      case "NinetyMin":
        this.NinetyMin = event.checked;
        this.typicalLearningTime = 90; 
        this.fortyFiveMin = false; 
        this.moreThanThreeHours = false;
        this.doNotApply = false;
        break;      
      case "moreThanThreeHours": 
        this.typicalLearningTime = 0;  
        this.moreThanThreeHours = event.checked;
        this.doNotApply = false;
        this.fortyFiveMin = false; 
        this.NinetyMin = false;
        break;
      case "doNotApply": 
        this.typicalLearningTime = 0;
        this.moreThanThreeHours = false;
        this.doNotApply = event.checked;
        this.fortyFiveMin = false; 
        this.NinetyMin = false;
        break;
      default:
        checkboxName === 90 ? this.NinetyMin = true : this.NinetyMin = false;
        checkboxName === 45 ? this.fortyFiveMin = true : this.fortyFiveMin = false; 
        this.moreThanThreeHours = false;
        this.doNotApply = false;
        break;      
    }
  }

  agreeWithDeposit(showPage1 : boolean){
    document.getElementById("agreeButton").style.display = "none";
    document.getElementById("step0").style.display = "none";
    if(showPage1) {
      document.getElementById("step1").style.display = "block";
    }
    document.getElementById("saveAndPaginationSection").style.display = "block";
  }

  async save() { 
    let removeExtraAuthor = false;
    let removeExtraRelation = false;
    let removeExtraComment = false;

    this.updateSimple();

    if(this.simple.author.length == 1) {
      this.addAuthor("","",["author"]);
      removeExtraAuthor = true;
    }
    
    if(this.simple.relationWith.length == 1) {
      this.addRelation("");
      removeExtraRelation = true;
    }
    
    if(this.simple.reviewersComments.length == 1) {
      this.addComment("");
      removeExtraComment = true;
    }

    this.simple.id = this.OBAA.id;  
  
    // console.log(this.simple)
    await this.rest.addDocumentSOLR(JSON.stringify([this.simple])).subscribe(
      result => {
        this.openSnackBar("Objeto salvo com sucesso!", "OK", 3000, "Info");
      },
      error => {
        this.openSnackBar("Erro ao salvar objeto!", "OK", 3000, "Info");
        console.log(error);
      }
    ); 
    if(removeExtraAuthor) {
      this.simple.author.pop();
    }
    if(removeExtraRelation) {
      this.simple.relationWith.pop();
    }
    if(removeExtraComment) {
      this.simple.reviewersComments.pop();
    }

    let queryPendencies = "q=(owner:\"" + this.simple.owner + "\" AND status:INCOMPLETE)";
    if(this.reviewerMainRole != "author") {
      let role = this.reviewerMainRole.substring(0, this.reviewerMainRole.length - 2).toUpperCase();
      queryPendencies += " OR (status:NEEDS_" + role;
      queryPendencies += ") OR (reviewer:\"" + this.simple.owner + "\"";
      queryPendencies += " AND status:UNDER_" + role + ")&rows=0";
    }

    await this.rest.querySOLR(queryPendencies, 'DocumentTinyDto').subscribe((data: any) => {
      if(data.response.numFound > 0) {
        this.rest.pendencies.emit(data.response.numFound);
        //console.log(data.response.numFound)
      } else {
        this.rest.pendencies.emit(0);
      }
      localStorage.setItem('lastPendencies', data.response.numFound);
    });
  }

  finish(role: string, newStatus: string){
    if(this.unsavedCommentText) {
      this.openSnackBar("Existe um comentário não salvo. Por favor, remova ou aceda o botão para gravá-lo.", "OK", 3000, "Info");
      return;
    }
    if(role == '' && newStatus == '') {
      this.openSnackBar("Tem a certeza que quer encerrar e enviá-la para a próxima etapa?", "OK", 3000, "Warn"); 
    }

    if(role == '' && newStatus != '') {
      this.openSnackBar("Tem a certeza que quer retornar submissão para a etapa anterior?", "OK", 3000, "Warn", role, newStatus); 
    }

    if(role != '' && newStatus == '') { 
      this.openSnackBar("Tem a certeza que quer aprovar este objeto?", "OK", 3000, "Warn", role);
    }
  }

  async doFinish(role: string, newStatus: string) {
    document.body.style.cursor="wait";

    for (var propt in this.simple){
      if (Object.prototype.hasOwnProperty.call(this.simple, propt)) {
          if(this.simple[propt] == "" && !(propt == "id" || propt == "relationWith"
          || propt == "typicalLearningTime" || propt == "relation"
          || propt == "favorites" || propt == "linkOfLo" || propt == "reviewersComments")){
              document.body.style.cursor="initial";
              this.openSnackBar("Preencha todos os campos necessários antes do envio.", "OK", 3000, "Info");
              return;
          }

          // Aditional info check
          if(this.simple[propt] == "" && this.fileId.length == 0 && 
          this.uploader2.queue.length == 0 && propt == "linkOfLo") {
            this.openSnackBar("Preencha todos os campos necessários antes do envio.", "OK", 3000, "Info");
            document.body.style.cursor="initial"; 
            return;
          }
      }
    }

    if(this.preName.toString().trim() == this.simple.name.toString().trim() ||
      this.preDescription.toString().trim() == this.simple.description.toString().trim()) {
      document.body.style.cursor="initial";
      this.openSnackBar("Título e descrição do objeto não podem ser iguais ao do objeto relacionado.", "OK", 3000, "Info");
      document.body.style.cursor="initial";
      return;
    } 

    for(var i = 0; i < this.simple["author"].length; i++) {
      if(this.simple["author"][i].name.trim() == "" || 
          //this.simple["author"][i].institution.trim() == "" ||
          this.simple["author"][i].role.length == 0) {
            document.body.style.cursor="initial";
            this.openSnackBar("Preencha todos os campos necessários antes do envio.", "OK", 3000, "Info");
            return;
      }
    }

    if(this.existRelation) {
      for(var i = 0; i < this.simple["relationWith"].length; i++) {
        if(this.simple["relationWith"][i].entry.trim() == "") {
          document.body.style.cursor="initial";
          this.openSnackBar("Preencha todos os campos necessários antes do envio.", "OK", 3000, "Info");
          return;
        }
      }
    } else {
      this.documentsTiny = [];
      this.simple.relationWith = [];
      this.addRelation("");
    }

    if(this.simple.reviewersComments.length >= 1) {
      if(!JSON.stringify(this.simple.reviewersComments[this.simple.reviewersComments.length-1]).includes(this.rest.decodePayloadJWT().sub) ||
      !JSON.stringify(this.simple.reviewersComments[this.simple.reviewersComments.length-1]).includes(this.simple.status)) {
        this.addComment("");
      }
    }

    if(this.route.snapshot.paramMap.get('id') != null) {
      this.OBAA.id = parseInt(this.route.snapshot.paramMap.get('id'));
      this.edit = "/edit";
      if(this.route.snapshot.paramMap.get('workflow_step') != null) {
        this.edit += "/?workflow_step=" + this.reviewerMainRole + "&status=" + newStatus;
        (role) ? this.edit += "&approved=true" : this.edit += "&approved=false";
      } else {
        this.edit += "/?workflow_step=author&approved=false&status=";
      }
    }

    this.OBAA.metadata.general.titles[0] = this.simple.name.toString();
    this.OBAA.metadata.general.languages[0] = this.simple.language.toString();
    this.OBAA.metadata.general.descriptions[0] = this.simple.description.toString();
    this.OBAA.metadata.general.keywords[0] = this.simple.keywords.toString();

    // this.updateSimple();
 
    this.addAuthor("","",["author"]); 
    this.addRelation("");

    // Tech reviewer can approve LOs when they are not brand new
    if(role == 'tech_reviewer') {
      this.simple.status = "REVIEWED";
    } else if (newStatus != '') {
      this.simple.status = newStatus;
    } else {
      this.simple.status = this.nextStatusScope(this.simple.status);
    }
    
    this.save();
    document.body.style.cursor="wait";
    this.openSnackBar("Aguarde o carregamento dos arquivos...", "OK", 3000, "Info");
    document.getElementById("incomplete").style.display="none";
    this.resetPagesBoldnessAndColor();

    for(var i = 0; i < this.contribute.length; i++){
      if(this.contribute[i].entities[0] != "") {
        this.OBAA.metadata.lifeCycle.contribute[i] = this.contribute[i];
      }
    }

    this.OBAA.metadata.educational.learningResourceTypes = this.simple.resources;
    this.OBAA.metadata.educational.interactivityLevel = this.simple.interaction;
    this.OBAA.metadata.educational.intendedEndUserRoles = this.simple.target;
    this.OBAA.metadata.educational.contexts = this.simple.age;
    if(this.typicalLearningTime != 0){
      this.OBAA.metadata.educational.typicalLearningTime = this.simple.typicalLearningTime;
    }
    this.OBAA.metadata.educational.knowledgeAreas = this.simple.knowledgeArea;

    this.OBAA.metadata.rights.cost = "false";
    this.OBAA.metadata.rights.copyright = "true";
    this.OBAA.metadata.rights.description = this.simple.licence;
    
    for(var i = 0; i < this.relation.length; i++){
      if(this.relation[i].kind != "") {
        this.relation[i].resource.identifier[0].entry =  "http://re-mar.uac.pt/#/documents/" + 
        this.relation[i].resource.identifier[0].entry;
        this.OBAA.metadata.relations.push(this.relation[i]);
      }
    }
    
    this.OBAA.isVersion = "1";
    
    // this.simple.id = this.OBAA.id;  
    
    // console.log( "BEFORE");
    // console.log(this.OBAA);
    // console.log(this.simple);

    this.rest.addDocument(JSON.stringify(this.OBAA), this.OBAA.id, this.edit).subscribe((data: {}) => {
      if(role) {
        this.openSnackBar("Objeto aprovado com sucesso! Já disponível no Re-Mar!", "OK", 3000, "Info");
      } else {
        this.openSnackBar("Objeto adicionado com sucesso! Enviado para próxima revisão!", "OK", 3000, "Info");
      }
      document.body.style.cursor="wait";
      if (this.uploader.queue.length > 0) {
        this.uploader.uploadAll();
      }    
      if(this.uploader2.queue.length > 0) {
        this.openSnackBar("Espere o envio dos arquivos para o correto carregamento...", "OK", 5000, "Info");
        this.uploader2.uploadAll();
      }  

      this.checkQueues();
    });    
  }

  checkQueues() {
    // //
    console.log(this.uploader.queue.length)
    console.log(this.uploader2.queue.length)
    if(this.uploader.queue.length == 0 && this.uploader2.queue.length == 0) {
      document.body.style.cursor="initial";
      this.router.navigate(['/']);
    }
  }

  //Pagination starts here
  page(page: number){
    this.resetPagesBoldnessAndColor();
    if(page == this.numPages) console.log(this.simple)
    var currentPageString = "page" + this.currentPage;
    var currentPageStep = "step" + this.currentPage;
    //document.getElementById(currentPageString).style.fontWeight = "normal";
    document.getElementById(currentPageStep).style.display = "none";
    //document.getElementById(currentPageString).style.color = "#000000";

    var newPageString = "page" + page;
    var newStepString = "step" + page;
    document.getElementById(newPageString).style.fontWeight = "bold";
    document.getElementById(newStepString).style.display = "block";
    document.getElementById(newPageString).style.color = "#81D6FF";
    
    this.currentPage = page;
    this.progressBarValue = (100/this.numPages) * page;

    if(page == this.numPages)
      this.updateSimple();
    
    window.scrollTo(0,0);

  }

  resetPagesBoldnessAndColor(){
    for (var i = 1; i <= this.numPages; i++){
      var pageString = "page" + i;
      document.getElementById(pageString).style.fontWeight = "normal";
      document.getElementById(pageString).style.color = "#000000";
    }
  }

  nextPage(){
    // console.log('page: ' + this.currentPage);
    if (this.currentPage != this.numPages){
      this.page(this.currentPage + 1);
    }
  }

  prevPage(){
    if (this.currentPage != 1){
      this.page(this.currentPage - 1);
    }
  }

  formatLabel(value:number | null){
    switch(value){
      case 1:
        return "Nula";
      case 2:
        return "Baixa";
      case 3:
        return "Média";
      case 4:
        return "Alta";
      }
  }

  updateSimple(){
    this.simple.target = [];
    this.simple.resources = [];
    this.simple.age = [];
    this.simple.knowledgeArea = []; 
    this.simple.keywords = [];
    this.simple.curriculumAreas = [];
    this.contribute = [];   
    this.relation = [];
    
    for(var i = 0; i < this.keywords_predefined.length; i++){
      for(var recIndex = 1; recIndex <= this.keywords_predefined.length; recIndex++){
        if(this.keywords_predefined[i]["col_" + recIndex].isValid){
          this.simple.keywords.push(this.keywords_predefined[i]["col_" + recIndex].name);
        }
      }
    }

    for(var i = 0; i < this.keywords.length; i++){      
        this.simple.keywords.push(this.keywords[i]);
    }

    for(var i = 0; i < this.target.length; i++){
      for(var recIndex = 1; recIndex <= this.target.length; recIndex++){
        if(this.target[i]["col_" + recIndex].isValid){
          this.simple.target.push(this.target[i]["col_" + recIndex].name);
        }
      }
    }

    for(var i = 0; i < this.age.length; i++){
      if(this.age[i]["col_1"].isValid){
        this.simple.age.push(this.age[i]["col_1"].name);
      }
    }

    for(var i = 0; i < this.knowledgeArea.length; i++){
      if(this.knowledgeArea[i]["col_1"].isValid){
        this.simple.knowledgeArea.push(this.knowledgeArea[i]["col_1"].name);
      }
    }

    for(var i = 0; i < this.curriculumAreas_predefined.length; i++){
      if(this.curriculumAreas_predefined[i]["col_1"].isValid){
        this.simple.curriculumAreas.push(this.curriculumAreas_predefined[i]["col_1"].name);
      }
    }

    for(var i = 0; i < this.curriculumAreas.length; i++){      
        this.simple.curriculumAreas.push(this.curriculumAreas[i]);
    }

    console.log(this.resources_predefined)
    for(var i = 0; i < this.resources_predefined.length; i++){
      for(var recIndex = 1; recIndex <= this.resources_predefined.length; recIndex++){
        if(this.resources_predefined[i]["col_" + recIndex].isValid){
          this.simple.resources.push(this.resources_predefined[i]["col_" + recIndex].name);
        }
      }
    }

    for(var i = 0; i < this.resources.length; i++){      
      this.simple.resources.push(this.resources[i]);
    }

    for(var i = 0; i < this.simple.author.length; i++){
      for(var recIndex = 0; recIndex < this.simple.author[i].role.length; recIndex++){
        this.contribute.push({role:this.simple.author[i].role[recIndex], 
          entities:[this.simple.author[i].name , this.simple.author[i].institution]}); 
      }
    }
    
    for(var i = 0; i < this.simple.relationWith.length; i++){
      
      if (this.documentsTiny[i]){
        // console.log(this.documentsTiny[i])
        var entries = [];
        for(var j = 0; j < this.documentsTiny[i].length; j++) {
          entries.push(this.documentsTiny[i][j].id)
          
          // console.log(this.documentsTiny[i][j].id)
        }
        //console.log(entries)
        // this.documentsTiny[i].toString().split(',');
        for(var k = 0; k < entries.length; k++){
          this.relation.push({kind:this.simple.relationWith[i].kind, 
            resource:{identifier:[{catalog: "URI" , 
            entry: entries[k]}]}});
          
        }
        this.simple.relationWith[i].entry = entries.toString();
      //TODO: group identifiers to the same kind
      }  else {
        this.relation.push({kind:this.simple.relationWith[i].kind, 
          resource:{identifier:[{catalog: "URI" , 
          entry: ""}]}});
      }
    }
    
    this.simple.interaction = this.formatLabel(this.simple.interactionNumber);

    // TypicalLearningTime must follow the rule PThHmMsS
    if(this.typicalLearningTime != 0){
      this.totalMinutes = this.typicalLearningTime;
      this.hours = ((this.totalMinutes - (this.totalMinutes % 60)) / 60);
      this.minutes = this.totalMinutes % 60;
      this.seconds = 0;

      this.simple.typicalLearningTime = "PT" + this.hours + "H" + this.minutes + "M" 
        + this.seconds + "S";
    } else {
      this.simple.typicalLearningTime = "";
    }

    if(this.moreThanThreeHours){
      this.simple.typicalLearningTime = "PT3H15M0S";
    }

    if(this.otherResource){
      this.simple.resources.pop();
      this.simple.resources.push(this.otherResource);
    }

    this.check();
  }

  addCurriculumArea() {
    if(this.curriculumAreas.indexOf(this.controlCurriculumArea.value) == -1) {
      this.curriculumAreas.push(this.controlCurriculumArea.value);
    }
  }

  removeCurriculumArea(i: number) {
    this.curriculumAreas.splice(i,1);
  }

  addType() {
    if(this.resources.indexOf(this.controlType.value) == -1) {
      this.resources.push(this.controlType.value);
    }
  }

  removeType(i: number){
    this.resources.splice(i,1);
  }

  addKeyword(){
    if(this.keywords.indexOf(this.controlKeyword.value) == -1) {
      this.keywords.push(this.controlKeyword.value);
    }
  }

  removeKeyord(i: number){
    this.keywords.splice(i,1);
  }

  removeDocTiny(i: number, k: number){
    this.documentsTiny[i].splice(k,1);
  }

  addAuthor(newName:string, newInsitution:string, newRole:string[]){
    var aut = {
      name: newName,
      institution:newInsitution,
      role:newRole,
    };
    this.simple.author.push(aut);
  }

  removeAuthor(i: number){
    this.simple.author.splice(i,1);
  }

  addRelation(newKind: string){
    var rel = {
      kind: newKind,
      entry:"",
    };
    this.simple.relationWith.push(rel);
  }

  removeRelation(i: number){
    this.documentsTiny.splice(i,1);
    this.simple.relationWith.splice(i,1);
  }

  check() {
    var complete = true;
    var fieldsMissing = [];
    this.resetPagesBoldnessAndColor();
    document.getElementById("incomplete").style.display="block";
    if(document.getElementById("finishbutton")) {
      document.getElementById("finishbutton").style.display="none";
    }
    if(document.getElementById("approveButton")) {
      document.getElementById("approveButton").style.display="none";
    }
    // TODO: analisar melhor como controlar campos do template!
    for (var propt in this.simple){
      if (Object.prototype.hasOwnProperty.call(this.simple, propt)) {
          if(this.simple[propt] == "" && !(propt == "id" || propt == "relationWith"
            || propt == "typicalLearningTime" || propt == "relation"
            || propt == "favorites" || propt == "linkOfLo" || propt == "reviewersComments")){
            complete = false; 
            fieldsMissing.push(propt);
          }
          // Aditional info check (when reviewer upolaader.queue is empty but has fileId)
          if(this.simple[propt] == "" && this.fileId.length == 0 &&  
          this.uploader2.queue.length == 0 && propt == "linkOfLo") {
            complete = false;
            fieldsMissing.push(propt);
          }
      }
    }

    for(var i = 0; i < this.simple["author"].length; i++) {
      if(this.simple["author"][i].name.trim() == "" || 
          //this.simple["author"][i].institution.trim() == "" ||
          this.simple["author"][i].role.length == 0) {
        complete = false;
        fieldsMissing.push("author");
      } 
    }

    if(this.existRelation) {
      for(var i = 0; i < this.simple["relationWith"].length; i++) {
        // console.log(this.simple["relationWith"][i])
        if(this.simple["relationWith"][i].entry.trim() == "") {
          complete = false;
        }
      }
    }

    if(complete) {
      document.getElementById("incomplete").style.display="none";
      document.getElementById("sameLO").style.display="none";
      if(this.existRelation) {
        if(this.preName.toString().trim() == this.simple.name.toString().trim() ||
         this.preDescription.toString().trim() == this.simple.description.toString().trim()) {
            document.getElementById("sameLO").style.display="block";
            document.getElementById("page4").style.fontWeight = "bold";
            document.getElementById("page4").style.color = "#ff0000";
        }
      }
    }

    for(var i = 0; i < fieldsMissing.length; i++){      
      let page = this.fieldPage(fieldsMissing[i]);
      document.getElementById("page"+page).style.fontWeight = "bold";
      document.getElementById("page"+page).style.color = "#ff0000";
    }

    if(this.uploader2.queue.length == 0 && this.simple.linkOfLo == "" &&
      this.simple.status == "INCOMPLETE" && this.fileId.length == 0){
        document.getElementById("uploadEmpty").style.display="block";
        document.getElementById("page1").style.fontWeight = "bold";
        document.getElementById("page1").style.color = "#ff0000";
        return;
    } 

    document.getElementById("uploadEmpty").style.display="none";
    if(complete && document.getElementById("finishbutton")) {
      document.getElementById("finishbutton").style.display="";
    }
    if(complete && document.getElementById("approveButton")) {
      document.getElementById("approveButton").style.display="";
    }
  }

  displayedTableColumms(size: number) {
    let colummns = [];

    for(var i=1; i <= size; i++) {
      colummns.push("col_" + i);
    }

    return colummns;
  }

  fieldPage(field) {
    switch(field) {
      case "curriculumAreas":
      case "learningObjectives":
      case "linkOfLo":
      case "mainStrategies": return 3;
      case "name":
      case "language":
      case "keywords":
      case "description":
      case "resources": return 4;
      case "interactionNumber": return 5;
      case "target":
      case "age": 
      case "knowledgeArea": return 6;
      case "licence": return 7;
      case "author": return 8;
    }
  }
  
  showText(id: string) {
    document.getElementById(id).style.display = "block";
  }

  hideText(id: string) {
    switch(id) {
      case "uploadFileText":
        if(this.uploader2.queue.length > 0) {
          return;
        }
      case "uploadPhotoText":
        document.getElementById(id).style.display = "none";
    }
  }

  openSnackBar(message: string, action: string, duration: number, snackType: string, 
    role?: string, newStatus?: string, id?: string, index?: string) {
    let snackBarRef = this._snackBarAdvice.openFromComponent(CustomSnackbarComponent, {
      duration: duration,
      horizontalPosition: 'center',
      //verticalPosition: 'top',
      data: { message: message, action: action, snackType: snackType, role: role, newStatus: newStatus }
    });
    snackBarRef.onAction().subscribe(() => {
      switch(message) {
        case "Deseja apagar todos os dados pré-preenchidos?": 
            this.clearFormValues();
            break;
        case "Tem a certeza que quer retornar submissão para a etapa anterior?":
            this.doFinish('', newStatus);
            break;
        case "Tem a certeza que quer encerrar e enviá-la para a próxima etapa?":
        case "Tem a certeza que quer aprovar este objeto?":
            this.doFinish(role, '');
            break;
        case "Tem a certeza de remover este arquivo?":
            this.doRemoveFile(id, index);
            break;
      }
    });
  }
}

@Component({
  selector: 'dialog-search-documents',
  templateUrl: 'dialog-search-documents.html',
})
export class DialogOverviewExampleDialog {
  documentsTiny = [];
  searchText = "";
  totalItems = 0;
  
  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    public rest: RestService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onCancelClick(): void {
    this.dialogRef.close();
  }

  pageChangedOnDialog(event) {
    let pageIndex = event.pageIndex;
    let pageSize = event.pageSize;
    // let previousIndex = event.previousPageIndex;
    let previousSize = pageSize * pageIndex;

    this.search(previousSize, pageSize);
  }

  ngOnInit() {
    this.search(0,10);
  }
  
  search(start, rows){
    this.documentsTiny = [];
    var finalString = "q=*:*"
    if(this.searchText != ""){
      finalString = "q=name:*"+ this.searchText.replace(" ","*") + "*";
    }
    finalString += "&fq=status:REVIEWED";
    finalString += "&rows=" + rows + "&start=" + start;
    this.rest.querySOLR(finalString, 'DocumentTinyDto').subscribe((data: any) => {
      var rec = data.response.docs;
      this.totalItems = data.response.numFound;
      // console.log(rec);
      for (var x in rec){
        // console.log(x);
        this.documentsTiny.push({id:rec[x].id, title:rec[x].name, selected:false});
      }
      // console.log(this.documentsTiny);
    });

  }

}
