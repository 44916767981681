<br>
<br>
<div class="grey-division">
    <div class="grey-division-text"><strong>Redefinir palavra-passe</strong></div>
</div>
<br>
<br>
<div class="container-fluid form">
    <div class="row" *ngIf="CurrentState=='Wait'">
        <div class="col-md-12  close-form">
            <h2> Aguarde...</h2>
        </div>
    </div>
    <div class="row"
        *ngIf="CurrentState=='NotVerified'">
        <div class="col-md-12" style="text-align: center;">
            <h2> URL é inválida. Peça a redefinição de palavra-passe novamente.</h2>
        </div>
    </div>
    <div class="row" *ngIf="CurrentState=='Verified'">
        <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
            <div class="card card-signin my-5">
                <div class="card-body">
                    <h5 class="card-title text-center">Defina nova palavra-passe</h5>
                    <div>
                        <div id="errorMsg" *ngIf="errorMessage">
                            <span>{{errorMessage}}</span>
                        </div>
                        <div id="successMsg" *ngIf="successMessage">
                            <span>{{successMessage}}</span>
                        </div>
                        <form action="" [formGroup]="ResponseResetForm" (ngSubmit)="resetPassword(ResponseResetForm)">
                            <div class="form-group">
                                <input _ngcontent-c0="" class="form-control form-control-lg" placeholder="Palavra-passe"
                                    type="password" id="password" formControlName="password" />
                                <span *ngIf="!ResponseResetForm.get('password').valid && !IsResetFormValid"
                                    class="help-block">Palavra-passe deve conter pelo menos 4 caracteres.</span>
                            </div>
                            <div class="form-group">
                                <input _ngcontent-c0="" class="form-control form-control-lg"
                                    placeholder="Confirme a palavra-passe" type="password" id="cpassword"
                                    formControlName="confirmPassword" />
                                <span *ngIf="!ResponseResetForm.get('confirmPassword').valid && !IsResetFormValid"
                                    class="help-block">. </span>
                                <span
                                    *ngIf="ResponseResetForm.get('confirmPassword').valid && (ResponseResetForm.get('confirmPassword').value != ResponseResetForm.get('password').value) && !IsResetFormValid"
                                    class="help-block">Confirmação de palavra-passe não é igual a palavra-passe.</span>
                            </div>
                            <div class="form-group">
                                <div>

                                    <button type="submit" class="  btn btn-primary">Redefinir palavra-passe</button>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
