import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout/layout.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';

import { AppRoutingModule } from '../app-routing/app-routing.module';
import { RouterModule } from '@angular/router';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  MatIconModule, MatButtonModule, MatSidenavModule, MatToolbarModule, MatBadgeModule,
  MatListModule, MatMenuModule,
} from '@angular/material';

@NgModule({
  imports: [
    CommonModule, MatMenuModule,AppRoutingModule, RouterModule, MatIconModule, MatSidenavModule,
    MatToolbarModule, MatListModule, MatButtonModule, BrowserAnimationsModule, FlexLayoutModule,
    MatBadgeModule, 
  ],
  declarations: [LayoutComponent, HeaderComponent, FooterComponent],
  exports: [LayoutComponent]
})
export class UiModule { }