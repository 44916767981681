import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-custom-snackbar',
  templateUrl: './custom-snackbar.component.html',
  styleUrls: ['./custom-snackbar.component.css']
})
export class CustomSnackbarComponent implements OnInit {

  constructor(private snackBarRef: MatSnackBarRef<CustomSnackbarComponent>, 
    @Inject(MAT_SNACK_BAR_DATA) public data: any) {
    //console.log(data); 
  }
  
  ngOnInit(): void {
  }

  get getIcon() {
    switch (this.data.snackType) {
      case 'Success':
        return 'done';
      case 'Error':
        return 'error';
      case 'Warn':
        return 'warning';
      case 'Info':
        return 'info';
    }
  }

  closeSnackbar() {
    this.snackBarRef.dismiss();
  }

  callActionSnackBar() {
    this.snackBarRef.dismissWithAction();
  }
}
