<div id="tab-content">
    <div id="entities-list" class="ui-widget" style="text-align: left" >
        <h4>Informações do Sistema</h4>
        <p> Versão: 2.2</p>
        <p> Número de documentos: 22</p>
        <p> Domínio: cognitivabrasil.com.br </p>
        <p> Raiz do projeto: /repositorio </p>
        <p> Porta: 80 </p>

    </div>
</div>