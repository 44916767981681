import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { UiModule } from './ui/ui.module';
import { HomeComponent } from './home/home.component';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { LoggedComponent } from './logged/logged.component';
import { LoginService } from './login.service';
import { PanelComponent } from './panel/panel.component';
import { DocumentsComponent } from './documents/documents.component';
import { NewDocumentComponent } from './new-document/new-document.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { 
  MatProgressBarModule, MatPaginatorModule, MatButtonModule, MatCheckboxModule,
  MatTabsModule, MatChipsModule, MatInputModule, MatGridListModule, MatSelectModule,
  MatRadioModule, MatSliderModule, MatCardModule, MatIconModule, MatStepperModule,
  MatDialogModule, MatAutocompleteModule, MatFormFieldModule, MatSnackBarModule,
  MatTableModule, MatTooltipModule, MatExpansionModule
} from '@angular/material';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InfoMetadadosComponent } from './info-metadados/info-metadados.component';
import { AboutComponent } from './about/about.component';
import { RegisterComponent } from './register/register.component';
import { ProfileComponent } from './profile/profile.component';
import { NewDocumentFastComponent, DialogOverviewExampleDialog } from './new-document-fast/new-document-fast.component';
import { HttpClientModule } from '@angular/common/http';

import { FileUploadModule } from 'ng2-file-upload';
import { SearchComponent } from './search/search.component';
import { ShowMetadataComponent } from './show-metadata/show-metadata.component';
import { LoginComponent } from './login/login.component';
import { ContactComponent } from './contact/contact.component';

import { DocumentTinyComponent } from './document-tiny/document-tiny.component';

import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { RequestResetPasswordComponent } from './request-reset-password/request-reset-password.component';
import { ResponseResetPasswordComponent } from './response-reset-password/response-reset-password.component';

import { TextareaAutoresizeDirective } from './textarea-autoresize.directive';
import { PedagogicalTemplateComponent } from './pedagogical-template/pedagogical-template.component';
import { TutorialsComponent } from './tutorials/tutorials.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CustomSnackbarComponent } from './custom-snackbar/custom-snackbar.component';
import { CommentsComponent } from './comments/comments.component';
import { ViewComponent } from './view/view.component';
import { MatPaginatorIntl } from '@angular/material';
import { MatPaginatorIntlPt } from './paginator-pt';
import { RatingModule } from 'ng-starrating';
import { ContributorsComponent } from './contributors/contributors.component';
import { NavigatorComponent } from './navigator/navigator.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoggedComponent,
    PanelComponent,
    DocumentsComponent,
    NewDocumentComponent,
    InfoMetadadosComponent,
    AboutComponent,
    RegisterComponent,
    ProfileComponent,
    NewDocumentFastComponent,
    SearchComponent,
    ShowMetadataComponent,
    LoginComponent,
    ContactComponent,
    DialogOverviewExampleDialog,
    DocumentTinyComponent,
    RequestResetPasswordComponent,
    ResponseResetPasswordComponent,
    TextareaAutoresizeDirective,
    PedagogicalTemplateComponent,
    TutorialsComponent,
    CustomSnackbarComponent,
    CommentsComponent,
    ViewComponent,
    ContributorsComponent,
    NavigatorComponent,
  ],
  imports: [
    BrowserModule,
    UiModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    RouterModule,
    MatButtonModule, MatCheckboxModule, MatTabsModule,  MatInputModule, MatGridListModule, MatDialogModule, 
    MatSelectModule, MatRadioModule, MatSliderModule, MatCardModule, MatChipsModule, MatIconModule, MatProgressBarModule,
    MatPaginatorModule, MatStepperModule, MatAutocompleteModule, MatFormFieldModule, MatTableModule,
    MatTooltipModule, MatExpansionModule, MatSnackBarModule,
    HttpClientModule,
    ReactiveFormsModule, 
    FileUploadModule,    
    RecaptchaModule,
    RecaptchaFormsModule,    
    FlexLayoutModule,
    RatingModule,
  ],
  providers: [LoginService, {provide: MatPaginatorIntl, useClass: MatPaginatorIntlPt}],
  entryComponents: [ DialogOverviewExampleDialog],
  bootstrap: [AppComponent]
})
export class AppModule { }
