import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { RestService } from '../rest.service';
import { Router } from '@angular/router';
import { MatTabGroup } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomSnackbarComponent } from '../custom-snackbar/custom-snackbar.component';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  constructor(private rest: RestService, private router: Router,
    private _snackBarAdvice: MatSnackBar, @Inject(DOCUMENT) private domDocument: Document) { }
  documentsToReview: any;
  documentsUnderReview: any;
  documentsOtherUnderReview: any;
  documentsOtherNeedReview: any;
  documentsToComplete: any;
  documentsSubmitted: any;
  documentsToAdmin: any;
  isReviewer: boolean;
  isAdmin: boolean;
  reviewerMainRole: string;
  roleText: string;
  tokenInfo: any;
  statusList: any;
  hideOthers: boolean;
  otherRevision: string;
  
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

  ngOnInit() {
    this.documentsToReview = [];
    this.documentsUnderReview = [];
    this.documentsOtherUnderReview = [];
    this.documentsOtherNeedReview = [];
    this.documentsToComplete = [];
    this.documentsSubmitted = [];
    this.documentsToAdmin = [];
    this.tokenInfo = this.rest.decodePayloadJWT();
    let status = this.getStatusScope(this.tokenInfo.roles);
    this.reviewerMainRole = this.getReviewRole(this.tokenInfo.roles);
    this.tokenInfo.roles.toString().includes('reviewer') ? this.isReviewer = true : this.isReviewer = false;
    this.tokenInfo.roles.toString().includes('admin') ? this.isAdmin = true : this.isAdmin = false;
    this.hideOthers = true;
    if(status[0] != 'undefined') {
      this.documentsToReview = this.getDocumentsToReview(status[0], "");
      this.documentsUnderReview = this.getDocumentsToReview(status[1], "");
      this.documentsOtherUnderReview = this.getDocumentsToReview(status[2], "");
      this.documentsOtherNeedReview = this.getDocumentsToReview(status[3], "");
    }
    this.getDocumentsToComplete(this.tokenInfo.sub);
    this.getDocumentsSubmitted(this.tokenInfo.sub);
    this.documentsToAdmin = this.getDocumentsToReview("","");
    this.statusList = ["INCOMPLETE", "NEEDS_TECH_REVIEW", "UNDER_TECH_REVIEW", "NEEDS_PEDAG_REVIEW",
      "UNDER_PEDAG_REVIEW", "REVIEWED"];
  }

  getReviewRole(roles) {
    roles = roles.toString().split(',');
    for(let role of roles) {
      // console.log(role);
      switch(role) {
        case "tech_reviewer": this.roleText = "científica"; this.otherRevision = "pedagógica"; return "tech_reviewer";
        case "pedag_reviewer": this.roleText = "pedagógica"; this.otherRevision = "científica"; return "pedag_reviewer";
      }
    }
    return "undefined";
  }

  getStatusScope(roles) {
    roles = roles.toString().split(',');
    for(let role of roles) {
      // console.log(role);
      switch(role) {
        case "tech_reviewer": return ["NEEDS_TECH_REVIEW", "UNDER_TECH_REVIEW", "UNDER_PEDAG_REVIEW", "NEEDS_PEDAG_REVIEW"];
        case "pedag_reviewer": return ["NEEDS_PEDAG_REVIEW", "UNDER_PEDAG_REVIEW", "UNDER_TECH_REVIEW", "NEEDS_TECH_REVIEW"];
      }
    }
    return ['undefined','undefined'];
  }

  getDocumentsSubmitted(owner){
    let query = "q=*:*&fq=!status:INCOMPLETE&fq=owner:" + owner;
    this.rest.querySOLR(query, 'DocumentTinyDto').subscribe((data: any) => {
      var rec = data.response.docs;
      for (var x in rec){
        this.documentsSubmitted.push({id:rec[x].id, title:rec[x].name, status:rec[x].status});
      }
    });
  }

  getDocumentsToComplete(owner) {
    let query = "q=*:*&fq=status:INCOMPLETE&fq=owner:" + owner;
    this.rest.querySOLR(query, 'DocumentTinyDto').subscribe((data: any) => {
      var rec = data.response.docs;
      for (var x in rec){
        this.documentsToComplete.push({id:rec[x].id, title:rec[x].name});
      }
    });
  }

  getDocumentsToReview(status, reviewer) {
    let documentsToReview = []
    let query = "q=*:*";
    if(status != "") {
      query+="&fq=status:" + status;
    }
    if(reviewer != "") {
      query+="&fq=reviewer:" + reviewer;
    }
    this.rest.querySOLR(query, 'DocumentTinyDto').subscribe((data: any) => {
      var rec = data.response.docs;
      for (var x in rec){
        documentsToReview.push({id:rec[x].id, title:rec[x].name, isValid:false, status:status, 
          owner:rec[x].owner, reviewer:rec[x].reviewer, current_status:rec[x].status, hasOntology:rec[x].hasOntology});
      }
    });
    return documentsToReview;
  }

  showOtherDocuments() {
    if(this.hideOthers) {
      if(this.documentsOtherUnderReview.length > 0 || this.documentsOtherNeedReview.length > 0) {
        document.getElementById("otherRevisions").style.display = "none";
      }
      document.getElementById("zeroRevisions").style.display = "none";
    } else {
      if(this.documentsOtherUnderReview.length > 0 || this.documentsOtherNeedReview.length > 0) {
        document.getElementById("otherRevisions").style.display = "";
      }
      document.getElementById("zeroRevisions").style.display = "";
    }
    
  }

  reviewDocuments(){
    document.body.style.cursor="wait";
    for(var i = 0; i < this.documentsToReview.length; i++){
      let status = this.getStatusScope(this.tokenInfo.roles);
      if(this.documentsToReview[i].isValid) {
        // console.log(i)
        this.rest.addDocumentSOLR(this.buildJson(this.documentsToReview[i].id, this.documentsToReview[i].owner, status[1], this.tokenInfo.sub)).subscribe((data: {}) => {
          console.log(data);
        });
      }
    }
    
    for(var i = this.documentsToReview.length -1; i >=0 ; i--){
      if(this.documentsToReview[i].isValid) {
        if(this.tokenInfo.roles.toString().includes('tech_reviewer')){
          this.documentsToReview[i].status = "UNDER_TECH_REVIEW";
        }
        if(this.tokenInfo.roles.toString().includes('pedag_reviewer')){
          this.documentsToReview[i].status = "UNDER_PEDAG_REVIEW";
        }
        this.documentsToReview[i].isValid = false;
        this.documentsToReview[i].reviewer = this.tokenInfo.sub;
        this.documentsUnderReview.push(this.documentsToReview[i]);
        this.documentsToReview.splice(i,1);
      }
    }
    this.tabGroup.selectedIndex = 2;

    document.body.style.cursor="initial";
  }

  backDocumentInWorkflow(id: number, i:number, owner:string, backWorkflowStep:string, currentWorkFlowStep: string) {
    let backWorkflowStepLabel = "";
    let new_status = "";
    
    switch(backWorkflowStep) {
      case "INCOMPLETE":
        new_status = this.documentsToReview[i].status.replace(currentWorkFlowStep, backWorkflowStep); 
        backWorkflowStepLabel = "submissão"; 
        break;
      case "NEEDS_TECH_REVIEW":
        new_status = this.documentsToReview[i].status.replace(currentWorkFlowStep, backWorkflowStep);  
        backWorkflowStepLabel = "rev. científica"; 
        break;
      case "NEEDS": 
        new_status = this.documentsUnderReview[i].status.replace(currentWorkFlowStep, backWorkflowStep); 
        break;
      default: backWorkflowStepLabel = "<<erro>>"; break;
    }
    if(backWorkflowStep != "NEEDS") {
      this.openSnackBar("Tem a certeza que quer enviar o OA para a etapa de " + 
        backWorkflowStepLabel + "?", "OK", "Warn", {id: id, i: i, new_status: new_status, backWorkflowStep: backWorkflowStep});
    } else {
      this.sendBackInWorkflow(id, i, owner, new_status, backWorkflowStep);
    }
  }

  buildJson(id:string, owner:string, status:string, reviewer:string, ontology?: boolean)  {
    let updateDoc = [];
    if(ontology) {
      updateDoc =
      [
        { 
          id: id,
          hasOntology: {"set": ontology},
        },    
      ];
    } else {
      updateDoc =
      [
        { 
          id: id,
          owner: {"set": owner},
          status: {"set": status},
          reviewer: {"set": reviewer} 
        },    
      ];
    }
    
    return JSON.stringify(updateDoc);
  }

  updateDocument(id, name, owner, new_status, reviewer) {
    this.openSnackBar("Tem a certeza de atualizar este objeto de aprendizagem?", "OK", "Warn",
      {id: id, name: name, owner: owner, new_status: new_status, reviewer: reviewer});
  }

  sendBackInWorkflow(id: number, i: number, owner: string, new_status: string, backWorkflowStep: string) {
    this.rest.addDocumentSOLR(this.buildJson(id.toString(), owner, new_status, this.tokenInfo.sub)).subscribe((data: {}) => {
      console.log(data);
    });

    if(backWorkflowStep == 'NEEDS_TECH_REVIEW' || backWorkflowStep == 'INCOMPLETE') {
      if(this.documentsToReview[i].owner == this.tokenInfo.sub && backWorkflowStep == 'INCOMPLETE') {
        this.documentsToReview[i].status = backWorkflowStep;
        this.documentsToComplete.push(this.documentsToReview[i]);
      } else if(backWorkflowStep == 'INCOMPLETE') {
        let pendencies = parseInt(localStorage.getItem('lastPendencies')) - 1;
        this.rest.pendencies.emit(pendencies);
        localStorage.setItem('lastPendencies', pendencies.toString());
      }
      this.documentsToReview.splice(i,1);
    }
    if(backWorkflowStep == "NEEDS") {
      this.documentsUnderReview[i].status = new_status;
      this.documentsToReview.push(this.documentsUnderReview[i]);
      this.documentsUnderReview.splice(i,1);
    }
    if(this.documentsToReview.length == 0 && 
      this.documentsUnderReview.length == 0 &&
      this.documentsToComplete == 0) {
      this.rest.pendencies.emit(0);
      localStorage.setItem('lastPendencies', '0');
    }
  }

  async doUpdateDocument(id: number, name: string, owner:string,  new_status: string, reviewer: string) {
    await this.rest.addDocumentSOLR(this.buildJson(id.toString(), owner, new_status, reviewer)).subscribe(
      result => {
        this.openSnackBar("Objeto salvo com sucesso! Recarregando página...", "OK", "Info");
        if(reviewer == this.tokenInfo.sub) {
          setTimeout(() => {this.domDocument.location.reload()}, 3000); 
        }
      },
      error => {
        this.openSnackBar("Erro ao salvar objeto!", "OK", "Info");
        console.log(error);
      }
    );     
  }

  logoutUser(){
    this.openSnackBar("Tem a certeza de realizar o logout?", "OK", "Warn");
  }

  doLogout() {
    this.rest.logged.emit(false)
    this.rest.isLogged = false;
    localStorage.removeItem('token');
    localStorage.removeItem('lastPendencies');
    this.router.navigate([''])
  }

  openSnackBar(message: string, action: string, snackType: string, info?: any) {
    let snackBarRef = this._snackBarAdvice.openFromComponent(CustomSnackbarComponent, {
      duration: 20000,
      horizontalPosition: 'center',
      //verticalPosition: 'top',
      data: { message: message, action: action, snackType: snackType, info: info }
    });
    snackBarRef.onAction().subscribe(() => {
      switch(message.toString()) {
        case "Tem a certeza de realizar o logout?" : 
              this.doLogout(); 
              break; 
        case "Tem a certeza que quer enviar o OA para a etapa de submissão?":
        case "Tem a certeza que quer enviar o OA para a etapa de rev. científica?": 
              this.sendBackInWorkflow(info.id, info.i, info.owner, info.new_status, info.backWorkflowStep); break;
        case "Tem a certeza de atualizar este objeto de aprendizagem?":
              this.doUpdateDocument(info.id, info.name, info.owner, info.new_status, info.reviewer); break;
      }
    });    
  }

  updateOntology(doc) {
    document.body.style.cursor="wait";
    this.rest.addOntology(doc.id).subscribe(
      response => {
        if (response == true) {
          this.openSnackBar("Ontologia criada com sucesso.", "OK", "Info");
          //TODO: reiniciar solr para schema pegar
          this.rest.addDocumentSOLR(this.buildJson(doc.id,"","","", true)).subscribe((data: {}) => {
            console.log(data);
          });
          doc.hasOntology = true;
        } else {
          if(response) {
            this.openSnackBar(response.toString(), "OK", "Info");
          } else {
            this.openSnackBar("Erro ao criar ontologia.", "OK", "Info");
          }
        }
      },
      err => {
        console.log(err.toString())
        this.openSnackBar("Erro ao criar ontologia, possivelmente metadados não existem.", "OK", "Info");
      }
    );
    document.body.style.cursor="initial";
  }
}
