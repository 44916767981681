<div>
  <mat-toolbar style="background-color:white;">
    <div fxShow="true" fxHide.gt-sm="true">
      <button mat-icon-button (click)="sidenav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
    </div>
    <a mat-button class="home" routerLink="/" routerLinkActive="active" class="link-text">
      <span>Home</span>
    </a>
    <span class="example-spacer"></span>
    <div fxShow="true" fxHide.lt-md="true">
      <a mat-button routerLink="/contributors" routerLinkActive="active" class="link-text">Colaborações</a>
      <a mat-button routerLink="/contact" routerLinkActive="active" class="link-text">Contacto</a>
      <a mat-button routerLink="/tutorials" routerLinkActive="active" class="link-text">Tutoriais</a>
      <a mat-button routerLink="/about" routerLinkActive="active" class="link-text">Sobre</a>
      <a *ngIf = "isLogged" mat-button routerLink="/documents/add" class="link-text">Adicionar objeto</a>
      <a *ngIf = "!isLogged" mat-button [routerLink]="['/signin']" [queryParams]="{ page: 'home' }"  routerLinkActive="active" class="link-text" style="text-align: right">  <mat-icon aria-hidden="false" aria-label="User">person</mat-icon>Login</a>
      <a *ngIf = "isLogged"  mat-button [routerLink]="['/profile/logout']"  routerLinkActive="active" class="link-text" style="text-align: left;"><mat-icon [matBadgeHidden] = "totalPendencies == 0" matBadge="{{totalPendencies}}" matBadgePosition="above before" matBadgeColor="warn" aria-hidden="false" aria-label="User">person</mat-icon>Bem-vindo(a) {{this.userName}}</a>
    </div>

  </mat-toolbar>
  <mat-sidenav-container fxFlexFill class="example-container">
    <mat-sidenav color="primary" #sidenav fxLayout="column" mode="over"  opened="false" fxHide.gt-sm="true">
      <div fxLayout="column">
        <a mat-button routerLink="/contributors" routerLinkActive="active" style="text-align: left;">Colaborações</a>
        <a mat-button routerLink="/contact" routerLinkActive="active" style="text-align: left;">Contacto</a>
        <a mat-button routerLink="/tutorials" routerLinkActive="active" style="text-align: left;">Tutoriais</a>
        <a mat-button routerLink="/about" routerLinkActive="active" style="text-align: left;">Sobre</a>
        <a *ngIf = "isLogged" mat-button routerLink="/documents/add" style="text-align: left;">Adicionar objeto</a>
        <a *ngIf = "!isLogged" mat-button [routerLink]="['/signin']" [queryParams]="{ page: 'home' }"  routerLinkActive="active" style="text-align: left;"><mat-icon aria-hidden="false" aria-label="User">person</mat-icon>Login</a>
        <a *ngIf = "isLogged"  mat-button [routerLink]="['/profile/logout']"  routerLinkActive="active" class="link-text" style="text-align: left;"><mat-icon [matBadgeHidden] = "totalPendencies == 0" matBadge="{{totalPendencies}}" matBadgePosition="above before" matBadgeColor="warn" aria-hidden="false" aria-label="User">person</mat-icon>Bem-vindo(a) {{this.userName}}</a>
      </div>
    </mat-sidenav>
    <mat-sidenav-content fxFlexFill style="background-color:white;">   
        <div class= "center">
          <a href="/" >
            <img src="../assets/images/Logo_Re-Mar.png" alt="logo" style="width:30em;max-width: 100%;height:auto"/>
          </a>
        </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>