import { Component, OnInit } from '@angular/core';
import { RestService } from '../rest.service';
import { FormControl } from '@angular/forms';
import * as d3 from 'd3';

const dbpedia = "dbpedia.org";
const creativecommons = "creativecommons.org";
const w3 = "w3.org";
const remar = "re-mar.uac.pt";

@Component({
  selector: 'app-navigator',
  templateUrl: './navigator.component.html',
  styleUrls: ['./navigator.component.css']
})

export class NavigatorComponent implements OnInit {

  documentsWithOntology: any;
  ids: any;
  jsonInfo: any;
  filteredJsonInfo: any;
  keywordsList = [];
  objectLinksList = [];
  objectsList = [];
  url_icon: any;
  keyword = new FormControl();
  object = new FormControl();
  links = new FormControl();
  linksArray = [];
  nodesArray = [];

  constructor(private rest: RestService) { }

  ngOnInit(): void {
    this.documentsWithOntology = [];
    document.body.style.cursor="wait";
    //console.log('D3.js version:', d3['version']);
    this.getOntlogyDocumentsSubmitted().then((data1) => {
      var rec = data1.response.docs;
      this.ids = "";
      for (var x in rec){
        this.ids += rec[x].id;
        this.ids += ","; 
        this.documentsWithOntology.push({id:rec[x].id, title:rec[x].name});
      }
      this.ids = this.ids.substring(0, this.ids.lastIndexOf(","));
      console.log(this.ids)
      this.getOntologyLinksById(this.ids).then((data2)=> {
        this.jsonInfo = data2;
        this.filteredJsonInfo = data2;
        this.getFilterValues(this.jsonInfo['links']);
        this.loadForceDirectedGraph();
      }, (error)=>{
        console.log("Promise rejected with " + JSON.stringify(error));
      })
    }, (error)=>{
      console.log("Promise rejected with " + JSON.stringify(error));
    })
    document.body.style.cursor="initial";
  }

  async getOntologyLinksById(id) {
    let response = await this.rest.getOntologyLinks(id).toPromise();
    return response;
  }

  async getOntlogyDocumentsSubmitted(){
    let query = "q=*:*&fq=status:REVIEWED&fq=hasOntology:true";
    let response = await this.rest.querySOLR(query, 'DocumentTinyDto').toPromise();
    return response;
  }

  // Get all links through the selected values, then the missing nodes  
  filter() {
    this.linksArray = [];
    this.nodesArray = [];

    if(this.keyword.value) {
      for (let i = 0; i < this.keyword.value.length; i++) {
        this.getFilteredValueLinks(this.keyword.value[i], "");
      }
    }
    if(this.object.value) {
      for (let i = 0; i < this.object.value.length; i++) {
        this.getFilteredValueLinks(this.object.value[i], "");
      }
    }
    if(this.links.value) {
      for (let i = 0; i < this.links.value.length; i++) {
        this.getFilteredValueLinks(this.links.value[i], remar);
      }
    }
    this.filteredJsonInfo = JSON.parse("{ \"nodes\":" + JSON.stringify(this.nodesArray) +
      ", \"links\":" + JSON.stringify(this.linksArray) + "}");

    this.loadForceDirectedGraph();
  }

  getFilteredValueLinks(value: String, type: String) {
    for(var i = 0; i < this.jsonInfo.links.length; i++) {
      if(this.jsonInfo.links[i].source.id == value ||
          this.jsonInfo.links[i].target.id == value) {
            if(this.linksArray.indexOf(this.jsonInfo.links[i]) == -1) {
              if(type == "") {
                this.linksArray.push(JSON.parse("{ \"source\":" + "\"" +  this.jsonInfo.links[i].source.id + "\"," +
                  "\"target\":" + "\"" + this.jsonInfo.links[i].target.id + "\", \"value\": 1}"));
                this.getFilteredValueNodes(this.jsonInfo.links[i].source.id);
                this.getFilteredValueNodes(this.jsonInfo.links[i].target.id);
              } else if (this.jsonInfo.links[i].target.id.indexOf(remar) != -1) {
                this.linksArray.push(JSON.parse("{ \"source\":" + "\"" +  this.jsonInfo.links[i].source.id + "\"," +
                  "\"target\":" + "\"" + this.jsonInfo.links[i].target.id + "\", \"value\": 1}"));
                this.getFilteredValueNodes(this.jsonInfo.links[i].source.id);
                this.getFilteredValueNodes(this.jsonInfo.links[i].target.id);
              }
            }
      }
    }
  }

  getFilteredValueNodes(value: String) {
    for(var i = 0; i < this.jsonInfo.nodes.length; i++) {
      if(this.jsonInfo.nodes[i].id == value) {
        if(this.nodesArray.indexOf(this.jsonInfo.nodes[i]) == -1) {
          this.nodesArray.push(this.jsonInfo.nodes[i]);
        }
      }
    }
  }

  reset() {
    this.filteredJsonInfo = this.jsonInfo;
    this.loadForceDirectedGraph();
    this.keyword.reset();
    this.links.reset();
    this.object.reset();
  }

  getFilterValues(links) {
    for(var i = 0; i < links.length; i++) {
      if(links[i].source.indexOf(remar) != -1 && 
        this.objectsList.indexOf(links[i].source) == -1) {
          this.objectsList.push(links[i].source);
        if(links[i].target.indexOf(remar) != -1) {
          if(this.objectLinksList.indexOf(links[i].source) == -1) {
            this.objectLinksList.push(links[i].source)
          }
          if(this.objectLinksList.indexOf(links[i].target) == -1) {
            this.objectLinksList.push(links[i].target)
          }
        }
      }
      if(links[i].target.indexOf(dbpedia) != -1 && 
          this.keywordsList.indexOf(links[i].target) == -1) {
            this.keywordsList.push(links[i].target);
      }
    }
  }

  loadForceDirectedGraph() {
    const svg = d3.select('svg');
    svg.selectAll("*").remove();
    const width = +svg.attr('width');
    const height = +svg.attr('height');
    const color = d3.scaleOrdinal(d3.schemeCategory20);
    const radius = 20;
    const nodePadding = 30;
    this.url_icon = "";

    const simulation = d3.forceSimulation()
      .force('link', d3.forceLink().id((d) => d['id']))
      .force('charge', d3.forceManyBody())
      .force('center', d3.forceCenter(width / 2, height / 2))
      .force('x', d3.forceX(width / 2))
      .force('y', d3.forceY(height / 2))
      .force('collide', d3.forceCollide().radius(radius + nodePadding));

    const link = svg.append('g')
      .attr('class', 'links')
      .selectAll('line')
      .data(this.filteredJsonInfo['links'])
      .enter()
      .append('line')
      .attr('stroke-width', (d) => Math.sqrt(d['value']))
      .attr("stroke", (d)=> { return "#D3D3D3"; });

    const node = svg.append('g')
      .attr('class', 'nodes')
      .selectAll('circle')
      .data(this.filteredJsonInfo['nodes'])
      .enter()
      .append("a")
      .attr("target", "_blank")
      .attr("xlink:href", (d) => d['id']) 
      .append('circle')
      .attr('r', radius)
      .attr('fill', (d) => color(d['group']))
      .on("mouseover", function(){ 
        d3.select(this).style("fill", ((d) => "url(#" + selectIconId(d['id']) + ")"));
      })
      .on("mouseout", function(){ 
        d3.select(this)
          .style("fill", (d) => color(d['group']));
      })
        
      .call(d3.drag()
        .on('start', dragStarted)
        .on('drag', dragged)
        .on('end', dragEnded)
      );

      node.append('title')
        .text((d) => ((d['learningobject'] == '1') ? this.getTitle(d['id']) : d['id']));
   
      simulation
        .nodes(this.filteredJsonInfo['nodes'])
        .on('tick', ticked);

      simulation.force<d3.ForceLink<any, any>>('link')
        .links(this.filteredJsonInfo['links']);

      function ticked() {
        link
          .attr('x1', function(d) { return d['source'].x; })
          .attr('y1', function(d) { return d['source'].y; })
          .attr('x2', function(d) { return d['target'].x; })
          .attr('y2', function(d) { return d['target'].y; });

      node
        .attr('cx', function(d) { return d['x']; })
        .attr('cy', function(d) { return d['y']; });
      }
    // });

    function selectIconId(link) {
      if(link.indexOf(dbpedia) != -1) {
        return "keyword";
      } else if (link.indexOf(creativecommons) != -1) {
        return "creativecommons";
      } else if (link.indexOf(w3) != -1) {
        return "xml";
      }
      return "view";  
    }

    function dragStarted(d) {
      if (!d3.event.active) { simulation.alphaTarget(0.3).restart(); }
      d.fx = d.x;
      d.fy = d.y;
    }

    function dragged(d) {
      d.fx = d3.event.x;
      d.fy = d3.event.y;
    }

    function dragEnded(d) {
      if (!d3.event.active) { simulation.alphaTarget(0); }
      d.fx = null;
      d.fy = null;
    }

    
  }

  getTitle(id) {
    for(let i=0; i < this.documentsWithOntology.length; i++) {
      if(this.documentsWithOntology[i].id == id) {
        return this.documentsWithOntology[i].title;
      }
    }
  }
}