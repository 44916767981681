<mat-card id="card{{documentsTiny.id}}" class="card" (click)="onSelect()"> 
    <mat-checkbox matTooltip="Selecionar"  [(ngModel)]="documentsTiny.selected" disabled></mat-checkbox>
    
    <a style="float: right" matTooltip="+ Info" class="card-footers theme-orange" [routerLink]="['/documents/' + documentsTiny.id]" routerLinkActive="router-link-active" target="_blank">
        <mat-icon>info</mat-icon>
    </a>
    <img mat-card-image class="mat-card-image" src={{thumb}} alt="Photo"> 
    
    <mat-card-actions matTooltip="{{documentsTiny.completeTitle}}">
        <h5 style="text-align: center"><strong>{{documentsTiny.title}}</strong></h5>
    </mat-card-actions>
            
</mat-card>
        

              
                  