
<div class="footer-area">
  <br/>
  <div class="footer-text-area">
    <div class="footer-text">Este trabalho é financiado pelo FEDER em 85% e por fundos
      regionais em 15%, através da Programa Operacional Açores 2020, no âmbito do projeto
      SEA-THINGS Objetos de Aprendizagem para Promover a Literacia Oceânica    
      ACORES-01-0145-FEDER-000110</div>
  </div>
  <div>
    <img src="../assets/images/Barra de Assinatura UAc.png" style="width:100%; margin-bottom: 3em"/>
  </div>
  <div>
    <img src="../assets/images/Barra de Assinatura ACORES 2020 FSE.png" style="width:100%; margin-bottom: 3em"/>
  </div>
</div>