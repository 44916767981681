<br>
<br>
<div class="grey-division">
  <div class="grey-division-text"><strong>Tutoriais</strong></div>
</div>
<br>
<br>
<div class="center-paragraph">
  <br>
  <h3>Saiba mais sobre o Re-Mar:</h3>
  <br>
</div>
<div class="content">
  <div fxLayout="row wrap" fxLayoutGap="30px grid">
    <div [fxFlex]="(100/gridColumns) + '%'" fxFlex.xs="100%" fxFlex.sm="33%" *ngFor="let video of presentationVideos">
      <mat-card class="mat-elevation-z4">
        <mat-card-header>
          <mat-card-title>{{video.title}}</mat-card-title>
        </mat-card-header>
        <div class="video-container">
          <video controls>
            <source src="{{video.fileName}}" type="video/mp4">
          </video>
        </div>
        <mat-card-content>
          <br>
          <p>
            {{video.description}}
          </p>
        </mat-card-content>
        <!-- <mat-card-actions>
            <button mat-button>LIKE</button>
            <button mat-button>SHARE</button>
          </mat-card-actions> -->
      </mat-card>
    </div>
  </div>
</div>
<hr>

<div class="center-paragraph">
  <br>
  <h3>Utilize estes vídeos para criar os seus próprios objetos de aprendizagem:</h3>
  <br>
</div>
<div class="content">
  <div fxLayout="row wrap" fxLayoutGap="30px grid">
    <div [fxFlex]="(100/gridColumns) + '%'" fxFlex.xs="100%" fxFlex.sm="33%" *ngFor="let video of tutorialVideos">
      <mat-card class="mat-elevation-z4">
        <mat-card-header>
          <mat-card-title>{{video.title}}</mat-card-title>
        </mat-card-header>
        <div class="video-container">
          <video controls>
            <source src="{{video.fileName}}" type="video/mp4">
          </video>
        </div>
        <mat-card-content>
          <br>
          <p>
            {{video.description}}
          </p>
        </mat-card-content>
        <!-- <mat-card-actions>
            <button mat-button>LIKE</button>
            <button mat-button>SHARE</button>
          </mat-card-actions> -->
      </mat-card>
    </div>
  </div>
</div>