import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RestService } from '../rest.service';
import { RecaptchaErrorParameters } from "ng-recaptcha";
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomSnackbarComponent } from '../custom-snackbar/custom-snackbar.component';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

    public signUpForm: FormGroup;

    hide = true;
    hideConfirm = true;
    allowEmail = false;

    constructor(private formBuilder: FormBuilder, private router: Router, 
      private restApi: RestService, private _snackBarAdvice: MatSnackBar) {
        this.signUpForm = this.formBuilder.group({
            name: ['', Validators.required],
            username: ['', Validators.required],
            password: ['', Validators.required],
            confirmPassword: ['', Validators.required],
            captcha: ['', Validators.required],
            receiveEmail: [this.allowEmail],
        });
    }

    public resolved(captchaResponse: string): void {
        console.log(`Resolved captcha with response: ${captchaResponse}`);
    }

    public onError(errorDetails: RecaptchaErrorParameters): void {
        console.log(`reCAPTCHA error encountered; details:`, errorDetails);
    }

    ngOnInit() {
    }

    openSnackBar(message: string, action: string, duration: number, snackType: string, role?: string) {
        let snackBarRef = this._snackBarAdvice.openFromComponent(CustomSnackbarComponent, {
            duration: duration,
            horizontalPosition: 'center',
            //verticalPosition: 'top',
            data: { message: message, action: action, snackType: snackType, role: role }
        });
    }
    
    onSignUp() {        
        if (this.signUpForm.valid) {
            if(this.signUpForm.controls['password'].value != this.signUpForm.controls['confirmPassword'].value) {
                this.openSnackBar("As palavras-passe devem ser iguais.", "OK", 3000, "Info");
                return;
            }

            let seq = this.restApi.postSignup(this.signUpForm.value);

            seq.subscribe((response) => {
                console.log(response)
                if (response == true) {
                    this.openSnackBar("Utilizador criado com sucesso", "OK", 3000, "Info");
                    this.router.navigate(['/']);
                }else if (response=='e'){
                    this.openSnackBar("Erro ao registar utilizador. Envie mensagem de contacto.", "OK", 3000, "Info");
                } else {
                    this.openSnackBar(response.toString(), "OK", 3000, "Info");
                }
            });
        } else {
            this.openSnackBar("Preencha todos os campos.", "OK", 3000, "Info");
            return;
        }

    }

}
