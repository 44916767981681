import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { OBAA } from '../metadata';
import { emptyMockOBAA } from '../mock-data';
import { RestService, endpoint } from '../rest.service';
import { ActivatedRoute } from "@angular/router";
import { DomSanitizer } from '@angular/platform-browser';
import { PedagogicalTemplateComponent } from '../pedagogical-template/pedagogical-template.component';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { StarRatingComponent } from 'ng-starrating'; 
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomSnackbarComponent } from '../custom-snackbar/custom-snackbar.component';


@Component({
  selector: 'app-info-metadados',
  templateUrl: './info-metadados.component.html',
  styleUrls: ['./info-metadados.component.css']
})

export class InfoMetadadosComponent implements OnInit {
  info: OBAA;
  id = "init";
  documents:any;
  simple:any;
  enableViewComponent: boolean;
  enableViewButton: boolean;
  thumb: string;
  fileId: any;
  ipAddress: string;
  isLogged: boolean;
  totalVotes: any;
  totalFavorites: any;
  meanVotes: any;
  voteMsg: any;
  favoriteMsg: any;
  uncheckedColor: any;
  like: boolean;

  constructor(private route: ActivatedRoute, private rest: RestService, 
    private sanitizer:DomSanitizer, private dialog: MatDialog,
    private http: HttpClient, private _snackBarAdvice: MatSnackBar) { }

  ngOnInit() {
    if(!localStorage.getItem('token') || this.tokenExpired(localStorage.getItem('token_expiration')) 
      || !localStorage.getItem('lastPendencies')) {
        this.isLogged = false;
        this.voteMsg = "É necessário fazer o login para votar";
        this.favoriteMsg = "É necessário fazer o login para adicionar aos favoritos";
    } else {
      this.isLogged = true;
      this.voteMsg = "Clique para avaliar o objeto de aprendizagem!";
      this.favoriteMsg = "Adicione o objeto de aprendizagem aos favoritos";
    }
    this.id = this.route.snapshot.paramMap.get('id');
    this.uncheckedColor = "white";
    this.rest.querySOLR("q=doc_id:\""+ this.id + "\"&fq=type:\"rate\"&stats=true&stats.field=rate" , 'Statistics').subscribe((data: any) => {
      this.meanVotes = data.stats.stats_fields.rate.mean;
      if(isNaN(this.meanVotes)) {
        this.meanVotes = 0;
        this.uncheckedColor = "gray";
      }
      this.totalVotes = data.response.numFound;
    });
    this.like = false;
    this.totalFavorites = 0;
    let tokenInfo = this.rest.decodePayloadJWT();
    this.rest.querySOLR("q=id:"+ this.id + "&fq=favorites:*" , 'DocumentTinyDto').subscribe((data: any) => {
      // console.log(data)
      this.totalFavorites = data.response.docs[0].favorites.length - 1;
      for(var i = 0; i < data.response.docs[0].favorites.length; i++) {
        if(data.response.docs[0].favorites[i] == tokenInfo.sub) {
          this.like = true;
        }
      }
    });
    this.ipAddress = "";
    this.fileId = [];
    this.enableViewComponent = false;
    this.enableViewButton = false;
    this.simple = {
      name:"",
      accessibilitylanguage:"",
      public: "",
      description:"",
      accessibility:"",
      context: "",
      education:"",
      area:"",
      interaction:"",
      interactionNumber:"",
      dificulty:"",
      rights:"",
      author:[{
        name: "",
        institution: "",
        role:"",
      }],
      keywords:"",
      licence:"",
      target: [],
      age: [],
      knowledgeArea: [],
      typicalLearningTime:"",
      resources: [],
      owner:"admin",
      favorites:"",
      id:0,
      curriculumAreas: [],
      duration: "",
      learningObjectives: "",
      linkOfLo: "",
      mainStrategies: "",
      relevantInfo: "",
      status: "",
      relationWith: [],
      reviewersComments: [],
    };

    this.info = emptyMockOBAA;
    
    this.rest.getDocumentFromID(this.id).subscribe((data: any) => {
      Object.assign(this.info,data);
      for(let i = data.files.length-1; i >= 0 ; i--){
        if(data.files[i].name != "thumbnail") {
          this.fileId.push({id: data.files[i].id, contentType: data.files[i].contentType});
          if(data.files[i].contentType.indexOf("zip") == -1 &&
            data.files[i].contentType.indexOf("rar") == -1) {
              // console.log(data.files[i].contentType.indexOf("mp4") )
              if(data.files[i].contentType.indexOf("png") > -1 || 
                data.files[i].contentType.indexOf("jpeg") > -1 || 
                data.files[i].contentType.indexOf("jpg") > -1 || 
                data.files[i].contentType.indexOf("gif") > -1 || 
                data.files[i].contentType.indexOf("tiff") > -1 || 
                data.files[i].contentType.indexOf("mp4") > -1 || 
                data.files[i].contentType.indexOf("pdf") > -1) {
                  this.enableViewButton = true;
                }
          }
        }
      }
      this.thumb = endpoint  + "/files/" + this.id + "/thumbnail";
    });

    var finalString = "q=id:\""+ this.id + "\"";
    
    this.rest.querySOLR(finalString, 'DocumentTinyDto').subscribe((data: any) => {
      this.documents = data.response.docs;
      // console.log(this.documents);
      // this.simples = this.documents[0];
      this.simple = {
        name:this.documents[0].name,
        language: this.documents[0].language,
        keywords:this.documents[0].keywords,
        description:this.documents[0].description,
        interaction:this.documents[0].interaction,
        interactionNumber:this.documents[0].interactionNumber,
        licence:this.documents[0].licence,
        target: this.documents[0].target,
        age: this.documents[0].age,
        knowledgeArea: this.documents[0].knowledgeArea,
        resources: this.documents[0].resources,
        typicalLearningTime:this.documents[0].typicalLearningTime,
        owner:this.documents[0].owner,
        favorites:this.documents[0].favorites,
        free:this.documents[0].free,
        authors:this.documents[0].author,
        author:[],
        curriculumAreas: this.documents[0].curriculumAreas,
        duration: this.documents[0].duration,
        learningObjectives: this.documents[0].learningObjectives,
        linkOfLo: this.documents[0].linkOfLo,
        mainStrategies: this.documents[0].mainStrategies,
        relevantInfo: this.documents[0].relevantInfo,
        status: this.documents[0].status,
        relationWith: this.documents[0].relationWith,
        reviewersComments: this.documents[0].reviewersComments,
        id: this.documents[0].id,
      }

      // console.log(this.rest.getAnnotatedText(this.documents[0].description))

      for(var i = 0; i < this.simple.authors.length; i++){
         var aut_parts = this.simple.authors[i].split("=") 
         var aut_name = aut_parts[1].substring(0, aut_parts[1].lastIndexOf(","));
         var aut_institution = aut_parts[2].substring(0, aut_parts[2].lastIndexOf(","));
         var aut_role = aut_parts[3].toString().replace('[', '').replace(']', '').replace('}', '');

         var aut_roles = [];
        if(aut_role.indexOf(",") == -1) {
          aut_roles.push(aut_role);
        } else {
          aut_roles = aut_role.split(", ");
        }

        if(aut_name.trim() != "") {
          this.simple.author.push({name:aut_name, institution:aut_institution , role:aut_roles}); 
        }  
      }

      if(this.simple.status == "REVIEWED") {
        this.saveUserStatistics("view", "", "");
      }
    });
  }

  private tokenExpired(expiry: string) {
    return (Math.floor((new Date).getTime() / 1000)) >= +expiry;
  }

  async getIPAddress() {
    let response = await this.http.get("https://ipapi.co/ip/", { responseType: 'text' }).toPromise();
    this.ipAddress = response;
  }

  async saveUserStatistics(type, rate, id) {
    await this.getIPAddress();
    let tokenInfo = this.rest.decodePayloadJWT();

    
    let statistic = {
      id: id,
      doc_id: this.simple.id,
      type: type,
      user: tokenInfo.sub,
      ip: this.ipAddress,
      rate: rate,
      datetime: new Date(new Date().toString().split('GMT')[0]+' UTC').toISOString()
    };

    let json_stat = JSON.stringify([statistic]);
    if(id == "") {
      json_stat = json_stat.replace('"id":"",',"");
    }

    this.rest.saveStatistic(json_stat).subscribe((data: any) => {
      console.log(data);
    });
  }

  openPedagogicalTemplateDialog() : void {
  let dialogRef = this.dialog.open(PedagogicalTemplateComponent, {
      width: '800px',  height: '600px',
      data: [this.simple, (this.fileId) ? true : false, true]
    });
  }

  view() {
    this.enableViewComponent = true;
  }

  scrollTo(el: HTMLElement) {
    el.scrollIntoView({behavior: 'smooth'});
  }

  sanitize() {
    if (this.fileId.length > 1) {
      return this.sanitizer.bypassSecurityTrustUrl(endpoint + '/documents/' + this.id + '/downloadZipFiles');
    } else {
      return this.sanitizer.bypassSecurityTrustUrl(endpoint + '/files/'+ this.fileId[0].id);
    }
  }

  async onRate($event:{oldValue:number, newValue:number, starRating:StarRatingComponent}) {
    if(this.isLogged) {
      let updateRate;
      let tokenInfo = this.rest.decodePayloadJWT();
      let response = await this.rest.querySOLR("q=doc_id:\"" + this.id + "\"&fq=type:\"rate\"&fq=user:\"" + tokenInfo.sub + "\"" , 'Statistics').toPromise();
      let id; 
      (response.response.numFound > 0) ? id = response.response.docs[0].id : id = "";  
      (!id ? this.totalVotes+=1 : this.totalVotes+=0);
        // alert(`Old Value:${$event.oldValue}, 
        //   New Value: ${$event.newValue}, 
        //   Checked Color: ${$event.starRating.checkedcolor}, 
        //   Unchecked Color: ${$event.starRating.uncheckedcolor}`);
      this.saveUserStatistics("rate", $event.newValue, id); 
      this.openSnackBar("Muito obrigado pela vossa avaliação!", "OK", 3000, "Info");
    } else {
      this.openSnackBar("É necessário realizar login para votar.", "OK", 3000, "Info");
    }
    
  }

  openSnackBar(message: string, action: string, duration: number, snackType: string, role?: string) {
    let snackBarRef = this._snackBarAdvice.openFromComponent(CustomSnackbarComponent, {
        duration: duration,
        horizontalPosition: 'center',
        //verticalPosition: 'top',
        data: { message: message, action: action, snackType: snackType, role: role }
    });
  }

  updateLike(value, id) {
    let json = (value ? this.buildJsonToAdd(id) : this.buildJsonToRemove(id))
    this.rest.addDocumentSOLR(json).subscribe((data: {}) => {
      console.log(data);
    });
    (value ? this.totalFavorites+=1 : this.totalFavorites-=1)
  }

  buildJsonToAdd(id:string)  {
    let tokenInfo = this.rest.decodePayloadJWT();
    var updateDoc =
    [
      { 
        id: id,
        favorites: {"add": tokenInfo.sub} 
      },    
    ];
    return JSON.stringify(updateDoc);
  }

  buildJsonToRemove(id:string)  {
    let tokenInfo = this.rest.decodePayloadJWT();
    var updateDoc =
    [
      { 
        id: id,
        favorites: {"remove": tokenInfo.sub} 
      },    
    ];
    return JSON.stringify(updateDoc);
  }
}
