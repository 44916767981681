import { Component, OnInit, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RestService } from '../rest.service';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.css']
})
export class CommentsComponent implements OnInit {

  @Input() simple: any;
  @Output() commentText: EventEmitter<any> = new EventEmitter();
  @Output() unsavedText: EventEmitter<any> = new EventEmitter();
  public reviewersCommentsForm: FormGroup;
  textTosave: boolean;

  constructor(private formBuilder: FormBuilder, public rest:RestService) { 
    this.reviewersCommentsForm = this.formBuilder.group({
      comment: [''],
    });
  }

  ngOnInit(): void {
    this.textTosave = false;
    if(this.simple.status == "REVIEWED") {
      document.getElementById("commentsInputDiv").style.display = "none";
    }
    let tokenInfo = this.rest.decodePayloadJWT();
    let roles = tokenInfo.roles.toString().split(",");
    let matched_role = "";
    for(var i=0; i < roles.length; i++) {
      if(roles[i].includes("reviewer")){
        matched_role = roles[i].substring(0, roles[i].length -2).toUpperCase();
      }
    }
    if(this.simple.owner == tokenInfo.sub) {
      matched_role = "author";
    }
    if(!matched_role) {
      document.getElementById("commentsShowDiv").style.display = "none";
    }
  }

  getCommentPart(comment, part) {
    switch(part) {
      case "comment=":
        return comment.toString().substring(comment.toString().indexOf(part)).replace(part,"").replace("}","");
      case "phase=":
      case "user=":
        let text = comment.toString().substring(comment.toString().indexOf(part),).replace(part,"");
        return text.split(",")[0];
      default: return "";
    }
  }

  addComment() {
    this.commentText.emit(this.reviewersCommentsForm.controls['comment'].value);
    this.unsavedText.emit(false);
  }

  existText() {
    this.textTosave = false;
    if(this.reviewersCommentsForm.controls['comment'].value.trim() != '') {
      this.textTosave = true;
    }
    this.unsavedText.emit(this.textTosave);
  }

  translatePhase(status: string) {
    switch(status) {
      case "INCOMPLETE": return "Submissão";
      case "UNDER_TECH_REVIEW": return "Rev. científica";
      case "UNDER_PEDAG_REVIEW": return "Rev. pedagógica"; 
      default: return "";
    }
  }
}
