import { Component, OnInit, ViewChild } from '@angular/core';
import { learningCyclesList, knowledgeAreasList, curricularAreasList, institutionList, stopwords} from './search-datasource'
import { RestService } from '../rest.service';
import { ActivatedRoute } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})

export class SearchComponent implements OnInit {
  tooltipAdvancedSearch : string;
  searchOptions: any;
  finalparam: string;
  searchText = "";
  initSearch = "";
  breakpoint: number;
  documents = [];
  ipAddress: string;
  learningCycles = new FormControl();
  learningCyclesList: any;
  knowledgeAreas = new FormControl();
  knowledgeAreasList: any;
  curricularAreas = new FormControl();
  curricularAreasList: any;
  institution = new FormControl();
  institutionList: any;
  stopwords: any;
  filterQuery = "";
  searchFavorites = false;
  isLogged: boolean;
  totalItems: number;
  @ViewChild('paginatorTop') paginatorTop;
  @ViewChild('paginatorBottom') paginatorBottom;
  searchSuggestions: any;
  selectedSearchSuggestions: string[];
  paramsObject: any;
  parameters: any;
  
  constructor(
    private route: ActivatedRoute,
    public rest: RestService,
    private http: HttpClient) { }

  ngOnInit() {
    this.tooltipAdvancedSearch = "A busca padrão procura por todos os termos indicados por separação de espaço. \n";
    this.tooltipAdvancedSearch += "Utilize aspas duplas no ínicio e fim dos termos para pesquisar de forma exata; \n";
    this.tooltipAdvancedSearch += "Para excluir termos utilize - ou ! antes de cada termo; \n";
    this.tooltipAdvancedSearch += "Para obrigar o aparecimento de um termo utilize + antes dele; \n";
    this.tooltipAdvancedSearch += "Também pode-se utilizar ? e * como coringas, para um caractere e vários, respectivamente.";
    this.searchSuggestions = "{narrowTerms: \"[]\", broaderTerms: \"[]\"}";
    this.selectedSearchSuggestions = [];
    this.totalItems = 0;
    this.isLogged = this.checkLogged();
    this.ipAddress = "";
    this.initSearch = this.route.snapshot.paramMap.get('search');
    this.parameters = {
      learningCycle: [],
      knowledgeArea: [],
      curricularArea: [],
      institution: [],
    };
   
    this.route.queryParamMap.subscribe((params) => {
      this.paramsObject = { ...params.keys, ...params };
      for(let param of params.keys) {
        switch(param){
          case "ensino": (Array.isArray(this.paramsObject.params.ensino)) ? 
              this.parameters.learningCycle = this.paramsObject.params.ensino : 
              this.parameters.learningCycle.push(this.paramsObject.params.ensino); 
              break;
          case "tematica": (Array.isArray(this.paramsObject.params.tematica)) ? 
              this.parameters.knowledgeArea = this.paramsObject.params.tematica : 
              this.parameters.knowledgeArea.push(this.paramsObject.params.tematica); 
              break;
          case "curricular": (Array.isArray(this.paramsObject.params.curricular)) ? 
              this.parameters.curricularArea = this.paramsObject.params.curricular : 
              this.parameters.curricularArea.push(this.paramsObject.params.curricular); 
              break;
          case "instituicao": (Array.isArray(this.paramsObject.params.instituicao)) ? 
              this.parameters.institution = this.paramsObject.params.instituicao : 
              this.parameters.institution.push(this.paramsObject.params.instituicao); 
              break;
        }
      }
      
      this.learningCycles.setValue(this.parameters.learningCycle);
      this.knowledgeAreas.setValue(this.parameters.knowledgeArea);
      this.curricularAreas.setValue(this.parameters.curricularArea);
      this.institution.setValue(this.parameters.institution);
      }
    );
    if (this.initSearch != null)
      this.searchText = this.initSearch;
    this.learningCyclesList = learningCyclesList;
    this.knowledgeAreasList = knowledgeAreasList;
    this.curricularAreasList = curricularAreasList;
    this.institutionList = institutionList;
    this.stopwords = stopwords;

    this.filterQuery = "";
    if (this.initSearch != "" ||  this.parameters.institution.length > 0 || 
      this.parameters.curricularArea.length > 0 || this.parameters.knowledgeArea.length > 0 ||
      this.parameters.learningCycle.length > 0) {
      this.search(0, 10);
    }
    
    if (window.innerWidth >= 1500) {
      this.breakpoint = 4
    } else {
      if (window.innerWidth >= 1200) {
        this.breakpoint = 3
      } else {
        this.breakpoint = (window.innerWidth <= 900) ? 1 : 2;
      }
    }
  }

  checkLogged() {
    if(localStorage.getItem('token') != null) {
      let tokenInfo = JSON.parse(atob(localStorage.getItem('token').match(/\..*\./)[0].replace(/\./g, '')));
      let userName = decodeURIComponent(escape(tokenInfo.name));
      if(Math.floor((new Date).getTime() / 1000) <= tokenInfo.exp && userName != "Usuário anônimo") {
        return true;
      }
      return false;
    }
    return false;
  }

  onResize(event) {
    if (window.innerWidth >= 1500) {
      this.breakpoint = 4
    } else {
      if (window.innerWidth >= 1200) {
        this.breakpoint = 3
      } else {
        this.breakpoint = (window.innerWidth <= 900) ? 1 : 2;
      }
    }
  }

  removeLang(text) {
    return text.substring(0, text.length - 3)
  }

  pageChanged(event) {
    this.paginatorTop.pageIndex = event.pageIndex;
    this.paginatorTop.pageSize = event.pageSize;
    this.paginatorTop.previousSize = event.pageIndex * event.pageIndex;
    this.paginatorBottom.pageIndex = event.pageIndex;
    this.paginatorBottom.pageSize = event.pageSize;
    this.paginatorBottom.previousSize = event.pageIndex * event.pageIndex;

    let pageIndex = event.pageIndex;
    let pageSize = event.pageSize;
    // let previousIndex = event.previousPageIndex;
    let previousSize = pageSize * pageIndex;

    this.search(previousSize, pageSize);
  }

  async getIPAddress() {
    let response = await this.http.get("https://ipapi.co/ip/", { responseType: 'text' }).toPromise();
    this.ipAddress = response;
  }

  getSolrStringFromFormValues(fieldName: string, fieldValues: string[]) {
    this.filterQuery += fieldName + ":(";
    for (let i = 0; i < fieldValues.length; i++) {
      this.filterQuery += "\"" + fieldValues[i] + "\"" + " OR ";
    }
    this.filterQuery = this.filterQuery.substring(0, this.filterQuery.length - 4);
    this.filterQuery += ")";
    this.filterQuery += " AND ";
  }

  getFilterQuery() {
    this.filterQuery = "";
    if (this.learningCycles.value && this.learningCycles.value.length > 0) {
      this.getSolrStringFromFormValues("age", this.learningCycles.value)
    }
    if (this.knowledgeAreas.value && this.knowledgeAreas.value.length > 0) {
      this.getSolrStringFromFormValues("knowledgeArea", this.knowledgeAreas.value)
    }
    if (this.curricularAreas.value && this.curricularAreas.value.length > 0) {
      this.getSolrStringFromFormValues("curriculumAreas", this.curricularAreas.value)
    }
    this.filterQuery = this.filterQuery.substring(0, this.filterQuery.length - 4);
  }

  selectSuggestion(suggestion: string) {
    if (suggestion.indexOf(" ") !== -1) {
      suggestion = "\"" + suggestion + "\"";
    }
    let index = this.selectedSearchSuggestions.indexOf(suggestion);
    if (index !== -1) {
      let value = this.selectedSearchSuggestions[index];
      this.selectedSearchSuggestions.splice(index, 1);
      this.searchText = this.searchText.replace(suggestion, "");
    } else {
      this.selectedSearchSuggestions.push(suggestion);
    }
  }

  async search(start, rows) {
    document.body.style.cursor="wait";
    for (let suggestion of this.selectedSearchSuggestions) {
      if (this.searchText.indexOf(suggestion) == -1) {
        this.searchText += " " + suggestion;
      }
    }
    
    this.rest.getSearchText(this.searchText.split("\"").join("\\\"")).subscribe((data: any) => {
      this.searchSuggestions = data;
      console.log(data);
    });

    this.getFilterQuery();
    
    await this.getIPAddress();
    let tokenInfo = this.rest.decodePayloadJWT();

    let search = {
      search_query: this.searchText,
      user: tokenInfo.sub,
      ip: this.ipAddress,
    };

    this.rest.saveSearchText(JSON.stringify([search])).subscribe((data: any) => {
      console.log(data);
    });

    this.documents = [];
    var finalString = "q=*:*";
    var nameClause = "";
    var keywordClause = "";
    var descriptionClause = "";
    let foundInitialQuotationMarks = false;
    let foundCloseQuotationMarks = true;

    if (this.searchText != "") {
      nameClause = "name:\(";
      keywordClause = "keywords:\(";
      descriptionClause = "description:\(";
      finalString = finalString.substring(0,2);
      let keywords = this.searchText.split(" ");
      
      let wordsTogether = "";
      for(let keyword of keywords) {
        // maintain original quoted string
        if(keyword.indexOf("\"") == 0) {
          wordsTogether += keyword;
          foundInitialQuotationMarks = true;
          foundCloseQuotationMarks = false;
          continue;
        }
        // middle of quoted string
        if(keyword.indexOf("\"") == -1 && foundInitialQuotationMarks) {
          wordsTogether += " " + keyword;
          continue;
        }
        // end of quoted string
        if(keyword.indexOf("\"") > 0 && foundInitialQuotationMarks) {
          foundInitialQuotationMarks = false;
          foundCloseQuotationMarks = true;
          wordsTogether += " " + keyword;
          (finalString.length > 3) ? finalString+=" OR " : finalString+="";
          finalString += "name:" + wordsTogether + 
                         " OR keywords:" + wordsTogether +
                         " OR description:" + wordsTogether;  
          wordsTogether = "";
          continue;
        }
        if(keyword.indexOf("\"") > 0 && !foundInitialQuotationMarks) {
            keyword = keyword.replace("\"", "");
        }
        if(this.stopwords.indexOf(keyword) > -1 || keyword.trim() == "") continue;
        let required = "";
        let excluded = "";
        (keyword.indexOf("+") == 0) ? required = "+" : required =  "";
        (keyword.indexOf("+") == 0) ? keyword = keyword.substring(1, keyword.length) : keyword = keyword;
        (keyword.indexOf("-") == 0) ? excluded = "-" : excluded =  "";
        (keyword.indexOf("-") == 0) ? keyword = keyword.substring(1, keyword.length) : keyword = keyword;
        
        // stemmer works better with single word
        let stemmingResponse = await this.rest.getStemmingText(keyword).toPromise();
        let stemmingText = "";
        if(stemmingResponse.msg) {
          stemmingText = stemmingResponse.msg;
        } else {
          stemmingText = keyword;
        } 
        (finalString.length > 3) ? finalString+=" OR " : finalString+="";
        let distance = stemmingText.length > 4 ? 2 : 1;
        nameClause += required + excluded + stemmingText + "\~" + distance + " ";
        keywordClause += required + excluded + stemmingText + "\~" + distance + " ";
        descriptionClause += required + excluded + stemmingText + "\~" + distance + " ";
        // finalString += required + "name:" + stemmingText + "\~" + distance +
        //                 " OR " + "keywords:" + stemmingText + "\~" + distance +
        //                 " OR " + "description:" + stemmingText + "\~" + distance;           
      }
      nameClause += "\) or "; 
      keywordClause += "\) or ";
      descriptionClause += "\)";
    }
    
    if (foundCloseQuotationMarks) {
      finalString += nameClause + keywordClause + descriptionClause;
    }
    finalString += "&fq=status:REVIEWED";
    if (this.filterQuery) {
      finalString += "&fq=" + this.filterQuery;
    }
    // As author is docvalue managed search by regex (TODO: optmize it later)
    if (this.institution.value && this.institution.value.length > 0) {
      finalString += "&fq=";
      for (let i = 0; i < this.institution.value.length; i++) {
        finalString += "author:/(.*" + this.institution.value[i] + ".*)/";
        finalString += " OR ";
        if(this.institution.value[i].indexOf("(") > -1) {
          let name = this.institution.value[i].substring(0,this.institution.value[i].indexOf("(")-1)
          finalString += "author:/(.*" + name + ".*)/";
          finalString += " OR ";
          let acronyms = this.institution.value[i].substring(this.institution.value[i].indexOf("(")+1,
          this.institution.value[i].indexOf(")"))
          finalString += "author:/(.*" + acronyms + ".*)/";
          finalString += " OR ";
        }
      }
      finalString = finalString.substring(0, finalString.length - 4);
    }
    if (this.searchFavorites) {
      let tokenInfo = this.rest.decodePayloadJWT();
      finalString += "&fq=favorites:" + tokenInfo.sub;
    }
    finalString += "&rows=" + rows + "&start=" + start + "&sort=_version_ desc";
    console.log(finalString);
    this.rest.querySOLR(finalString, 'DocumentTinyDto').subscribe((data: any) => {
      var rec = data.response.docs;
      this.totalItems = data.response.numFound;
      for (var x in rec) {
        this.documents.push({ id: rec[x].id, title: rec[x].name, favorites: rec[x].favorites });
      }
    });
    document.body.style.cursor="initial";
  }
}
