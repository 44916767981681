<br>
<br>
<div class="grey-division">
  <div class="grey-division-text"><strong>Colaborações</strong></div>
</div>
<br>
<br>
<div>
  <div fxLayout="row wrap" fxLayoutGap="30px grid">
    <div [fxFlex]="(100/gridColumns) + '%'" fxFlex.xs="100%" fxFlex.sm="33%" *ngFor="let contributor of contributors">
      <mat-card class="mat-elevation-z4">
        <div class="container">
            <a href="{{contributor.link}}" target="_blank"><img  mat-card-image class="mat-card-image" src={{folder}}{{contributor.filename}}></a> 
        </div>
      </mat-card>
    </div>
  </div>
</div>